import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import {
  Button,
  Box,
  Stat,
  StatHelpText,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useState } from 'react';
const CheckoutForm = ({ invoiceID, clientID }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [invoiceStatus, setInvoiceStatus] = useState<any>('');
  const [invoicePaymentError, setInvoicePaymentError] = useState<number>(0);
  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const return_url =
      'https://portal.unlockinglanguage.co.uk/thank-you-for-completing-the-onboarding-process?invoiceID=' +
      invoiceID +
      '&clientID=' +
      clientID;
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: return_url,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setInvoiceStatus(result.error.message);
      setInvoicePaymentError(1);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setInvoiceStatus('You can now login to the dashboard');
      setInvoicePaymentError(2);
      console.log('Payment complete');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Stat py={5} fontWeight={'bold'}>
        <StatHelpText>Please disable ad blockers before making payment.</StatHelpText>
      </Stat>
      <Box>
        <Button
          variant={'defaultButton'}
          disabled={!stripe}
          mt={10}
          onClick={(e: any) => handleSubmit(e)}
          mb={10}
        >
          Agree & Pay
        </Button>
      </Box>
      {invoicePaymentError === 1 ? (
        <Alert status="error" borderRadius={10}>
          <AlertIcon />
          <AlertTitle>Payment error!</AlertTitle>
          <AlertDescription>{invoiceStatus}</AlertDescription>
        </Alert>
      ) : invoicePaymentError === 2 ? (
        <Alert status="success" borderRadius={10}>
          <AlertIcon />
          <AlertTitle>Payment complete!</AlertTitle>
          <AlertDescription>{invoiceStatus}</AlertDescription>
        </Alert>
      ) : (
        ''
      )}
    </form>
  );
};

export default CheckoutForm;
