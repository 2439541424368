import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  NumberInput,
  Progress,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Spinner,
  useSteps,
  useToast,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import appendixA from './../constants/reporting/appendix_a.json';
import { SignedInUserProps } from './../interfaces/SignedInUserProps';
import UL_logo_large from './../logo/unlocking_language_logo_white.png';
import { useTimeout } from 'usehooks-ts';
import StepNine from './ReportComponents/stepNine';
import elevenToTwelveUnderstandingSpokenParagraphs from './../constants/report/rawScores/11-12-understandingSpokenParagraphs.json';
import thirteenToFourteenUnderstandingSpokenParagraphs from './../constants/report/rawScores/13-14-understandingSpokenParagraphs.json';
import fifteenToTwentyoneUnderstandingSpokenParagraphs from './../constants/report/rawScores/15-21-understandingSpokenParagraphs.json';
import fiveToSixUnderstandingSpokenParagraphs from './../constants/report/rawScores/5-6-understandingSpokenParagraphs.json';
import sevenToEightUnderstandingSpokenParagraphs from './../constants/report/rawScores/7-8-understandingSpokenParagraphs.json';
import nineToTenUnderstandingSpokenParagraphs from './../constants/report/rawScores/9-10-understandingSpokenParagraphs.json';
import followingDirections from './../constants/report/rawScores/followingDirections.json';
import formulatedSentences from './../constants/report/rawScores/formulatedSentences.json';
import linguisticConcepts from './../constants/report/rawScores/linguisticConcepts.json';
import pragmaticsActivitiesChecklist from './../constants/report/rawScores/pragmaticsActivitiesChecklist.json';
import pragmaticsProfile from './../constants/report/rawScores/pragmaticsProfile.json';
import recallingSentences from './../constants/report/rawScores/recallingSentences.json';
import semanticRelationships from './../constants/report/rawScores/semanticRelationships.json';
import sentenceAssembly from './../constants/report/rawScores/sentenceAssembly.json';
import sentenceComprehension from './../constants/report/rawScores/sentenceComprehension.json';
import wordClasses from './../constants/report/rawScores/wordClasses.json';
import wordDefinitions from './../constants/report/rawScores/wordDefinitions.json';
import wordStructures from './../constants/report/rawScores/wordStructures.json';
import uploadFileToClientUploadFolder from './../utils/uploadFileToClientUploadFolder';
import appAScaledScores5055 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_5055.json';
import appAScaledScores56511 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_56511.json';
import appAScaledScores6065 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_6065.json';
import appAScaledScores66611 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_66611.json';
import appAScaledScores70711 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_70711.json';
import appAScaledScores80811 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_80811.json';
import appAScaledScores90911 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_90911.json';
import appAScaledScores1001011 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_1001011.json';
import appAScaledScores1101111 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_1101111.json';
import appAScaledScores1201211 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_1201211.json';
import appAScaledScores1301311 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_1301311.json';
import appAScaledScores1401411 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_1401411.json';
import appAScaledScores1501511 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_1501511.json';
import appAScaledScores1601611 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_1601611.json';
import appAScaledScores1702111 from './../constants/report/calculationData/appendix_a/scaledScores/appendix_a_scaled_scores_1702111.json';

import appAConfidenceInterval5055 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_5055.json';
import appAConfidenceInterval56511 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_56511.json';
import appAConfidenceInterval6065 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_6065.json';
import appAConfidenceInterval66611 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_66611.json';
import appAConfidenceInterval70711 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_70711.json';
import appAConfidenceInterval80811 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_80811.json';
import appAConfidenceInterval90911 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_90911.json';
import appAConfidenceInterval1001011 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_1001011.json';
import appAConfidenceInterval1101111 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_1101111.json';
import appAConfidenceInterval1201211 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_1201211.json';
import appAConfidenceInterval1301311 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_1301311.json';
import appAConfidenceInterval1401411 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_1401411.json';
import appAConfidenceInterval1501511 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_1501511.json';
import appAConfidenceInterval1601611 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_1601611.json';
import appAConfidenceInterval1702111 from './../constants/report/calculationData/appendix_a/confidenceInterval/appendix_a_confidence_interval_1702111.json';

import appBScaledScores5055 from './../constants/report/calculationData/appendix_b/appendix_b_5055.json';
import appBScaledScores56511 from './../constants/report/calculationData/appendix_b/appendix_b_56511.json';
import appBScaledScores6065 from './../constants/report/calculationData/appendix_b/appendix_b_6065.json';
import appBScaledScores66611 from './../constants/report/calculationData/appendix_b/appendix_b_66611.json';
import appBScaledScores70711 from './../constants/report/calculationData/appendix_b/appendix_b_70711.json';
import appBScaledScores80811 from './../constants/report/calculationData/appendix_b/appendix_b_80811.json';
import appBScaledScores90911 from './../constants/report/calculationData/appendix_b/appendix_b_90911.json';
import appBScaledScores1001011 from './../constants/report/calculationData/appendix_b/appendix_b_1001011.json';
import appBScaledScores1101111 from './../constants/report/calculationData/appendix_b/appendix_b_1101111.json';
import appBScaledScores1201211 from './../constants/report/calculationData/appendix_b/appendix_b_1201211.json';
import appBScaledScores1301311 from './../constants/report/calculationData/appendix_b/appendix_b_1301311.json';
import appBScaledScores1401411 from './../constants/report/calculationData/appendix_b/appendix_b_1401411.json';
import appBScaledScores1501511 from './../constants/report/calculationData/appendix_b/appendix_b_1501511.json';
import appBScaledScores1601611 from './../constants/report/calculationData/appendix_b/appendix_b_1601611.json';
import appBScaledScores1702111 from './../constants/report/calculationData/appendix_b/appendix_b_1702111.json';

import appCTestAgeEquivalent from './../constants/report/calculationData/appendix_c/appendix_c.json';
import appDPercentileRank from './../constants/report/calculationData/appendix_d/appendix_d.json';
import appFCriticalValues from './../constants/report/calculationData/appendix_f/appendix_f1.json';

import followingDirectionsJson from './../constants/report/itemsAnalysis/followingDirections.json';
import formulatedSentencesJson from './../constants/report/itemsAnalysis/formulatedSentences.json';
import linguisticConceptsJson from './../constants/report/itemsAnalysis/linguisticConcepts.json';
import pragmaticsProfileJson from './../constants/report/itemsAnalysis/pragmaticsProfile.json';
import recallingSentencesJson from './../constants/report/itemsAnalysis/recallingSentences.json';
import semanticRelationshipsJson from './../constants/report/itemsAnalysis/semanticRelationships.json';
import sentenceAssemblyJson from './../constants/report/itemsAnalysis/sentenceAssembly.json';
import sentenceComprehensionJson from './../constants/report/itemsAnalysis/sentenceComprehension.json';
import wordClassesJson from './../constants/report/itemsAnalysis/wordClasses.json';
import wordDefinitionsJson from './../constants/report/itemsAnalysis/wordDefinitions.json';
import wordStructuresJson from './../constants/report/itemsAnalysis/wordStructures.json';
import pragmaticsActivitiesChecklistJson from './../constants/report/itemsAnalysis/pragmaticsActivitiesChecklist.json';

import fiveToSixUspJson from './../constants/report/itemsAnalysis/fiveToSixUnderstandingSpokenParagraphs.json';
import sevenToEightUspJson from './../constants/report/itemsAnalysis/sevenToEightUnderstandingSpokenParagraphs.json';
import nineToTenUspJson from './../constants/report/itemsAnalysis/nineToTenUnderstandingSpokenParagraphs.json';
import elevenToTwelveUspJson from './../constants/report/itemsAnalysis/elevenToTwelveUnderstandingSpokenParagraphs.json';
import thirteenToFourteenUspJson from './../constants/report/itemsAnalysis/thirteenToFourteenUnderstandingSpokenParagraphs.json';
import fifteenToTwentyOneUspJson from './../constants/report/itemsAnalysis/fifteenToTwentyOneUnderstandingSpokenParagraphs.json';

import reportFallbackTemplate from './../logo/report_template.png';

// import postDataToEndPoint from '../utils/postDataToEndPoint';
import postDataToEndPointCreateReport from '../utils/postDataToEndPointCreateReport';
// import { GoogleCharts } from 'google-charts';
import { Chart, GoogleVizEventName } from 'react-google-charts';
import { useScreenshot } from 'use-react-screenshot';
import confetti from 'canvas-confetti';

import moment from 'moment';

import getFileSizeFromBase64 from './../utils/getFileSizeFromBase64';
import getFileTypeFromBase64 from './../utils/getFileTypeFromBase64';
const rsModalData = [
  { rsid: 'fd', rsTitle: 'Following Directions(FD)', rsData: followingDirections },
  { rsid: 'fs', rsTitle: 'Formulated Sentences(FS)', rsData: formulatedSentences },
  { rsid: 'lc', rsTitle: 'Linguistic Concepts(LC)', rsData: linguisticConcepts },
  {
    rsid: 'pac',
    rsTitle: 'Pragmatics Activies Checklist(PAC)',
    rsData: pragmaticsActivitiesChecklist,
  },
  { rsid: 'pp', rsTitle: 'Pragmatics Profile(PP)', rsData: pragmaticsProfile },
  { rsid: 'rs', rsTitle: 'Recalling Sentences(RS)', rsData: recallingSentences },
  { rsid: 'sr', rsTitle: 'Semantic Relationships(SR)', rsData: semanticRelationships },
  { rsid: 'sa', rsTitle: 'Sentence Assembly(SA)', rsData: sentenceAssembly },
  { rsid: 'sc', rsTitle: 'Sentence Comprehension(SC)', rsData: sentenceComprehension },
  { rsid: 'wc', rsTitle: 'Word Classes(WC)', rsData: wordClasses },
  { rsid: 'wd', rsTitle: 'Word Definitions(WD)', rsData: wordDefinitions },
  { rsid: 'ws', rsTitle: 'Word Structure(WS)', rsData: wordStructures },
  // {
  //   rsid: '56usp',
  //   rsTitle: '5 - 6 Understanding Spoken Paragraphs(USP)',
  //   rsData: fiveToSixUnderstandingSpokenParagraphs,
  // },
  // {
  //   rsid: '78usp',
  //   rsTitle: '7 - 8 Understanding Spoken Paragraphs(USP)',
  //   rsData: sevenToEightUnderstandingSpokenParagraphs,
  // },
  // {
  //   rsid: '910usp',
  //   rsTitle: '9 - 10 Understanding Spoken Paragraphs(USP)',
  //   rsData: nineToTenUnderstandingSpokenParagraphs,
  // },
  // {
  //   rsid: '1112usp',
  //   rsTitle: '11 - 12 Understanding Spoken Paragraphs(USP)',
  //   rsData: elevenToTwelveUnderstandingSpokenParagraphs,
  // },
  // {
  //   rsid: '1314usp',
  //   rsTitle: '13 - 14 Understanding Spoken Paragraphs(USP)',
  //   rsData: thirteenToFourteenUnderstandingSpokenParagraphs,
  // },
  // {
  //   rsid: '1521usp',
  //   rsTitle: '15 - 21 Understanding Spoken Paragraphs(USP)',
  //   rsData: fifteenToTwentyoneUnderstandingSpokenParagraphs,
  // },
];

const uspModalData = [
  {
    age: [5, 6],
    rsid: 'fiveToSixUsp',
    rsTitle: '5 - 6 Understanding Spoken Paragraphs(USP)',
    rsData: fiveToSixUnderstandingSpokenParagraphs,
  },
  {
    age: [7, 8],
    rsid: 'sevenToEightUsp',
    rsTitle: '7 - 8 Understanding Spoken Paragraphs(USP)',
    rsData: sevenToEightUnderstandingSpokenParagraphs,
  },
  {
    age: [9, 10],
    rsid: 'nineToTenUsp',
    rsTitle: '9 - 10 Understanding Spoken Paragraphs(USP)',
    rsData: nineToTenUnderstandingSpokenParagraphs,
  },
  {
    age: [11, 12],
    rsid: 'elevenToTwelveUsp',
    rsTitle: '11 - 12 Understanding Spoken Paragraphs(USP)',
    rsData: elevenToTwelveUnderstandingSpokenParagraphs,
  },
  {
    age: [13, 14],
    rsid: 'thirteenToFourteenUsp',
    rsTitle: '13 - 14 Understanding Spoken Paragraphs(USP)',
    rsData: thirteenToFourteenUnderstandingSpokenParagraphs,
  },
  {
    age: [15, 16, 17, 18, 19, 20, 21],
    rsid: 'fifteenToTwentyOneUsp',
    rsTitle: '15 - 21 Understanding Spoken Paragraphs(USP)',
    rsData: fifteenToTwentyoneUnderstandingSpokenParagraphs,
  },
];

const ReportingContent = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  sessionId,
}: SignedInUserProps) => {
  const [rows, setRows] = useState<any>([]);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulClientsTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  // const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const [rawScoresSelected, setRawScoresSelected] = useState<any>([]);
  const [rsTitle, setRsTitle] = useState<string>('');
  const [currentRsid, setCurrentRsid] = useState<string>('fiveToSixUsp');
  const [currentUsp, setCurrentUsp] = useState<any>('fiveToSixUsp');
  const [ciLevel, setCiLevel] = useState<number>(0);
  const [ssLevel, setSsLevel] = useState<number>(0);
  const [savedPac, setSavedPac] = useState<number>(0);
  const [itemsAnalysis, setItemsAnalysis] = useState<any>([]);

  const [tabIndex, setTabIndex] = useState(0);
  const [dataSent, setDataSent] = useState(false);

  const [buildCandleStickOne, setBuildCandleStickOne] = useState(0);
  const [createReportButton, setCreateReportButton] = useState(0);
  const [celfLoadingIcon, setCelfLoadingIcon] = useState(true);
  const [secondLoading, setSecondLoading] = useState(0);
  const [fileCreated, setFileCreated] = useState('none');
  const [clsipImageCreatedOne, setClsipImageCreatedOne] = useState(0);
  const [clsipImageCreatedTwo, setClsipImageCreatedTwo] = useState(0);
  const [clsipImageCreatedThree, setClsipImageCreatedThree] = useState(0);

  const [newCandlestickOneImageId, setNewCandlestickOneImageId] = useState('');
  const [newCandlestickTwoImageId, setNewCandlestickTwoImageId] = useState('');
  const [newCandlestickThreeImageId, setNewCandlestickThreeImageId] = useState('');

  const toast = useToast();

  // function calAge() {
  //   var date = dob;
  //   console.log(date)
  //   var diff = moment(date).diff(moment(), 'milliseconds');
  //   var duration = moment.duration(diff);
  //   document.getElementById("result").innerHTML =duration.format().replace("-","");
  // }
  const cii_values = [68, 90, 95];
  const sii_values = [0.15, 0.05];

  // tot if less than 9 then meets else does not meet - inactive checkbox field
  // other observations: each should be radio buttons
  // additional notes : should be checkboxes

  const [responseTime, setResponseTime] = useState<any>(1);
  const [needForRepetitionOfStimuli, setNeedForRepetitionOfStimuli] = useState<any>(1);
  const [attentionDuringTesting, setAttentionDuringTesting] = useState<any>(1);
  const [saveTotalScore, setSaveTotalScore] = useState<number>(0);

  const [attitudesTowardsTesting, setAttitudesTowardsTesting] = useState<any>(1);
  const [socialLanguageRegisterandQuantity, setSocialLanguageRegisterandQuantity] =
    useState<any>(1);

  const ref = useRef(null);
  const chartRef = useRef(null);

  const [imageScreenshot, takeScreenshot] = useScreenshot();
  const getImage = () => {
    return takeScreenshot(ref.current);
  };

  const otherObvDefaults = [
    {
      id: 'responseTime',
      title: 'Response Time',
      value: null,
      options: [
        { id: 1, value: 'Immediate' },
        { id: 2, value: 'Variable based on difficulty' },
        { id: 3, value: 'Delayed' },
      ],
    },
    {
      id: 'needForRepetitionOfStimuli',
      title: 'Need for repetition of stimuli',
      value: null,
      options: [
        { id: 1, value: 'Few Repetitions' },
        { id: 2, value: 'Some Repetitions' },
        { id: 3, value: 'Many repetitions' },
      ],
    },
    {
      id: 'attentionDuringTesting',
      title: 'Attention during testing',
      value: null,
      options: [
        { id: 1, value: 'Maintained Throughout' },
        { id: 2, value: 'Maintained Intermittently' },
        { id: 3, value: 'Distractible/Off task' },
      ],
    },
    {
      id: 'attitudesTowardsTesting',
      title: 'Attitudes towards testing',
      value: null,
      options: [
        { id: 1, value: 'Positive/Cooperative' },
        { id: 2, value: 'Variable/Neutral' },
        { id: 3, value: 'Negative/Uncooperative' },
      ],
    },
    {
      id: 'socialLanguageRegisterandQuantity',
      title: 'Social language register and quantity',
      value: null,
      options: [
        { id: 1, value: 'Appropriate/On topic/Efficient' },
        { id: 2, value: 'Variable' },
        { id: 3, value: 'Inappropriate/ Off topic/Inappropriate / Off topic/Verbose' },
      ],
    },
  ];

  const [otherObservations, setOtherObservations] = useState(otherObvDefaults);

  // const defaultReportCompilerOutput = {};
  const defaultReportCompilerInputs = {
    reportState: 'empty',
    studentName: null,
    address: '',
    gender: null,
    schoolYear: null,
    schooName: '',
    teacher: '',
    examiner: '',
    dateOfTest: null,
    dateOfBirth: null,
    ageAtTestCode: null,
    ageAtTest: null,
    ageYears: 8,
    ageMonths: 6,
    fd: null,
    fs: null,
    lc: null,
    pac: null,
    pp: null,
    rs: null,
    sr: null,
    sa: null,
    sc: null,
    wc: null,
    wd: null,
    ws: null,
    usp: null,

    ciLevel: 90,
    ssLevel: 0.15,
    tot: null,

    otherObvservations: otherObvDefaults,

    fdFinaleScore: null,
    fsFinaleScore: null,
    lcFinaleScore: null,
    pacFinaleScore: null,
    ppFinaleScore: null,
    rsFinaleScore: null,
    srFinaleScore: null,
    saFinaleScore: null,
    scFinaleScore: null,
    wcFinaleScore: null,
    wdFinaleScore: null,
    wsFinaleScore: null,
    uspFinaleScore: null,

    criterion: null,
    meetsDoesNotMeet: null,

    // responseTime: null,
    // needForRepitionStimuli: null,
    // attentionDuringTesting: null,
    // attituteTowardsTesting: null,
    // socialLanguageRegisterAndQuantity: null,

    stammerFluent: null,
    additionalNotes: null,

    selectedUsp: 'fiveToSixUsp',
  };
  const [reportCompiler, setReportCompiler] = useState<any>(defaultReportCompilerInputs);
  const defaultReportCompilerOutput = [
    {
      NEWfdItemsAnalysisValuesCategoriesOne: null,
      NEWfdItemsAnalysisValuesOne: null,
    },
  ];
  const [reportCompilerOutput, setReportCompilerOutput] = useState<any>([]);

  const [typedScore, setTypedScore] = useState<any>('');
  const [modalContent, setModalContent] = useState<any>('none');
  const [ageCalculated, setAgeCalculated] = useState<number>(0);
  const [tot, setTot] = useState<any>(null);

  //   google.charts.load('current', {'packages':['corechart']});
  //   google.charts.setOnLoadCallback(drawChart);

  // function drawChart() {
  // var data = google.visualization.arrayToDataTable([
  //   ['CLS', 145, 150, 170, 185],
  //   ['RLI', 145, 150, 170, 185],

  //   // Treat first row as data as well.
  // ], true);

  // var options = {
  //   legend:'none',
  //   vAxis: { ticks: [140,145,150,155,160,165,170,175,180,185,190] }
  // };

  // var chart = new google.visualization.CandlestickChart(document.getElementById('chart_div'));

  // chart.draw(data, options);
  // }

  const ranges = [
    40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140,
    145, 150, 155, 160,
  ];

  // [
  //   { type: 'cls', score: 72, ciMax: 78, ciMin: 66 , ranges: getIndexRangeForScores(66, 78) //[17,18,19,20]},
  //   { type: 'rli', score: 79, ciMax: 85, ciMin: 73 },
  //   { type: 'eli', score: 71, ciMax: 77, ciMin: 65 },
  //   { type: 'lci', score: 75, ciMax: 80, ciMin: 70 },
  //   { type: 'lmi', score: 76, ciMax: 82, ciMin: 70 },
  //   { type: 'lsi', score: 00, ciMax: 00, ciMin: 00},
  // ];

  // chart.getImageURI();
  // ciMin, Score, Score, ciMax

  const candlestickChartOneOptions = {
    legend: 'none',
    colors: ['#40a83d'],
    bar: {
      groupWidth: '20%',
      groupHeight: '80%',
    },
    vAxis: {
      ticks: [
        40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140,
        145, 150, 155, 160,
      ],
      // gridlines: { color: '#333' },
      title: 'Index score',
      titleTextStyle: { color: '#333', fontSize: 20 },
      textStyle: { color: '#000', fontSize: 20 },
    },
    hAxis: {
      // title: 'Tasks',
      titleTextStyle: { color: '#333', fontSize: 20 },
      textStyle: { color: '#000', fontSize: 20 },
      direction: 1,
      title: 'Subtests',
      // showTextEvery: 1, // Show every label
      // maxTextLines: 2, // Ensure labels are not wrapped
    },
    chartArea: {
      width: '85%',
      height: '85%',
      left: 80,
      top: 80,
    },
    title: 'Language Scores Summary',
    titleTextStyle: {
      fontSize: 20,
      bold: true,
    },
  };
  const candlestickChartTwoOptions = {
    legend: 'none',
    colors: ['#40a83d'],
    bar: {
      groupWidth: '20%',
      groupHeight: '80%',
    },
    vAxis: {
      ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
      // gridlines: { color: '#333' },
      title: 'Index score',
      titleTextStyle: { color: '#333', fontSize: 20 },
      textStyle: { color: '#000', fontSize: 20 },
    },
    hAxis: {
      // title: 'Tasks',
      titleTextStyle: { color: '#333', fontSize: 20 },
      textStyle: { color: '#000', fontSize: 20 },
      direction: 1,
      title: 'Subtests',
      // showTextEvery: 1, // Show every label
      // maxTextLines: 2, // Ensure labels are not wrapped
    },
    chartArea: {
      width: '85%',
      height: '85%',
      left: 80,
      top: 80,
    },
    title: 'Receptive Language (Understanding) scores',
    titleTextStyle: {
      fontSize: 20,
      bold: true,
    },
  };
  const candlestickChartThreeOptions = {
    legend: 'none',
    colors: ['#40a83d'],
    bar: {
      groupWidth: '20%',
      groupHeight: '80%',
    },
    vAxis: {
      ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
      // gridlines: { color: '#333' },
      title: 'Index score',
      titleTextStyle: { color: '#333', fontSize: 20 },
      textStyle: { color: '#000', fontSize: 20 },
    },
    hAxis: {
      // title: 'Tasks',
      titleTextStyle: { color: '#333', fontSize: 20 },
      textStyle: { color: '#000', fontSize: 20 },
      direction: 1,
      title: 'Subtests',
      // showTextEvery: 1, // Show every label
      // maxTextLines: 2, // Ensure labels are not wrapped
    },
    chartArea: {
      width: '85%',
      height: '85%',
      left: 80,
      top: 80,
    },
    title: 'Expressive Language (Talking) scores',
    titleTextStyle: {
      fontSize: 20,
      bold: true,
    },
  };
  const [chartOne, setChartOne] = useState<any>('');
  const [optionsChartOne, setOptionsChartOne] = useState<any>(candlestickChartOneOptions);
  let chartTwoCandleStickData: any = [['csid', 'a', 'b', 'c', 'd']];

  let chartThreeCandleStickData: any = [['csid', 'a', 'b', 'c', 'd']];

  // let chartTwoCandleStickData: any = [
  //   ['day', 'a', 'b', 'c', 'd'],
  //   ['Mon', 20, 28, 38, 45],
  //   ['Tue', 31, 38, 55, 66],
  //   ['Wed', 50, 55, 77, 80],
  //   ['Thu', 50, 77, 66, 77],
  //   ['Fri', 15, 66, 22, 68],
  // ];

  const [chartTwo, setChartTwo] = useState<any>(chartTwoCandleStickData);
  const [optionsChartTwo, setOptionsChartTwo] = useState<any>(candlestickChartTwoOptions);

  const [chartThree, setChartThree] = useState<any>(chartThreeCandleStickData);
  const [optionsChartThree, setOptionsChartThree] = useState<any>(candlestickChartThreeOptions);

  const [chartScreenshot, setChartScreenshot] = useState<any>('');

  const [visible, setVisible] = useState(true);

  const hide = () => {
    setVisible(false);
  };

  // useTimeout(hide, 5000);

  const steps = [
    { title: 'Student details', description: '' },
    { title: 'Age Calculation', description: '' },
    { title: 'Levels', description: '' },
    { title: 'Raw Scores', description: '' },
    { title: 'Pragmatics', description: '' },
    // { title: 'Other Observations', description: '' },
    { title: 'Additional notes', description: '' },
    // { title: 'Test Scaled Scores', description: '' },
    // { title: 'Core Language & Index Scores', description: '' },
    // { title: 'Disrepancy Comparisons', description: '' },
    { title: 'Compiled Report', description: '' },
    // { title: 'Score Profiles', description: '' },
    { title: 'Save report', description: '' },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  // function triggerCalculateTss(activeStep: any) {
  //   setActiveStep(activeStep + 1);
  //   console.log('Triggered');
  // }

  const appendixAScaledScoresBasedOnAge = () => {
    const ageYears = reportCompiler.ageYears;
    const ageMonths = reportCompiler.ageMonths;
    // console.log(reportCompiler);

    let scaledScoreToUse;

    switch (true) {
      case ageYears == 5 && ageMonths <= 5:
        scaledScoreToUse = appAScaledScores5055;
        break;
      case ageYears == 5 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores56511;
        break;
      case ageYears == 6 && ageMonths <= 5:
        scaledScoreToUse = appAScaledScores6065;
        break;
      case ageYears == 6 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores66611;
        break;
      case ageYears == 7 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores70711;
        break;
      case ageYears == 8 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores80811;
        break;
      case ageYears == 9 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores90911;
        break;
      case ageYears == 10 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores1001011;
        break;
      case ageYears == 11 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores1101111;
        break;
      case ageYears == 12 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores1201211;
        break;
      case ageYears == 13 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores1301311;
        break;
      case ageYears == 14 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores1401411;
        break;
      case ageYears == 15 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores1501511;
        break;
      case ageYears == 16 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores1601611;
        break;
      case ageYears >= 17 && ageMonths <= 11:
        scaledScoreToUse = appAScaledScores1702111;
        break;
    }
    // console.log(scaledScoreToUse);
    return scaledScoreToUse;
  };

  const appendixAConfidenceIntervalBasedOnAge = () => {
    const ageYears = reportCompiler.ageYears;
    const ageMonths = reportCompiler.ageMonths;
    // console.log(reportCompiler);

    let confidenceIntervalToUse;

    switch (true) {
      case ageYears == 5 && ageMonths <= 5:
        confidenceIntervalToUse = appAConfidenceInterval5055;
        break;
      case ageYears == 5 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval56511;
        break;
      case ageYears == 6 && ageMonths <= 5:
        confidenceIntervalToUse = appAConfidenceInterval6065;
        break;
      case ageYears == 6 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval66611;
        break;
      case ageYears == 7 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval70711;
        break;
      case ageYears == 8 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval80811;
        break;
      case ageYears == 9 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval90911;
        break;
      case ageYears == 10 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval1001011;
        break;
      case ageYears == 11 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval1101111;
        break;
      case ageYears == 12 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval1201211;
        break;
      case ageYears == 13 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval1301311;
        break;
      case ageYears == 14 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval1401411;
        break;
      case ageYears == 15 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval1501511;
        break;
      case ageYears == 16 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval1601611;
        break;
      case ageYears >= 17 && ageMonths <= 11:
        confidenceIntervalToUse = appAConfidenceInterval1702111;
        break;
    }
    // console.log(confidenceIntervalToUse);
    return confidenceIntervalToUse;
  };
  // const Reducedarray = data.reduce((acc, { Address, ...rest }) => [...acc, rest, ...Address], []);

  function appendixBStandardScoresBasedOnAge() {
    const ageYears = reportCompiler.ageYears;
    const ageMonths = reportCompiler.ageMonths;

    let scaledScoreToUse;

    switch (true) {
      case ageYears == 5 && ageMonths <= 5:
        scaledScoreToUse = appBScaledScores5055;
        break;
      case ageYears == 5 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores56511;
        break;
      case ageYears == 6 && ageMonths <= 5:
        scaledScoreToUse = appBScaledScores6065;
        break;
      case ageYears == 6 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores66611;
        break;
      case ageYears == 7 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores70711;
        break;
      case ageYears == 8 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores80811;
        break;
      case ageYears == 9 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores90911;
        break;
      case ageYears == 10 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores1001011;
        break;
      case ageYears == 11 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores1101111;
        break;
      case ageYears == 12 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores1201211;
        break;
      case ageYears == 13 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores1301311;
        break;
      case ageYears == 14 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores1401411;
        break;
      case ageYears == 15 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores1501511;
        break;
      case ageYears == 16 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores1601611;
        break;
      case ageYears >= 17 && ageMonths <= 11:
        scaledScoreToUse = appBScaledScores1702111;
        break;
    }
    // console.log('appendixB scaled score', scaledScoreToUse);
    return scaledScoreToUse;
  }
  function findWitinNestedObj(entireObj: any, keyToFind: any, valToFind: number) {
    let foundObj: any;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && Number(nestedValue[keyToFind]) === Number(valToFind)) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj?.ss;
  }

  function findStandardScoreWithinNestedObj(soss: number, ssObject: any, clisType: string) {
    const theSsArray = ssObject[1];
    let clisTypeToUse;

    switch (true) {
      case clisType == 'sotssCLS':
        clisTypeToUse = theSsArray?.filter((i: any) => String(i.sotssCls) === String(soss));
        // setReportCompilerOutput([...reportCompilerOutput, { sotssCLS: clisTypeToUse[0]?.clis }]);
        break;
      case clisType == 'sotssRLI':
        clisTypeToUse = theSsArray?.filter((i: any) => String(i.sotssRLI) === String(soss));
        // setReportCompilerOutput([...reportCompilerOutput, { sotssRLI: clisTypeToUse[0]?.clis }]);
        break;
      case clisType == 'sotssELI':
        clisTypeToUse = theSsArray?.filter((i: any) => String(i.sotssELI) === String(soss));
        // setReportCompilerOutput([...reportCompilerOutput, { sotssELI: clisTypeToUse[0]?.clis }]);
        break;
      case clisType == 'sotssLCI':
        clisTypeToUse = theSsArray?.filter((i: any) => String(i.sotssLCI) === String(soss));
        // setReportCompilerOutput([...reportCompilerOutput, { sotssLCI: clisTypeToUse[0]?.clis }]);
        break;

      case clisType == 'sotssLSI':
        clisTypeToUse = theSsArray?.filter((i: any) => String(i.sotssLSI) === String(soss));
        // setReportCompilerOutput([...reportCompilerOutput, { sotssLCI: clisTypeToUse[0]?.clis }]);
        break;

      case clisType == 'sotssLMI':
        clisTypeToUse = theSsArray?.filter((i: any) => String(i.sotssLMI) === String(soss));
        // setReportCompilerOutput([...reportCompilerOutput, { sotssLCI: clisTypeToUse[0]?.clis }]);
        break;

      // case clisType == (Number(reportCompiler.ageYears) <= Number(8) ? 'sotssLSI' : 'sotssLMI'):
      //   Number(reportCompiler.ageYears) <= 8
      //     ? (clisTypeToUse = theSsArray?.filter((i: any) => String(i?.sotssLSI) === String(soss)))
      //     : (clisTypeToUse = theSsArray?.filter((i: any) => String(i?.sotssLMI) === String(soss)));
      // setReportCompilerOutput([...reportCompilerOutput, { sotssLMI: clisTypeToUse[0]?.clis }]);
      // break;
    }

    return clisTypeToUse[0]?.clis;
  }

  function findStandardScorePlusMinusPointsWithinNestedObj(ssObject: any) {
    const theCiArray = ssObject[0];
    let clisCiToUse;
    clisCiToUse = theCiArray?.filter((i: any) => String(i.cl) === String(reportCompiler.ciLevel));
    return clisCiToUse[0];
  }

  function findScaledScorePlusMinusFromNestedObj(ci: number, ciObject: any) {
    const theCiArray = ciObject[0];
    const theCiValue = theCiArray.filter((i: any) => String(i.ci) === String(ci));
    const RsidToUse = currentRsid;
    let rawScoreToUse;
    switch (true) {
      case RsidToUse == 'fd':
        rawScoreToUse = theCiValue[0].fd;
        break;
      case RsidToUse == 'fs':
        rawScoreToUse = theCiValue[0].fs;
        break;
      case RsidToUse == 'lc':
        rawScoreToUse = theCiValue[0].lc;
        break;
      case RsidToUse == 'pac':
        rawScoreToUse = theCiValue[0].pac;
        break;
      case RsidToUse == 'pp':
        rawScoreToUse = theCiValue[0].pp;
        break;
      case RsidToUse == 'rs':
        rawScoreToUse = theCiValue[0].rs;
        break;
      case RsidToUse == 'sr':
        rawScoreToUse = theCiValue[0].sr;
        break;
      case RsidToUse == 'sa':
        rawScoreToUse = theCiValue[0].sa;
        break;
      case RsidToUse == 'sc':
        rawScoreToUse = theCiValue[0].sc;
        break;
      case RsidToUse == 'wc':
        rawScoreToUse = theCiValue[0].wc;
        break;
      case RsidToUse == 'wd':
        rawScoreToUse = theCiValue[0].wd;
        break;
      case RsidToUse == 'ws':
        rawScoreToUse = theCiValue[0].ws;
        break;
      // case RsidToUse == 'usp':
      //   rawScoreToUse = theCiValue[0].usp;
      //   break;
      case RsidToUse === 'fiveToSixUsp':
        rawScoreToUse = theCiValue[0].usp;
        break;
      case RsidToUse === 'sevenToEightUsp':
        rawScoreToUse = theCiValue[0].usp;
        break;
      case RsidToUse === 'nineToTenUsp':
        rawScoreToUse = theCiValue[0].usp;
        break;
      case RsidToUse === 'elevenToTwelveUsp':
        rawScoreToUse = theCiValue[0].usp;
        break;
      case RsidToUse === 'thirteenToFourteenUsp':
        rawScoreToUse = theCiValue[0].usp;
        break;
      case RsidToUse === 'fifteenToTwentyOneUsp':
        rawScoreToUse = theCiValue[0].usp;
        break;
    }
    return rawScoreToUse;
    // return theCiValue[0].fd;
  }

  function findPercentileRankFromNestedObj(tss: any, prObject: any) {
    const theTss = tss;
    const thePrArray = prObject[0];
    const thePrValue = thePrArray.find((i: any) => String(i.tss) === String(theTss));
    return thePrValue?.pr;
  }

  function findPercentileRankConfidenceIntervalFromNestedObj(tss: any, prObject: any) {
    const theTss = tss;
    const thePrArray = prObject[0];
    const thePrValue = thePrArray.filter((i: any) => String(i.tss) === String(theTss));
    return thePrValue[0]?.pr;
  }

  function findTestAgeFromNestedObj(tss: any, prObject: any) {
    const theTss = tss;
    const thePrArray = prObject[0];

    const RsidToUse = currentRsid;
    let prArrayToUse;
    switch (true) {
      case RsidToUse == 'fd':
        prArrayToUse = thePrArray.filter((i: any) => String(i.fd) === String(theTss));
        break;
      case RsidToUse == 'fs':
        prArrayToUse = thePrArray.filter((i: any) => String(i.fs) === String(theTss));
        break;
      case RsidToUse == 'lc':
        prArrayToUse = thePrArray.filter((i: any) => String(i.lc) === String(theTss));
        break;
      case RsidToUse == 'pac':
        prArrayToUse = thePrArray.filter((i: any) => String(i.pac) === String(theTss));
        break;
      case RsidToUse == 'pp':
        prArrayToUse = thePrArray.filter((i: any) => String(i.pp) === String(theTss));
        break;
      case RsidToUse == 'rs':
        prArrayToUse = thePrArray.filter((i: any) => String(i.rs) === String(theTss));
        break;
      case RsidToUse == 'sr':
        prArrayToUse = thePrArray.filter((i: any) => String(i.sr) === String(theTss));
        break;
      case RsidToUse == 'sa':
        prArrayToUse = thePrArray.filter((i: any) => String(i.sa) === String(theTss));
        break;
      case RsidToUse == 'sc':
        prArrayToUse = thePrArray.filter((i: any) => String(i.sc) === String(theTss));
        break;
      case RsidToUse == 'wc':
        prArrayToUse = thePrArray.filter((i: any) => String(i.wc) === String(theTss));
        break;
      case RsidToUse == 'wd':
        prArrayToUse = thePrArray.filter((i: any) => String(i.wd) === String(theTss));
        break;
      case RsidToUse == 'ws':
        prArrayToUse = thePrArray.filter((i: any) => String(i.ws) === String(theTss));
        break;
    }
    return prArrayToUse[0]?.testAge;
  }

  // function findElement(data: any, ageyears: number, agemonth: number, los: string) {
  //   const ageGroup = `${ageyears}:${agemonth}-${ageyears}:${
  //     agemonth + 5 < 12 ? agemonth + 5 : agemonth + 5 - 12
  //   }`;
  //   console.log('ageyears', ageyears);
  //   console.log('agemonth', agemonth);
  //   console.log('los', los);
  //   console.log('ageGroup', ageGroup);
  //   for (let record of data[0]) {
  //     if (String(record.age) === String(ageGroup) && String(record.los) === String(los)) {
  //       console.log('record.age', record.age);
  //       console.log('ageGroup=', ageGroup);

  //       return record;
  //     }
  //   }
  //   return null;
  // }

  function findElementUsingMonthAndYears(
    data: any,
    ageyears: number,
    agemonths: number,
    los: string
  ) {
    const ageGroup =
      ageyears <= 6
        ? agemonths <= 5
          ? `${ageyears}:0-${ageyears}:5`
          : `${ageyears}:6-${ageyears}:11`
        : ageyears >= 17
        ? `17:0-21:11`
        : `${ageyears}:0-${ageyears}:11`;

    console.log('ageyears', ageyears);
    console.log('agemonth', agemonths);
    console.log('los', los);
    console.log('ageGroup', ageGroup);
    for (let record of data[0]) {
      if (String(record.age) === String(ageGroup) && String(record.los) === String(los)) {
        console.log('record.age', record.age);
        console.log('ageGroup=', ageGroup);

        return record;
      }
    }
    return null;
  }

  function findElement(data: any, ageyears: number, agemonth: number, los: string) {
    const ageGroup = `${ageyears}:${agemonth}-${ageyears}:${
      agemonth + 5 < 12 ? agemonth + 5 : agemonth + 5 - 12
    }`;
    console.log('ageyears', ageyears);
    console.log('agemonth', agemonth);
    console.log('los', los);
    console.log('ageGroup', ageGroup);
    for (let record of data[0]) {
      if (String(record.age) === String(ageGroup) && String(record.los) === String(los)) {
        console.log('record.age', record.age);
        console.log('ageGroup=', ageGroup);

        return record;
      }
    }
    return null;
  }

  // appFCriticalValues;
  function findCriticalValueWithinNestedObj(appF: any) {
    const ssLevel = reportCompiler.ssLevel;
    const ageYears = reportCompiler.ageYears;
    const ageMonths = reportCompiler.ageMonths;
    const cvObject = appF;
    // const theCvArray = cvObject[0];
    // .slice(0, 2);

    let cvCiToUse;
    cvCiToUse = findElementUsingMonthAndYears(appFCriticalValues, ageYears, ageMonths, ssLevel);
    // //8:0-8:7
    localStorage.setItem('rliEli', cvCiToUse.rliEli);
    ageYears >= 9
      ? localStorage.setItem('lciLmi', cvCiToUse.lciLmi)
      : localStorage.setItem('lciLsi', cvCiToUse.lciLsi);
    console.log('cvCiToUse:', cvCiToUse);
  }

  const selectFinalRawScoreBasedOnCurrentCsid = () => {
    const RsidToUse = currentRsid;
    let rawScoreToUse;
    switch (true) {
      case RsidToUse == 'fd':
        rawScoreToUse = reportCompiler.fdFinaleScore;
        break;
      case RsidToUse == 'fs':
        rawScoreToUse = reportCompiler.fsFinaleScore;
        break;
      case RsidToUse == 'lc':
        rawScoreToUse = reportCompiler.lcFinaleScore;
        break;
      case RsidToUse == 'pac':
        rawScoreToUse = reportCompiler.pacFinaleScore;
        break;
      case RsidToUse == 'pp':
        rawScoreToUse = reportCompiler.ppFinaleScore;
        break;
      case RsidToUse == 'rs':
        rawScoreToUse = reportCompiler.rsFinaleScore;
        break;
      case RsidToUse == 'sr':
        rawScoreToUse = reportCompiler.srFinaleScore;
        break;
      case RsidToUse == 'sa':
        rawScoreToUse = reportCompiler.saFinaleScore;
        break;
      case RsidToUse == 'sc':
        rawScoreToUse = reportCompiler.scFinaleScore;
        break;
      case RsidToUse == 'wc':
        rawScoreToUse = reportCompiler.wcFinaleScore;
        break;
      case RsidToUse == 'wd':
        rawScoreToUse = reportCompiler.wdFinaleScore;
        break;
      case RsidToUse == 'ws':
        rawScoreToUse = reportCompiler.wsFinaleScore;
        break;
      // case RsidToUse == 'usp':
      //   rawScoreToUse = reportCompiler.uspFinaleScore;
      //   break;
      case RsidToUse === 'fiveToSixUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'sevenToEightUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'nineToTenUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'elevenToTwelveUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'thirteenToFourteenUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'fifteenToTwentyOneUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
    }
    return rawScoreToUse;
  };

  function getFinaleScore(RsidToUse: string) {
    let rawScoreToUse;
    switch (true) {
      case RsidToUse == 'fd':
        rawScoreToUse = reportCompiler.fdFinaleScore;
        break;
      case RsidToUse == 'fs':
        rawScoreToUse = reportCompiler.fsFinaleScore;
        break;
      case RsidToUse == 'lc':
        rawScoreToUse = reportCompiler.lcFinaleScore;
        break;
      case RsidToUse == 'pac':
        rawScoreToUse = reportCompiler.pacFinaleScore;
        break;
      case RsidToUse == 'pp':
        rawScoreToUse = reportCompiler.ppFinaleScore;
        break;
      case RsidToUse == 'rs':
        rawScoreToUse = reportCompiler.rsFinaleScore;
        break;
      case RsidToUse == 'sr':
        rawScoreToUse = reportCompiler.srFinaleScore;
        break;
      case RsidToUse == 'sa':
        rawScoreToUse = reportCompiler.saFinaleScore;
        break;
      case RsidToUse == 'sc':
        rawScoreToUse = reportCompiler.scFinaleScore;
        break;
      case RsidToUse == 'wc':
        rawScoreToUse = reportCompiler.wcFinaleScore;
        break;
      case RsidToUse == 'wd':
        rawScoreToUse = reportCompiler.wdFinaleScore;
        break;
      case RsidToUse == 'ws':
        rawScoreToUse = reportCompiler.wsFinaleScore;
        break;
      // case RsidToUse == 'usp':
      //   rawScoreToUse = reportCompiler.uspFinaleScore;
      //   break;
      case RsidToUse === 'fiveToSixUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'sevenToEightUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'nineToTenUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'elevenToTwelveUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'thirteenToFourteenUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
      case RsidToUse === 'fifteenToTwentyOneUsp':
        rawScoreToUse = reportCompiler.uspFinaleScore;
        break;
    }
    return rawScoreToUse;
  }
  const standardScoreToUseFinal = appendixBStandardScoresBasedOnAge();

  const transformArrayToMap = (data: any) => {
    const newArray = data.map((item: any, key: number) => ({
      ...item,
      id: key + 1,
    }));
    return newArray;
  };

  function removeDuplicates(arr: any) {
    return [...new Set(arr)];
  }

  function runItemsAnalysis(itemId: string, sheetToUseArray: any, categoriesArray: any) {
    sheetToUseArray[0].map((item: any) => {
      const theItems = item.items;
      theItems?.includes(String(itemId)) && categoriesArray.push(item.category);
    });
    return categoriesArray;
  }

  const CalculateReport = (theRsid: any) => {
    const scaledScoreToUseFinal = appendixAScaledScoresBasedOnAge();
    const confidenceIntervalToUseFinal = appendixAConfidenceIntervalBasedOnAge();
    const rawScoreToUseToFind = selectFinalRawScoreBasedOnCurrentCsid();

    const scaledScoreRsid =
      theRsid === 'fiveToSixUsp' ||
      theRsid === 'sevenToEightUsp' ||
      theRsid === 'nineToTenUsp' ||
      theRsid === 'elevenToTwelveUsp' ||
      theRsid === 'thirteenToFourteenUsp' ||
      theRsid === 'fifteenToTwentyOneUsp'
        ? 'usp'
        : theRsid;
    const scaledScore = findWitinNestedObj(
      scaledScoreToUseFinal, //tab to use
      scaledScoreRsid, // currentRsid
      rawScoreToUseToFind // value
    );

    const ciFd = findScaledScorePlusMinusFromNestedObj(
      reportCompiler.ciLevel,
      confidenceIntervalToUseFinal
    );

    const prFd = findPercentileRankFromNestedObj(
      reportCompilerOutput.scaledScore !== undefined
        ? reportCompilerOutput.scaledScore
        : scaledScore,
      appDPercentileRank
    );

    const ciMin =
      Number(
        reportCompiler && reportCompilerOutput?.scaledScore !== undefined
          ? reportCompiler && reportCompilerOutput?.scaledScore
          : scaledScore
      ) -
      Number(
        findScaledScorePlusMinusFromNestedObj(reportCompiler.ciLevel, confidenceIntervalToUseFinal)
      );
    const ciMax =
      Number(
        reportCompiler && reportCompilerOutput?.scaledScore !== undefined
          ? reportCompiler && reportCompilerOutput?.scaledScore
          : scaledScore
      ) +
      Number(
        findScaledScorePlusMinusFromNestedObj(reportCompiler.ciLevel, confidenceIntervalToUseFinal)
      );

    const prciFromFd = findPercentileRankConfidenceIntervalFromNestedObj(
      Number(
        reportCompiler && reportCompilerOutput?.scaledScore !== undefined
          ? reportCompiler && reportCompilerOutput?.scaledScore
          : scaledScore
      ) -
        Number(
          findScaledScorePlusMinusFromNestedObj(
            reportCompiler.ciLevel,
            confidenceIntervalToUseFinal
          )
        ),
      appDPercentileRank
    );

    const prciToFd = findPercentileRankConfidenceIntervalFromNestedObj(
      Number(
        reportCompiler && reportCompilerOutput?.scaledScore !== undefined
          ? reportCompiler && reportCompilerOutput?.scaledScore
          : scaledScore
      ) +
        Number(
          findScaledScorePlusMinusFromNestedObj(
            reportCompiler.ciLevel,
            confidenceIntervalToUseFinal
          )
        ),
      appDPercentileRank
    );
    console.log('theRsid: ', theRsid);
    const testAge =
      theRsid === 'fiveToSixUsp' ||
      theRsid === 'sevenToEightUsp' ||
      theRsid === 'nineToTenUsp' ||
      theRsid === 'elevenToTwelveUsp' ||
      theRsid === 'thirteenToFourteenUsp' ||
      theRsid === 'fifteenToTwentyOneUsp'
        ? null
        : findTestAgeFromNestedObj(getFinaleScore(currentRsid), appCTestAgeEquivalent);

    console.log('scaledScoreToUseFinal:', scaledScoreToUseFinal);
    console.log('confidenceIntervalToUseFinal:', confidenceIntervalToUseFinal);
    console.log('selectedCsid:', currentRsid);
    console.log('rawScoreToUseToFind:', rawScoreToUseToFind);

    console.log('value to save to compiler output', scaledScore);
    console.log('ciMin:', ciMin);
    console.log('ciMax:', ciMax);
    console.log('ciFd', reportCompilerOutput && reportCompilerOutput.ciFd);
    console.log('scaledScore:', scaledScore);
    console.log(
      'reportCompilerOutput?.scaledScore',
      reportCompiler && reportCompilerOutput?.scaledScore
    );

    // setUrls((currentUrls) => ([
    //   ...currentUrls,
    //   newItem
    // ]));

    // const addItem = () => {
    //   const newItem = { id: items.length + 1, name: `Item ${items.length + 1}` };
    //   setItems([...items, newItem]);
    // };
    findCriticalValueWithinNestedObj(appFCriticalValues);

    function getValuesFromReportCompiler() {
      const theCurrentRsid = currentRsid;
      let compilerValues;
      switch (true) {
        case theCurrentRsid == 'fd':
          compilerValues = reportCompiler.fd;
          break;
        case theCurrentRsid == 'fs':
          compilerValues = reportCompiler.fs;
          break;
        case theCurrentRsid == 'lc':
          compilerValues = reportCompiler.lc;
          break;
        case theCurrentRsid == 'pac':
          compilerValues = reportCompiler.pac;
          break;
        case theCurrentRsid == 'pp':
          compilerValues = reportCompiler.pp;
          break;
        case theCurrentRsid == 'rs':
          compilerValues = reportCompiler.rs;
          break;
        case theCurrentRsid == 'sr':
          compilerValues = reportCompiler.sr;
          break;
        case theCurrentRsid == 'sa':
          compilerValues = reportCompiler.sa;
          break;
        case theCurrentRsid == 'sc':
          compilerValues = reportCompiler.sc;
          break;
        case theCurrentRsid == 'wc':
          compilerValues = reportCompiler.wc;
          break;
        case theCurrentRsid == 'wd':
          compilerValues = reportCompiler.wd;
          break;
        case theCurrentRsid == 'ws':
          compilerValues = reportCompiler.ws;
          break;
        case theCurrentRsid === 'fiveToSixUsp':
          compilerValues = reportCompiler.usp;
          break;
        case theCurrentRsid === 'sevenToEightUsp':
          compilerValues = reportCompiler.usp;
          break;
        case theCurrentRsid === 'nineToTenUsp':
          compilerValues = reportCompiler.usp;
          break;
        case theCurrentRsid === 'elevenToTwelveUsp':
          compilerValues = reportCompiler.usp;
          break;
        case theCurrentRsid === 'thirteenToFourteenUsp':
          compilerValues = reportCompiler.usp;
          break;
        case theCurrentRsid === 'fifteenToTwentyOneUsp':
          compilerValues = reportCompiler.usp;
          break;
      }
      return compilerValues;
    }
    const unfilteredValues = getValuesFromReportCompiler();
    const newData = transformArrayToMap(unfilteredValues);

    // 'fd': 0 to 1
    // 'fs': 0 to 2
    // 'lc': 0 to 1
    // 'pp': 1 to 4
    // 'rs': 0 to 3
    // 'sr': 0 to 1
    // 'sa': 0 to 1
    // 'sc': 0 to 1
    // 'wc': 0 to 1
    // 'wd': 0 to 1
    // 'ws': 0 to 1
    // 'pac': 0 to 1
    // 'fiveToSixUsp': 0 to 1
    // 'sevenToEightUsp': 0 to 1
    // 'nineToTenUsp': 0 to 1
    // 'elevenToTwelveUsp': 0 to 1
    // 'thirteenToFourteenUsp': 0 to 1
    // 'fifteenToTwentyOneUsp': 0 to 1

    function checkForValueRangeTypes(newData: any, currentRsid: any, valueToFilter: string) {
      const theCurrentRsid = currentRsid;
      let newDataFilters;

      newDataFilters = newData
        ? newData.filter((z: any) => String(z.enteredValue) === valueToFilter)
        : [];
      // switch (true) {
      //   case theCurrentRsid == 'fd':
      //     newDataFilters = newData
      //       ? newData.filter((z: any) => String(z.enteredValue) === valueToFilter)
      //       : [];
      //     break;
      //   case theCurrentRsid == 'fs':
      //     newDataFilters = newData
      //       ? newData.filter((z: any) => String(z.enteredValue) === valueToFilter)
      //       : [];
      //     break;
      //   case theCurrentRsid == 'lc':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'pac':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'pp':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) =>
      //             String(z.enteredValue) === '1' ||
      //             String(z.enteredValue) === '2' ||
      //             String(z.enteredValue) === '3' ||
      //             String(z.enteredValue) === '4'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'rs':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) =>
      //             String(z.enteredValue) === '0' ||
      //             String(z.enteredValue) === '1' ||
      //             String(z.enteredValue) === '2' ||
      //             String(z.enteredValue) === '3'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'sr':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'sa':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'sc':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'wc':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'wd':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'ws':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'fiveToSixUsp':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'sevenToEightUsp':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'nineToTenUsp':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'elevenToTwelveUsp':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'thirteenToFourteenUsp':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      //   case theCurrentRsid == 'fifteenToTwentyOneUsp':
      //     newDataFilters = newData
      //       ? newData.filter(
      //           (z: any) => String(z.enteredValue) === '0' || String(z.enteredValue) === '1'
      //         )
      //       : [];
      //     break;
      // }
      return newDataFilters;
    }

    function checkWhichRawScoreSheetToUse(currentRsid: any) {
      const theCurrentRsid = currentRsid;
      let rawScoreSheet;
      switch (true) {
        case theCurrentRsid == 'fd':
          rawScoreSheet = followingDirectionsJson;
          break;
        case theCurrentRsid == 'fs':
          rawScoreSheet = formulatedSentencesJson;
          break;
        case theCurrentRsid == 'lc':
          rawScoreSheet = linguisticConceptsJson;
          break;
        case theCurrentRsid == 'pac':
          rawScoreSheet = pragmaticsActivitiesChecklistJson;
          break;
        case theCurrentRsid == 'pp':
          rawScoreSheet = pragmaticsProfileJson;
          break;
        case theCurrentRsid == 'rs':
          rawScoreSheet = recallingSentencesJson;
          break;
        case theCurrentRsid == 'sr':
          rawScoreSheet = semanticRelationshipsJson;
          break;
        case theCurrentRsid == 'sa':
          rawScoreSheet = sentenceAssemblyJson;
          break;
        case theCurrentRsid == 'sc':
          rawScoreSheet = sentenceComprehensionJson;
          break;
        case theCurrentRsid == 'wc':
          rawScoreSheet = wordClassesJson;
          break;
        case theCurrentRsid == 'wd':
          rawScoreSheet = wordDefinitionsJson;
          break;
        case theCurrentRsid == 'ws':
          rawScoreSheet = wordStructuresJson;
          break;
        case theCurrentRsid == 'fiveToSixUsp':
          rawScoreSheet = fiveToSixUspJson;
          break;
        case theCurrentRsid == 'sevenToEightUsp':
          rawScoreSheet = sevenToEightUspJson;
          break;
        case theCurrentRsid == 'nineToTenUsp':
          rawScoreSheet = nineToTenUspJson;
          break;
        case theCurrentRsid == 'elevenToTwelveUsp':
          rawScoreSheet = elevenToTwelveUspJson;
          break;
        case theCurrentRsid == 'thirteenToFourteenUsp':
          rawScoreSheet = thirteenToFourteenUspJson;
          break;
        case theCurrentRsid == 'fifteenToTwentyOneUsp':
          rawScoreSheet = fifteenToTwentyOneUspJson;
          break;
      }
      return rawScoreSheet;
    }

    const fdFilteredValuesOne = checkForValueRangeTypes(newData, 'fd', '1');
    const fdFilteredValuesZero = checkForValueRangeTypes(newData, 'fd', '0');

    const fsFilteredValuesZero = checkForValueRangeTypes(newData, 'fs', '0');
    const fsFilteredValuesOne = checkForValueRangeTypes(newData, 'fs', '1');
    const fsFilteredValuesTwo = checkForValueRangeTypes(newData, 'fs', '2');

    const lcFilteredValuesZero = checkForValueRangeTypes(newData, 'lc', '0');
    const lcFilteredValuesOne = checkForValueRangeTypes(newData, 'lc', '1');

    const pacFilteredValuesZero = checkForValueRangeTypes(newData, 'pac', '0');
    const pacFilteredValuesOne = checkForValueRangeTypes(newData, 'pac', '1');

    const ppFilteredValuesOne = checkForValueRangeTypes(newData, 'pp', '1');
    const ppFilteredValuesTwo = checkForValueRangeTypes(newData, 'pp', '2');
    const ppFilteredValuesThree = checkForValueRangeTypes(newData, 'pp', '3');
    const ppFilteredValuesFour = checkForValueRangeTypes(newData, 'pp', '4');

    const rsFilteredValuesZero = checkForValueRangeTypes(newData, 'rs', '0');
    const rsFilteredValuesOne = checkForValueRangeTypes(newData, 'rs', '1');
    const rsFilteredValuesTwo = checkForValueRangeTypes(newData, 'rs', '2');
    const rsFilteredValuesThree = checkForValueRangeTypes(newData, 'rs', '3');

    const srFilteredValuesZero = checkForValueRangeTypes(newData, 'sr', '0');
    const srFilteredValuesOne = checkForValueRangeTypes(newData, 'sr', '1');

    const saFilteredValuesZero = checkForValueRangeTypes(newData, 'sa', '0');
    const saFilteredValuesOne = checkForValueRangeTypes(newData, 'sa', '1');

    const scFilteredValuesZero = checkForValueRangeTypes(newData, 'sc', '0');
    const scFilteredValuesOne = checkForValueRangeTypes(newData, 'sc', '1');

    const wcFilteredValuesZero = checkForValueRangeTypes(newData, 'wc', '0');
    const wcFilteredValuesOne = checkForValueRangeTypes(newData, 'wc', '1');

    const wdFilteredValuesZero = checkForValueRangeTypes(newData, 'wd', '0');
    const wdFilteredValuesOne = checkForValueRangeTypes(newData, 'wd', '1');

    const wsFilteredValuesZero = checkForValueRangeTypes(newData, 'ws', '0');
    const wsFilteredValuesOne = checkForValueRangeTypes(newData, 'ws', '1');

    const fiveToSixUspFilteredValuesZero = checkForValueRangeTypes(newData, 'fiveToSixUsp', '0');
    const fiveToSixUspFilteredValuesOne = checkForValueRangeTypes(newData, 'fiveToSixUsp', '1');

    const sevenToEightUspFilteredValuesZero = checkForValueRangeTypes(
      newData,
      'sevenToEightUsp',
      '0'
    );
    const sevenToEightUspFilteredValuesOne = checkForValueRangeTypes(
      newData,
      'sevenToEightUsp',
      '1'
    );

    const nineToTenUspFilteredValuesZero = checkForValueRangeTypes(newData, 'nineToTenUsp', '0');
    const nineToTenUspFilteredValuesOne = checkForValueRangeTypes(newData, 'nineToTenUsp', '1');

    const elevenToTwelveUspFilteredValuesZero = checkForValueRangeTypes(
      newData,
      'elevenToTwelveUsp',
      '0'
    );
    const elevenToTwelveUspFilteredValuesOne = checkForValueRangeTypes(
      newData,
      'elevenToTwelveUsp',
      '1'
    );

    const thirteenToFourteenUspFilteredValuesZero = checkForValueRangeTypes(
      newData,
      'thirteenToFourteenUsp',
      '0'
    );
    const thirteenToFourteenUspFilteredValuesOne = checkForValueRangeTypes(
      newData,
      'thirteenToFourteenUsp',
      '1'
    );

    const fifteenToTwentyOneUspFilteredValuesZero = checkForValueRangeTypes(
      newData,
      'fifteenToTwentyOneUsp',
      '0'
    );
    const fifteenToTwentyOneUspFilteredValuesOne = checkForValueRangeTypes(
      newData,
      'fifteenToTwentyOneUsp',
      '1'
    );

    function getListofCategories(currentRsid: any, filteredValuesToUse: any) {
      const categoriesArray: any[] = [];
      const sheetToUse = checkWhichRawScoreSheetToUse(currentRsid);
      const theArray = filteredValuesToUse.map((o: any, u: any) =>
        runItemsAnalysis(o.id, sheetToUse, categoriesArray)
      );
      return removeDuplicates(theArray[0]);
    }
    // console.log('scFilteredValues: ', scFilteredValues);

    const fdItemAnalysisValuesZero = getListofCategories('fd', fdFilteredValuesZero);
    const fdItemAnalysisValuesOne = getListofCategories('fd', fdFilteredValuesOne);

    const fsItemAnalysisValuesZero = getListofCategories('fs', fsFilteredValuesZero);
    const fsItemAnalysisValuesOne = getListofCategories('fs', fsFilteredValuesOne);
    const fsItemAnalysisValuesTwo = getListofCategories('fs', fsFilteredValuesTwo);

    const lcItemAnalysisValuesZero = getListofCategories('lc', lcFilteredValuesZero);
    const lcItemAnalysisValuesOne = getListofCategories('lc', lcFilteredValuesOne);

    const pacItemAnalysisValuesZero = getListofCategories('pac', pacFilteredValuesZero);
    const pacItemAnalysisValuesOne = getListofCategories('pac', pacFilteredValuesOne);

    const ppItemAnalysisValuesOne = getListofCategories('pp', ppFilteredValuesOne);
    const ppItemAnalysisValuesTwo = getListofCategories('pp', ppFilteredValuesTwo);
    const ppItemAnalysisValuesThree = getListofCategories('pp', ppFilteredValuesThree);
    const ppItemAnalysisValuesFour = getListofCategories('pp', ppFilteredValuesFour);

    const rsItemAnalysisValuesZero = getListofCategories('rs', rsFilteredValuesZero);
    const rsItemAnalysisValuesOne = getListofCategories('rs', rsFilteredValuesOne);
    const rsItemAnalysisValuesTwo = getListofCategories('rs', rsFilteredValuesTwo);
    const rsItemAnalysisValuesThree = getListofCategories('rs', rsFilteredValuesThree);

    const srItemAnalysisValuesZero = getListofCategories('sr', srFilteredValuesZero);
    const srItemAnalysisValuesOne = getListofCategories('sr', srFilteredValuesOne);

    const saItemAnalysisValuesZero = getListofCategories('sa', saFilteredValuesZero);
    const saItemAnalysisValuesOne = getListofCategories('sa', saFilteredValuesOne);

    const scItemAnalysisValuesZero = getListofCategories('sc', scFilteredValuesZero);
    const scItemAnalysisValuesOne = getListofCategories('sc', scFilteredValuesOne);

    const wcItemAnalysisValuesZero = getListofCategories('wc', wcFilteredValuesZero);
    const wcItemAnalysisValuesOne = getListofCategories('wc', wcFilteredValuesOne);

    const wdItemAnalysisValuesZero = getListofCategories('wd', wdFilteredValuesZero);
    const wdItemAnalysisValuesOne = getListofCategories('wd', wdFilteredValuesOne);

    const wsItemAnalysisValuesZero = getListofCategories('ws', wsFilteredValuesZero);
    const wsItemAnalysisValuesOne = getListofCategories('ws', wsFilteredValuesOne);

    const fiveToSixUspItemAnalysisValuesZero = getListofCategories(
      'fiveToSixUsp',
      fiveToSixUspFilteredValuesZero
    );
    const fiveToSixUspItemAnalysisValuesOne = getListofCategories(
      'fiveToSixUsp',
      fiveToSixUspFilteredValuesOne
    );

    const sevenToEightUspItemAnalysisValuesZero = getListofCategories(
      'sevenToEightUsp',
      sevenToEightUspFilteredValuesZero
    );
    const sevenToEightUspItemAnalysisValuesOne = getListofCategories(
      'sevenToEightUsp',
      sevenToEightUspFilteredValuesOne
    );

    const nineToTenUspItemAnalysisValuesZero = getListofCategories(
      'nineToTenUsp',
      nineToTenUspFilteredValuesZero
    );
    const nineToTenUspItemAnalysisValuesOne = getListofCategories(
      'nineToTenUsp',
      nineToTenUspFilteredValuesOne
    );

    const elevenToTwelveUspItemAnalysisValuesZero = getListofCategories(
      'elevenToTwelveUsp',
      elevenToTwelveUspFilteredValuesZero
    );
    const elevenToTwelveUspItemAnalysisValuesOne = getListofCategories(
      'elevenToTwelveUsp',
      elevenToTwelveUspFilteredValuesOne
    );

    const thirteenToFourteenUspItemAnalysisValuesZero = getListofCategories(
      'thirteenToFourteenUsp',
      thirteenToFourteenUspFilteredValuesZero
    );
    const thirteenToFourteenUspItemAnalysisValuesOne = getListofCategories(
      'thirteenToFourteenUsp',
      thirteenToFourteenUspFilteredValuesOne
    );

    const fifteenToTwentyOneUspItemAnalysisValuesZero = getListofCategories(
      'fifteenToTwentyOneUsp',
      fifteenToTwentyOneUspFilteredValuesZero
    );
    const fifteenToTwentyOneUspItemAnalysisValuesOne = getListofCategories(
      'fifteenToTwentyOneUsp',
      fifteenToTwentyOneUspFilteredValuesOne
    );

    const newItem = {
      rsid: currentRsid,
      rsidReference: currentRsid.includes('Usp') ? 'usp' : currentRsid,
      title: rsTitle,
      scaledScore: scaledScore,
      ciFd: ciFd,
      prFd: prFd,
      ciMin: ciMin,
      ciMax: ciMax,
      prciFromFd: prciFromFd,
      prciToFd: prciToFd,
      testAge: testAge,
      clsSumOfScaledScore: clsSumOfScaledScore,
      rliSumOfScaledScore: rliSumOfScaledScore,
      eliSumOfScaledScore: eliSumOfScaledScore,
      lciSumOfScaledScore: lciSumOfScaledScore,
      lsiOrLmiSumOfScaledScore: lsiOrLmiSumOfScaledScore,

      fdItemsAnalysisValuesZero:
        currentRsid === 'fd'
          ? fdFilteredValuesZero
          : reportCompilerOutput.fdItemsAnalysisValuesZero,
      fdItemsAnalysisValuesCategoriesZero:
        currentRsid === 'fd'
          ? fdItemAnalysisValuesZero
          : reportCompilerOutput.fdItemsAnalysisValuesCategoriesZero,

      fdItemsAnalysisValuesOne:
        currentRsid === 'fd' ? fdFilteredValuesOne : reportCompilerOutput.fdItemsAnalysisValuesOne,
      fdItemsAnalysisValuesCategoriesOne:
        currentRsid === 'fd'
          ? fdItemAnalysisValuesOne
          : reportCompilerOutput.fdItemsAnalysisValuesCategoriesOne,

      fsItemsAnalysisValuesZero:
        currentRsid === 'fs'
          ? fsFilteredValuesZero
          : reportCompilerOutput.fsItemsAnalysisValuesZero,
      fsItemsAnalysisValuesCategoriesZero:
        currentRsid === 'fs'
          ? fsItemAnalysisValuesZero
          : reportCompilerOutput.fsItemsAnalysisValuesCategoriesZero,

      fsItemsAnalysisValuesOne:
        currentRsid === 'fs' ? fsFilteredValuesOne : reportCompilerOutput.fsItemsAnalysisValuesOne,
      fsItemsAnalysisValuesCategoriesOne:
        currentRsid === 'fs'
          ? fsItemAnalysisValuesOne
          : reportCompilerOutput.fsItemsAnalysisValuesCategoriesOne,

      fsItemsAnalysisValuesTwo:
        currentRsid === 'fs' ? fsFilteredValuesTwo : reportCompilerOutput.fsItemsAnalysisValuesTwo,
      fsItemsAnalysisValuesCategoriesTwo:
        currentRsid === 'fs'
          ? fsItemAnalysisValuesTwo
          : reportCompilerOutput.fsItemsAnalysisValuesCategoriesTwo,

      lcItemsAnalysisValuesZero:
        currentRsid === 'lc'
          ? lcFilteredValuesZero
          : reportCompilerOutput.lcItemsAnalysisValuesZero,
      lcItemsAnalysisValuesCategoriesZero:
        currentRsid === 'lc'
          ? lcItemAnalysisValuesZero
          : reportCompilerOutput.lcItemsAnalysisValuesCategoriesZero,

      lcItemsAnalysisValuesOne:
        currentRsid === 'lc' ? lcFilteredValuesOne : reportCompilerOutput.lcItemsAnalysisValuesOne,
      lcItemsAnalysisValuesCategoriesOne:
        currentRsid === 'lc'
          ? lcItemAnalysisValuesOne
          : reportCompilerOutput.lcItemsAnalysisValuesCategoriesOne,

      pacItemsAnalysisValuesZero:
        currentRsid === 'pac'
          ? pacFilteredValuesZero
          : reportCompilerOutput.pacItemsAnalysisValuesZero,
      pacItemsAnalysisValuesCategoriesZero:
        currentRsid === 'pac'
          ? pacItemAnalysisValuesZero
          : reportCompilerOutput.pacItemsAnalysisValuesCategoriesZero,

      pacItemsAnalysisValuesOne:
        currentRsid === 'pac'
          ? pacFilteredValuesOne
          : reportCompilerOutput.pacItemsAnalysisValuesOne,
      pacItemsAnalysisValuesCategoriesOne:
        currentRsid === 'pac'
          ? pacItemAnalysisValuesOne
          : reportCompilerOutput.pacItemsAnalysisValuesCategoriesOne,

      ppItemsAnalysisValuesOne:
        currentRsid === 'pp' ? ppFilteredValuesOne : reportCompilerOutput.ppItemsAnalysisValuesOne,
      ppItemsAnalysisValuesCategoriesOne:
        currentRsid === 'pp'
          ? ppItemAnalysisValuesOne
          : reportCompilerOutput.ppItemsAnalysisValuesCategoriesOne,

      ppItemsAnalysisValuesTwo:
        currentRsid === 'pp' ? ppFilteredValuesTwo : reportCompilerOutput.ppItemsAnalysisValuesTwo,
      ppItemsAnalysisValuesCategoriesTwo:
        currentRsid === 'pp'
          ? ppItemAnalysisValuesTwo
          : reportCompilerOutput.ppItemsAnalysisValuesCategoriesTwo,

      ppItemsAnalysisValuesThree:
        currentRsid === 'pp'
          ? ppFilteredValuesThree
          : reportCompilerOutput.ppItemsAnalysisValuesThree,
      ppItemsAnalysisValuesCategoriesThree:
        currentRsid === 'pp'
          ? ppItemAnalysisValuesThree
          : reportCompilerOutput.ppItemsAnalysisValuesCategoriesThree,

      ppItemsAnalysisValuesFour:
        currentRsid === 'pp'
          ? ppFilteredValuesFour
          : reportCompilerOutput.ppItemsAnalysisValuesFour,
      ppItemsAnalysisValuesCategoriesFour:
        currentRsid === 'pp'
          ? ppItemAnalysisValuesFour
          : reportCompilerOutput.ppItemsAnalysisValuesCategoriesFour,

      rsItemsAnalysisValuesZero:
        currentRsid === 'rs'
          ? rsFilteredValuesZero
          : reportCompilerOutput.rsItemsAnalysisValuesZero,
      rsItemsAnalysisValuesCategoriesZero:
        currentRsid === 'rs'
          ? rsItemAnalysisValuesZero
          : reportCompilerOutput.rsItemsAnalysisValuesCategoriesZero,

      rsItemsAnalysisValuesOne:
        currentRsid === 'rs' ? rsFilteredValuesOne : reportCompilerOutput.rsItemsAnalysisValuesOne,
      rsItemsAnalysisValuesCategoriesOne:
        currentRsid === 'rs'
          ? rsItemAnalysisValuesOne
          : reportCompilerOutput.rsItemsAnalysisValuesCategoriesOne,

      rsItemsAnalysisValuesTwo:
        currentRsid === 'rs' ? rsFilteredValuesTwo : reportCompilerOutput.rsItemsAnalysisValuesTwo,
      rsItemsAnalysisValuesCategoriesTwo:
        currentRsid === 'rs'
          ? rsItemAnalysisValuesTwo
          : reportCompilerOutput.rsItemsAnalysisValuesCategoriesTwo,

      rsItemsAnalysisValuesThree:
        currentRsid === 'rs'
          ? rsFilteredValuesThree
          : reportCompilerOutput.rsItemsAnalysisValuesThree,
      rsItemsAnalysisValuesCategoriesThree:
        currentRsid === 'rs'
          ? rsItemAnalysisValuesThree
          : reportCompilerOutput.rsItemsAnalysisValuesCategoriesThree,

      srItemsAnalysisValuesZero:
        currentRsid === 'sr'
          ? srFilteredValuesZero
          : reportCompilerOutput.srItemsAnalysisValuesZero,
      srItemsAnalysisValuesCategoriesZero:
        currentRsid === 'sr'
          ? srItemAnalysisValuesZero
          : reportCompilerOutput.srItemsAnalysisValuesCategoriesZero,

      srItemsAnalysisValuesOne:
        currentRsid === 'sr' ? srFilteredValuesOne : reportCompilerOutput.srItemsAnalysisValuesOne,
      srItemsAnalysisValuesCategoriesOne:
        currentRsid === 'sr'
          ? srItemAnalysisValuesOne
          : reportCompilerOutput.srItemsAnalysisValuesCategoriesOne,

      saItemsAnalysisValuesZero:
        currentRsid === 'sa'
          ? saFilteredValuesZero
          : reportCompilerOutput.saItemsAnalysisValuesZero,
      saItemsAnalysisValuesCategoriesZero:
        currentRsid === 'sa'
          ? saItemAnalysisValuesZero
          : reportCompilerOutput.saItemsAnalysisValuesCategoriesZero,

      saItemsAnalysisValuesOne:
        currentRsid === 'sa' ? saFilteredValuesOne : reportCompilerOutput.saItemsAnalysisValuesOne,
      saItemsAnalysisValuesCategoriesOne:
        currentRsid === 'sa'
          ? saItemAnalysisValuesOne
          : reportCompilerOutput.saItemsAnalysisValuesCategoriesOne,

      scItemsAnalysisValuesZero:
        currentRsid === 'sc' ? scFilteredValuesOne : reportCompilerOutput.scItemsAnalysisValuesZero,
      scItemsAnalysisValuesCategoriesZero:
        currentRsid === 'sc'
          ? scItemAnalysisValuesZero
          : reportCompilerOutput.scItemsAnalysisValuesCategoriesZero,

      scItemsAnalysisValuesOne:
        currentRsid === 'sc' ? scFilteredValuesOne : reportCompilerOutput.scItemsAnalysisValuesOne,
      scItemsAnalysisValuesCategoriesOne:
        currentRsid === 'sc'
          ? scItemAnalysisValuesOne
          : reportCompilerOutput.scItemsAnalysisValuesCategoriesOne,

      wcItemsAnalysisValuesZero:
        currentRsid === 'wc'
          ? wcFilteredValuesZero
          : reportCompilerOutput.wcItemsAnalysisValuesZero,
      wcItemsAnalysisValuesCategoriesZero:
        currentRsid === 'wc'
          ? wcItemAnalysisValuesZero
          : reportCompilerOutput.wcItemsAnalysisValuesCategoriesZero,

      wcItemsAnalysisValuesOne:
        currentRsid === 'wc' ? wcFilteredValuesOne : reportCompilerOutput.wcItemsAnalysisValuesOne,
      wcItemsAnalysisValuesCategoriesOne:
        currentRsid === 'wc'
          ? wcItemAnalysisValuesOne
          : reportCompilerOutput.wcItemsAnalysisValuesCategoriesOne,

      wdItemsAnalysisValuesZero:
        currentRsid === 'wd'
          ? wdFilteredValuesZero
          : reportCompilerOutput.wdItemsAnalysisValuesZero,
      wdItemsAnalysisValuesCategoriesZero:
        currentRsid === 'wd'
          ? wdItemAnalysisValuesZero
          : reportCompilerOutput.wdItemsAnalysisValuesCategoriesZero,

      wdItemsAnalysisValuesOne:
        currentRsid === 'wd' ? wdFilteredValuesOne : reportCompilerOutput.wdItemsAnalysisValuesOne,
      wdItemsAnalysisValuesCategoriesOne:
        currentRsid === 'wd'
          ? wdItemAnalysisValuesOne
          : reportCompilerOutput.wdItemsAnalysisValuesCategoriesOne,

      wsItemsAnalysisValuesZero:
        currentRsid === 'ws'
          ? wsFilteredValuesZero
          : reportCompilerOutput.wsItemsAnalysisValuesZero,
      wsItemsAnalysisValuesCategoriesZero:
        currentRsid === 'ws'
          ? wsItemAnalysisValuesZero
          : reportCompilerOutput.wsItemsAnalysisValuesCategoriesZero,

      wsItemsAnalysisValuesOne:
        currentRsid === 'ws' ? wsFilteredValuesOne : reportCompilerOutput.wsItemsAnalysisValuesOne,
      wsItemsAnalysisValuesCategoriesOne:
        currentRsid === 'ws'
          ? wsItemAnalysisValuesOne
          : reportCompilerOutput.wsItemsAnalysisValuesCategoriesOne,

      fiveToSixUspItemsAnalysisValuesZero:
        currentRsid === 'fiveToSixUsp'
          ? fiveToSixUspFilteredValuesZero
          : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesZero,
      fiveToSixUspItemsAnalysisValuesCategoriesZero:
        currentRsid === 'fiveToSixUsp'
          ? fiveToSixUspItemAnalysisValuesZero
          : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesCategoriesZero,

      fiveToSixUspItemsAnalysisValuesOne:
        currentRsid === 'fiveToSixUsp'
          ? fiveToSixUspFilteredValuesOne
          : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesOne,
      fiveToSixUspItemsAnalysisValuesCategoriesOne:
        currentRsid === 'fiveToSixUsp'
          ? fiveToSixUspItemAnalysisValuesOne
          : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesCategoriesOne,

      sevenToEightUspItemsAnalysisValuesZero:
        currentRsid === 'sevenToEightUsp'
          ? sevenToEightUspFilteredValuesZero
          : reportCompilerOutput.sevenToEightUspItemsAnalysisValuesZero,
      sevenToEightUspItemsAnalysisValuesCategoriesZero:
        currentRsid === 'sevenToEightUsp'
          ? sevenToEightUspItemAnalysisValuesZero
          : reportCompilerOutput.sevenToEightUspItemsAnalysisValuesCategoriesZero,

      sevenToEightUspItemsAnalysisValuesOne:
        currentRsid === 'sevenToEightUsp'
          ? sevenToEightUspFilteredValuesOne
          : reportCompilerOutput.sevenToEightUspItemsAnalysisValuesOne,
      sevenToEightUspItemsAnalysisValuesCategoriesOne:
        currentRsid === 'sevenToEightUsp'
          ? sevenToEightUspItemAnalysisValuesOne
          : reportCompilerOutput.sevenToEightUspItemsAnalysisValuesCategoriesOne,

      nineToTenUspItemsAnalysisValuesZero:
        currentRsid === 'nineToTenUsp'
          ? nineToTenUspFilteredValuesZero
          : reportCompilerOutput.nineToTenUspItemsAnalysisValuesZero,
      nineToTenUspItemsAnalysisValuesCategoriesZero:
        currentRsid === 'nineToTenUsp'
          ? nineToTenUspItemAnalysisValuesZero
          : reportCompilerOutput.nineToTenUspItemsAnalysisValuesCategoriesZero,

      nineToTenUspItemsAnalysisValuesOne:
        currentRsid === 'nineToTenUsp'
          ? nineToTenUspFilteredValuesOne
          : reportCompilerOutput.nineToTenUspItemsAnalysisValuesOne,
      nineToTenUspItemsAnalysisValuesCategoriesOne:
        currentRsid === 'nineToTenUsp'
          ? nineToTenUspItemAnalysisValuesOne
          : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesCategoriesOne,

      elevenToTwelveUspItemsAnalysisValuesZero:
        currentRsid === 'elevenToTwelveUsp'
          ? elevenToTwelveUspFilteredValuesZero
          : reportCompilerOutput.elevenToTwelveUspItemsAnalysisValuesZero,
      elevenToTwelveUspItemsAnalysisValuesCategoriesZero:
        currentRsid === 'elevenToTwelveUsp'
          ? elevenToTwelveUspItemAnalysisValuesZero
          : reportCompilerOutput.elevenToTwelveUspItemsAnalysisValuesCategoriesZero,

      elevenToTwelveUspItemsAnalysisValuesOne:
        currentRsid === 'elevenToTwelveUsp'
          ? elevenToTwelveUspFilteredValuesOne
          : reportCompilerOutput.elevenToTwelveUspItemsAnalysisValuesOne,
      elevenToTwelveUspItemsAnalysisValuesCategoriesOne:
        currentRsid === 'elevenToTwelveUsp'
          ? elevenToTwelveUspItemAnalysisValuesOne
          : reportCompilerOutput.elevenToTwelveUspItemsAnalysisValuesCategoriesOne,

      thirteenToFourteenUspItemsAnalysisValuesZero:
        currentRsid === 'thirteenToFourteenUsp'
          ? thirteenToFourteenUspFilteredValuesZero
          : reportCompilerOutput.thirteenToFourteenUspItemsAnalysisValuesZero,
      thirteenToFourteenUspItemsAnalysisValuesCategoriesZero:
        currentRsid === 'thirteenToFourteenUsp'
          ? thirteenToFourteenUspItemAnalysisValuesZero
          : reportCompilerOutput.thirteenToFourteenUspItemsAnalysisValuesCategoriesZero,

      thirteenToFourteenUspItemsAnalysisValuesOne:
        currentRsid === 'thirteenToFourteenUsp'
          ? thirteenToFourteenUspFilteredValuesOne
          : reportCompilerOutput.thirteenToFourteenUspItemsAnalysisValuesOne,
      thirteenToFourteenUspItemsAnalysisValuesCategoriesOne:
        currentRsid === 'thirteenToFourteenUsp'
          ? thirteenToFourteenUspItemAnalysisValuesOne
          : reportCompilerOutput.thirteenToFourteenUspItemsAnalysisValuesCategoriesOne,

      fifteenToTwentyOneUspItemsAnalysisValuesZero:
        currentRsid === 'fifteenToTwentyOneUsp'
          ? fifteenToTwentyOneUspFilteredValuesZero
          : reportCompilerOutput.fifteenToTwentyoneUspItemsAnalysisValuesZero,
      fifteenToTwentyOneUspItemsAnalysisValuesCategoriesZero:
        currentRsid === 'fifteenToTwentyOneUsp'
          ? fifteenToTwentyOneUspItemAnalysisValuesZero
          : reportCompilerOutput.fifteenToTwentyoneUspItemsAnalysisValuesCategoriesZero,

      fifteenToTwentyOneUspItemsAnalysisValuesOne:
        currentRsid === 'fifteenToTwentyOneUsp'
          ? fifteenToTwentyOneUspFilteredValuesOne
          : reportCompilerOutput.fifteenToTwentyOneUspItemsAnalysisValuesOne,
      fifteenToTwentyOneUspItemsAnalysisValuesCategoriesOne:
        currentRsid === 'fifteenToTwentyOneUsp'
          ? fifteenToTwentyOneUspItemAnalysisValuesOne
          : reportCompilerOutput.fifteenToTwentyOneUspItemsAnalysisValuesCategoriesOne,
    };
    const updatedItems = reportCompilerOutput.map((i: any) =>
      i.rsid === currentRsid
        ? {
            ...i,
            rsid: currentRsid,
            rsidReference: currentRsid.includes('Usp') ? 'usp' : currentRsid,
            title: rsTitle,
            scaledScore: scaledScore,
            ciFd: ciFd,
            prFd: prFd,
            ciMin: ciMin,
            ciMax: ciMax,
            prciFromFd: prciFromFd,
            prciToFd: prciToFd,
            testAge: testAge,
            clsSumOfScaledScore: clsSumOfScaledScore,
            rliSumOfScaledScore: rliSumOfScaledScore,
            eliSumOfScaledScore: eliSumOfScaledScore,
            lciSumOfScaledScore: lciSumOfScaledScore,
            lsiOrLmiSumOfScaledScore: lsiOrLmiSumOfScaledScore,

            fdItemsAnalysisValuesZero:
              currentRsid === 'fd'
                ? fdFilteredValuesZero
                : reportCompilerOutput.fdItemsAnalysisValuesZero,
            fdItemsAnalysisValuesCategoriesZero:
              currentRsid === 'fd'
                ? fdItemAnalysisValuesZero
                : reportCompilerOutput.fdItemsAnalysisValuesCategoriesZero,

            fdItemsAnalysisValuesOne:
              currentRsid === 'fd'
                ? fdFilteredValuesOne
                : reportCompilerOutput.fdItemsAnalysisValuesOne,
            fdItemsAnalysisValuesCategoriesOne:
              currentRsid === 'fd'
                ? fdItemAnalysisValuesOne
                : reportCompilerOutput.fdItemsAnalysisValuesCategoriesOne,

            fsItemsAnalysisValuesZero:
              currentRsid === 'fs'
                ? fsFilteredValuesZero
                : reportCompilerOutput.fsItemsAnalysisValuesZero,
            fsItemsAnalysisValuesCategoriesZero:
              currentRsid === 'fs'
                ? fsItemAnalysisValuesZero
                : reportCompilerOutput.fsItemsAnalysisValuesCategoriesZero,

            fsItemsAnalysisValuesOne:
              currentRsid === 'fs'
                ? fsFilteredValuesOne
                : reportCompilerOutput.fsItemsAnalysisValuesOne,
            fsItemsAnalysisValuesCategoriesOne:
              currentRsid === 'fs'
                ? fsItemAnalysisValuesOne
                : reportCompilerOutput.fsItemsAnalysisValuesCategoriesOne,

            fsItemsAnalysisValuesTwo:
              currentRsid === 'fs'
                ? fsFilteredValuesTwo
                : reportCompilerOutput.fsItemsAnalysisValuesTwo,
            fsItemsAnalysisValuesCategoriesTwo:
              currentRsid === 'fs'
                ? fsItemAnalysisValuesTwo
                : reportCompilerOutput.fsItemsAnalysisValuesCategoriesTwo,

            lcItemsAnalysisValuesZero:
              currentRsid === 'lc'
                ? lcFilteredValuesZero
                : reportCompilerOutput.lcItemsAnalysisValuesZero,
            lcItemsAnalysisValuesCategoriesZero:
              currentRsid === 'lc'
                ? lcItemAnalysisValuesZero
                : reportCompilerOutput.lcItemsAnalysisValuesCategoriesZero,

            lcItemsAnalysisValuesOne:
              currentRsid === 'lc'
                ? lcFilteredValuesOne
                : reportCompilerOutput.lcItemsAnalysisValuesOne,
            lcItemsAnalysisValuesCategoriesOne:
              currentRsid === 'lc'
                ? lcItemAnalysisValuesOne
                : reportCompilerOutput.lcItemsAnalysisValuesCategoriesOne,

            pacItemsAnalysisValuesZero:
              currentRsid === 'pac'
                ? pacFilteredValuesZero
                : reportCompilerOutput.pacItemsAnalysisValuesZero,
            pacItemsAnalysisValuesCategoriesZero:
              currentRsid === 'pac'
                ? pacItemAnalysisValuesZero
                : reportCompilerOutput.pacItemsAnalysisValuesCategoriesZero,

            pacItemsAnalysisValuesOne:
              currentRsid === 'pac'
                ? pacFilteredValuesOne
                : reportCompilerOutput.pacItemsAnalysisValuesOne,
            pacItemsAnalysisValuesCategoriesOne:
              currentRsid === 'pac'
                ? pacItemAnalysisValuesOne
                : reportCompilerOutput.pacItemsAnalysisValuesCategoriesOne,

            ppItemsAnalysisValuesOne:
              currentRsid === 'pp'
                ? ppFilteredValuesOne
                : reportCompilerOutput.ppItemsAnalysisValuesOne,
            ppItemsAnalysisValuesCategoriesOne:
              currentRsid === 'pp'
                ? ppItemAnalysisValuesOne
                : reportCompilerOutput.ppItemsAnalysisValuesCategoriesOne,

            ppItemsAnalysisValuesTwo:
              currentRsid === 'pp'
                ? ppFilteredValuesTwo
                : reportCompilerOutput.ppItemsAnalysisValuesTwo,
            ppItemsAnalysisValuesCategoriesTwo:
              currentRsid === 'pp'
                ? ppItemAnalysisValuesTwo
                : reportCompilerOutput.ppItemsAnalysisValuesCategoriesTwo,

            ppItemsAnalysisValuesThree:
              currentRsid === 'pp'
                ? ppFilteredValuesThree
                : reportCompilerOutput.ppItemsAnalysisValuesThree,
            ppItemsAnalysisValuesCategoriesThree:
              currentRsid === 'pp'
                ? ppItemAnalysisValuesThree
                : reportCompilerOutput.ppItemsAnalysisValuesCategoriesThree,

            ppItemsAnalysisValuesFour:
              currentRsid === 'pp'
                ? ppFilteredValuesFour
                : reportCompilerOutput.ppItemsAnalysisValuesFour,
            ppItemsAnalysisValuesCategoriesFour:
              currentRsid === 'pp'
                ? ppItemAnalysisValuesFour
                : reportCompilerOutput.ppItemsAnalysisValuesCategoriesFour,

            rsItemsAnalysisValuesZero:
              currentRsid === 'rs'
                ? rsFilteredValuesZero
                : reportCompilerOutput.rsItemsAnalysisValuesZero,
            rsItemsAnalysisValuesCategoriesZero:
              currentRsid === 'rs'
                ? rsItemAnalysisValuesZero
                : reportCompilerOutput.rsItemsAnalysisValuesCategoriesZero,

            rsItemsAnalysisValuesOne:
              currentRsid === 'rs'
                ? rsFilteredValuesOne
                : reportCompilerOutput.rsItemsAnalysisValuesOne,
            rsItemsAnalysisValuesCategoriesOne:
              currentRsid === 'rs'
                ? rsItemAnalysisValuesOne
                : reportCompilerOutput.rsItemsAnalysisValuesCategoriesOne,

            rsItemsAnalysisValuesTwo:
              currentRsid === 'rs'
                ? rsFilteredValuesTwo
                : reportCompilerOutput.rsItemsAnalysisValuesTwo,
            rsItemsAnalysisValuesCategoriesTwo:
              currentRsid === 'rs'
                ? rsItemAnalysisValuesTwo
                : reportCompilerOutput.rsItemsAnalysisValuesCategoriesTwo,

            rsItemsAnalysisValuesThree:
              currentRsid === 'rs'
                ? rsFilteredValuesThree
                : reportCompilerOutput.rsItemsAnalysisValuesThree,
            rsItemsAnalysisValuesCategoriesThree:
              currentRsid === 'rs'
                ? rsItemAnalysisValuesThree
                : reportCompilerOutput.rsItemsAnalysisValuesCategoriesThree,

            srItemsAnalysisValuesZero:
              currentRsid === 'sr'
                ? srFilteredValuesZero
                : reportCompilerOutput.srItemsAnalysisValuesZero,
            srItemsAnalysisValuesCategoriesZero:
              currentRsid === 'sr'
                ? srItemAnalysisValuesZero
                : reportCompilerOutput.srItemsAnalysisValuesCategoriesZero,

            srItemsAnalysisValuesOne:
              currentRsid === 'sr'
                ? srFilteredValuesOne
                : reportCompilerOutput.srItemsAnalysisValuesOne,
            srItemsAnalysisValuesCategoriesOne:
              currentRsid === 'sr'
                ? srItemAnalysisValuesOne
                : reportCompilerOutput.srItemsAnalysisValuesCategoriesOne,

            saItemsAnalysisValuesZero:
              currentRsid === 'sa'
                ? saFilteredValuesZero
                : reportCompilerOutput.saItemsAnalysisValuesZero,
            saItemsAnalysisValuesCategoriesZero:
              currentRsid === 'sa'
                ? saItemAnalysisValuesZero
                : reportCompilerOutput.saItemsAnalysisValuesCategoriesZero,

            saItemsAnalysisValuesOne:
              currentRsid === 'sa'
                ? saFilteredValuesOne
                : reportCompilerOutput.saItemsAnalysisValuesOne,
            saItemsAnalysisValuesCategoriesOne:
              currentRsid === 'sa'
                ? saItemAnalysisValuesOne
                : reportCompilerOutput.saItemsAnalysisValuesCategoriesOne,

            scItemsAnalysisValuesZero:
              currentRsid === 'sc'
                ? scFilteredValuesZero
                : reportCompilerOutput.scItemsAnalysisValuesZero,
            scItemsAnalysisValuesCategoriesZero:
              currentRsid === 'sc'
                ? scItemAnalysisValuesZero
                : reportCompilerOutput.scItemsAnalysisValuesCategoriesZero,

            scItemsAnalysisValuesOne:
              currentRsid === 'sc'
                ? scFilteredValuesOne
                : reportCompilerOutput.scItemsAnalysisValuesOne,
            scItemsAnalysisValuesCategoriesOne:
              currentRsid === 'sc'
                ? scItemAnalysisValuesOne
                : reportCompilerOutput.scItemsAnalysisValuesCategoriesOne,

            wcItemsAnalysisValuesZero:
              currentRsid === 'wc'
                ? wcFilteredValuesZero
                : reportCompilerOutput.wcItemsAnalysisValuesZero,
            wcItemsAnalysisValuesCategoriesZero:
              currentRsid === 'wc'
                ? wcItemAnalysisValuesZero
                : reportCompilerOutput.wcItemsAnalysisValuesCategoriesZero,

            wcItemsAnalysisValuesOne:
              currentRsid === 'wc'
                ? wcFilteredValuesOne
                : reportCompilerOutput.wcItemsAnalysisValuesOne,
            wcItemsAnalysisValuesCategoriesOne:
              currentRsid === 'wc'
                ? wcItemAnalysisValuesOne
                : reportCompilerOutput.wcItemsAnalysisValuesCategoriesOne,

            wdItemsAnalysisValuesZero:
              currentRsid === 'wd'
                ? wdFilteredValuesZero
                : reportCompilerOutput.wdItemsAnalysisValuesZero,
            wdItemsAnalysisValuesCategoriesZero:
              currentRsid === 'wd'
                ? wdItemAnalysisValuesZero
                : reportCompilerOutput.wdItemsAnalysisValuesCategoriesZero,

            wdItemsAnalysisValuesOne:
              currentRsid === 'wd'
                ? wdFilteredValuesOne
                : reportCompilerOutput.wdItemsAnalysisValuesOne,
            wdItemsAnalysisValuesCategoriesOne:
              currentRsid === 'wd'
                ? wdItemAnalysisValuesOne
                : reportCompilerOutput.wdItemsAnalysisValuesCategoriesOne,

            wsItemsAnalysisValuesZero:
              currentRsid === 'ws'
                ? wsFilteredValuesZero
                : reportCompilerOutput.wsItemsAnalysisValuesZero,
            wsItemsAnalysisValuesCategoriesZero:
              currentRsid === 'ws'
                ? wsItemAnalysisValuesZero
                : reportCompilerOutput.wsItemsAnalysisValuesCategoriesZero,

            wsItemsAnalysisValuesOne:
              currentRsid === 'ws'
                ? wsFilteredValuesOne
                : reportCompilerOutput.wsItemsAnalysisValuesOne,
            wsItemsAnalysisValuesCategoriesOne:
              currentRsid === 'ws'
                ? wsItemAnalysisValuesOne
                : reportCompilerOutput.wsItemsAnalysisValuesCategoriesOne,

            fiveToSixUspItemsAnalysisValuesZero:
              currentRsid === 'fiveToSixUsp'
                ? fiveToSixUspFilteredValuesZero
                : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesZero,
            fiveToSixUspItemsAnalysisValuesCategoriesZero:
              currentRsid === 'fiveToSixUsp'
                ? fiveToSixUspItemAnalysisValuesZero
                : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesCategoriesZero,

            fiveToSixUspItemsAnalysisValuesOne:
              currentRsid === 'fiveToSixUsp'
                ? fiveToSixUspFilteredValuesOne
                : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesOne,
            fiveToSixUspItemsAnalysisValuesCategoriesOne:
              currentRsid === 'fiveToSixUsp'
                ? fiveToSixUspItemAnalysisValuesOne
                : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesCategoriesOne,

            sevenToEightUspItemsAnalysisValuesZero:
              currentRsid === 'sevenToEightUsp'
                ? sevenToEightUspFilteredValuesZero
                : reportCompilerOutput.sevenToEightUspItemsAnalysisValuesZero,
            sevenToEightUspItemsAnalysisValuesCategoriesZero:
              currentRsid === 'sevenToEightUsp'
                ? sevenToEightUspItemAnalysisValuesZero
                : reportCompilerOutput.sevenToEightUspItemsAnalysisValuesCategoriesZero,

            sevenToEightUspItemsAnalysisValuesOne:
              currentRsid === 'sevenToEightUsp'
                ? sevenToEightUspFilteredValuesOne
                : reportCompilerOutput.sevenToEightUspItemsAnalysisValuesOne,
            sevenToEightUspItemsAnalysisValuesCategoriesOne:
              currentRsid === 'sevenToEightUsp'
                ? sevenToEightUspItemAnalysisValuesOne
                : reportCompilerOutput.sevenToEightUspItemsAnalysisValuesCategoriesOne,

            nineToTenUspItemsAnalysisValuesZero:
              currentRsid === 'nineToTenUsp'
                ? nineToTenUspFilteredValuesZero
                : reportCompilerOutput.nineToTenUspItemsAnalysisValuesZero,
            nineToTenUspItemsAnalysisValuesCategoriesZero:
              currentRsid === 'nineToTenUsp'
                ? nineToTenUspItemAnalysisValuesZero
                : reportCompilerOutput.nineToTenUspItemsAnalysisValuesCategoriesZero,

            nineToTenUspItemsAnalysisValuesOne:
              currentRsid === 'nineToTenUsp'
                ? nineToTenUspFilteredValuesOne
                : reportCompilerOutput.nineToTenUspItemsAnalysisValuesOne,
            nineToTenUspItemsAnalysisValuesCategoriesOne:
              currentRsid === 'nineToTenUsp'
                ? nineToTenUspItemAnalysisValuesOne
                : reportCompilerOutput.fiveToSixUspItemsAnalysisValuesCategoriesOne,

            elevenToTwelveUspItemsAnalysisValuesZero:
              currentRsid === 'elevenToTwelveUsp'
                ? elevenToTwelveUspFilteredValuesZero
                : reportCompilerOutput.elevenToTwelveUspItemsAnalysisValuesZero,
            elevenToTwelveUspItemsAnalysisValuesCategoriesZero:
              currentRsid === 'elevenToTwelveUsp'
                ? elevenToTwelveUspItemAnalysisValuesZero
                : reportCompilerOutput.elevenToTwelveUspItemsAnalysisValuesCategoriesZero,

            elevenToTwelveUspItemsAnalysisValuesOne:
              currentRsid === 'elevenToTwelveUsp'
                ? elevenToTwelveUspFilteredValuesOne
                : reportCompilerOutput.elevenToTwelveUspItemsAnalysisValuesOne,
            elevenToTwelveUspItemsAnalysisValuesCategoriesOne:
              currentRsid === 'elevenToTwelveUsp'
                ? elevenToTwelveUspItemAnalysisValuesOne
                : reportCompilerOutput.elevenToTwelveUspItemsAnalysisValuesCategoriesOne,

            thirteenToFourteenUspItemsAnalysisValuesZero:
              currentRsid === 'thirteenToFourteenUsp'
                ? thirteenToFourteenUspFilteredValuesZero
                : reportCompilerOutput.thirteenToFourteenUspItemsAnalysisValuesZero,
            thirteenToFourteenUspItemsAnalysisValuesCategoriesZero:
              currentRsid === 'thirteenToFourteenUsp'
                ? thirteenToFourteenUspItemAnalysisValuesZero
                : reportCompilerOutput.thirteenToFourteenUspItemsAnalysisValuesCategoriesZero,

            thirteenToFourteenUspItemsAnalysisValuesOne:
              currentRsid === 'thirteenToFourteenUsp'
                ? thirteenToFourteenUspFilteredValuesOne
                : reportCompilerOutput.thirteenToFourteenUspItemsAnalysisValuesOne,
            thirteenToFourteenUspItemsAnalysisValuesCategoriesOne:
              currentRsid === 'thirteenToFourteenUsp'
                ? thirteenToFourteenUspItemAnalysisValuesOne
                : reportCompilerOutput.thirteenToFourteenUspItemsAnalysisValuesCategoriesOne,

            fifteenToTwentyOneUspItemsAnalysisValuesZero:
              currentRsid === 'fifteenToTwentyOneUsp'
                ? fifteenToTwentyOneUspFilteredValuesZero
                : reportCompilerOutput.fifteenToTwentyoneUspItemsAnalysisValuesZero,
            fifteenToTwentyOneUspItemsAnalysisValuesCategoriesZero:
              currentRsid === 'fifteenToTwentyOneUsp'
                ? fifteenToTwentyOneUspItemAnalysisValuesZero
                : reportCompilerOutput.fifteenToTwentyoneUspItemsAnalysisValuesCategoriesZero,

            fifteenToTwentyOneUspItemsAnalysisValuesOne:
              currentRsid === 'fifteenToTwentyOneUsp'
                ? fifteenToTwentyOneUspFilteredValuesOne
                : reportCompilerOutput.fifteenToTwentyOneUspItemsAnalysisValuesOne,
            fifteenToTwentyOneUspItemsAnalysisValuesCategoriesOne:
              currentRsid === 'fifteenToTwentyOneUsp'
                ? fifteenToTwentyOneUspItemAnalysisValuesOne
                : reportCompilerOutput.fifteenToTwentyOneUspItemsAnalysisValuesCategoriesOne,
          }
        : i
    );

    console.log(
      'array check: ',
      reportCompilerOutput.includes((i: any) => String(i.rsid) === String(currentRsid))
    );
    function findSubstringInArray(arr: any, substring: string) {
      for (let i = 0; i < arr.length; i++) {
        if (typeof arr[i] === 'string' && arr[i].includes(substring)) {
          return true;
        }
      }
      return false;
    }

    function updateNewScore() {
      setReportCompilerOutput(updatedItems);
      console.log('updatedItems BBB', updatedItems);
      const valueToUpdate =
        String(newItem?.rsid) === 'fiveToSixUsp' ||
        String(newItem?.rsid) === 'sevenToEightUsp' ||
        String(newItem?.rsid) === 'nineToTenUsp' ||
        String(newItem?.rsid) === 'elevenToTwelveUsp' ||
        String(newItem?.rsid) === 'thirteenToFourteenUsp' ||
        String(newItem?.rsid) === 'fifteenToTwentyOneUsp'
          ? 'usp'
          : String(newItem?.rsid);
      const theItem = updatedItems?.find((i: any) => i.rsid.split(':')[0] === valueToUpdate);
      localStorage.setItem(currentRsid + 'ScaledScore', String(theItem?.scaledScore));
      localStorage.setItem(currentRsid + 'CiMax', String(theItem?.ciMax));
      localStorage.setItem(currentRsid + 'CiMin', String(theItem?.ciMin));

      const theUpdateItem = [
        // `${String(theItem?.rsid)}`,
        `${String(valueToUpdate.split(':')[0]).toUpperCase()}:${updatedItems[0]?.scaledScore}`,
        Number(theItem?.ciMin) < 0 ? 0 : Number(theItem?.ciMin),
        Number(theItem?.scaledScore),
        Number(theItem?.scaledScore),
        Number(theItem?.ciMax) > 19 ? 19 : Number(theItem?.ciMax),
      ];

      // chartTwoCandleStickData.push(theUpdateItem);

      if (
        currentRsid === 'fd' ||
        currentRsid === 'sc' ||
        currentRsid === 'wc' ||
        currentRsid === 'sr' ||
        currentRsid === 'lc' ||
        currentRsid === 'fiveToSixUsp' ||
        currentRsid === 'sevenToEightUsp' ||
        currentRsid === 'nineToTenUsp' ||
        currentRsid === 'elevenToTwelveUsp' ||
        currentRsid === 'thirteenToFourteenUsp' ||
        currentRsid === 'fifteenToTwentyOneUsp'
      ) {
        // console.log('YOYO', chartTwo);
        // console.log('YOYO updatedItems', updatedItems);
        // console.log('YOYO theUPDATEItem', theUpdateItem);
        // console.log(
        //   'YOYO chartTwo.filter',
        //   chartTwo.filter((z: any) => String(z[0]) !== String(currentRsid))
        // );

        const valueToUpdateReverse =
          String(currentRsid) === 'fiveToSixUsp' ||
          String(currentRsid) === 'sevenToEightUsp' ||
          String(currentRsid) === 'nineToTenUsp' ||
          String(currentRsid) === 'elevenToTwelveUsp' ||
          String(currentRsid) === 'thirteenToFourteenUsp' ||
          String(currentRsid) === 'fifteenToTwentyOneUsp'
            ? 'usp'
            : String(currentRsid);
        console.log('valueToUpdateReverse', valueToUpdateReverse);
        setChartTwo([
          ...chartTwo.filter(
            (z: any) => String(z[0].split(':')[0]) !== String(valueToUpdateReverse.toUpperCase())
          ),
          theUpdateItem,
        ]);
      }

      if (
        currentRsid === 'rs' ||
        currentRsid === 'fs' ||
        currentRsid === 'ws' ||
        currentRsid === 'wd' ||
        currentRsid === 'sa'
      ) {
        setChartThree([
          ...chartThree.filter(
            (z: any) => String(z[0].split(':')[0]) !== String(currentRsid.toUpperCase())
          ),
          theUpdateItem,
        ]);
      }
      // currentRsid === 'rs' ||
      //   currentRsid === 'fs' ||
      //   currentRsid === 'ws' ||
      //   (currentRsid === 'sa'

      //   && setChartThree([...chartThree, theUpdateItem]));

      // console.log('CANDLE 2 UPDATE', chartTwoCandleStickData);
    }
    function addNewScore() {
      setReportCompilerOutput([...reportCompilerOutput, newItem]);
      localStorage.setItem(currentRsid + 'ScaledScore', String(newItem?.scaledScore));
      localStorage.setItem(currentRsid + 'CiMax', String(newItem?.ciMax));
      localStorage.setItem(currentRsid + 'CiMin', String(newItem?.ciMin));

      const valueToAdd =
        String(newItem?.rsid) === 'fiveToSixUsp' ||
        String(newItem?.rsid) === 'sevenToEightUsp' ||
        String(newItem?.rsid) === 'nineToTenUsp' ||
        String(newItem?.rsid) === 'elevenToTwelveUsp' ||
        String(newItem?.rsid) === 'thirteenToFourteenUsp' ||
        String(newItem?.rsid) === 'fifteenToTwentyOneUsp'
          ? 'usp'
          : String(newItem?.rsid);

      const theNewItem = [
        // `${currentRsid}`,
        `${valueToAdd.toUpperCase()}:${newItem?.scaledScore}`,
        Number(newItem?.ciMin) < 0 ? 0 : Number(newItem?.ciMin),
        Number(newItem?.scaledScore),
        Number(newItem?.scaledScore),
        Number(newItem?.ciMax) > 19 ? 19 : Number(newItem?.ciMax),
      ];
      // chartTwoCandleStickData.push(theNewItem);
      // Understanding : FD, SC, WC, SR, LC, USP
      // Expressive: RS, FS, WS, SA
      // currentRsid === 'fd' ||
      //   currentRsid === 'sc' ||
      //   currentRsid === 'wc' ||
      //   currentRsid === 'sr' ||
      //   currentRsid === 'lc' ||
      //   currentRsid === 'fiveToSixUsp' ||
      //   currentRsid === 'sevenToEightUsp' ||
      //   currentRsid === 'nineToTenUsp' ||
      //   currentRsid === 'elevenToTwelveUsp' ||
      //   currentRsid === 'thirteenToFourteenUsp' ||
      //   (currentRsid === 'fifteenToTwentyOneUsp' && setChartTwo([...chartTwo, theNewItem]));

      if (
        currentRsid === 'fd' ||
        currentRsid === 'sc' ||
        currentRsid === 'wc' ||
        currentRsid === 'sr' ||
        currentRsid === 'lc' ||
        currentRsid === 'fiveToSixUsp' ||
        currentRsid === 'sevenToEightUsp' ||
        currentRsid === 'nineToTenUsp' ||
        currentRsid === 'elevenToTwelveUsp' ||
        currentRsid === 'thirteenToFourteenUsp' ||
        currentRsid === 'fifteenToTwentyOneUsp'
      ) {
        setChartTwo([...chartTwo, theNewItem]);
      }

      if (
        currentRsid === 'rs' ||
        currentRsid === 'fs' ||
        currentRsid === 'ws' ||
        currentRsid === 'wd' ||
        currentRsid === 'sa'
      ) {
        setChartThree([...chartThree, theNewItem]);
      }

      // currentRsid === 'rs' ||
      //   currentRsid === 'fs' ||
      //   currentRsid === 'ws' ||
      //   (currentRsid === 'sa' && setChartThree([...chartThree, theNewItem]));

      // console.log('CANDLE 2 ADD', chartTwoCandleStickData);
    }
    // function removeAllUsps() {
    //   setReportCompilerOutput([
    //     ...reportCompilerOutput.filter((i: any) => i.rsidReference !== 'usp'),
    //   ]);
    // }

    function removeAllUsps() {
      console.log('HERE removeAllUsps');
      setReportCompilerOutput([
        ...reportCompilerOutput.filter((i: any) => i.rsidReference !== 'usp'),
        newItem,
      ]);
      localStorage.setItem(currentRsid + 'ScaledScore', String(newItem?.scaledScore));
      localStorage.setItem(currentRsid + 'CiMax', String(newItem?.ciMax));
      localStorage.setItem(currentRsid + 'CiMin', String(newItem?.ciMin));

      const valueToAdd =
        String(newItem?.rsid) === 'fiveToSixUsp' ||
        String(newItem?.rsid) === 'sevenToEightUsp' ||
        String(newItem?.rsid) === 'nineToTenUsp' ||
        String(newItem?.rsid) === 'elevenToTwelveUsp' ||
        String(newItem?.rsid) === 'thirteenToFourteenUsp' ||
        String(newItem?.rsid) === 'fifteenToTwentyOneUsp'
          ? 'usp'
          : String(newItem?.rsid);

      const theNewItem = [
        // `${currentRsid}`,
        // `${String(currentRsid)} : ${newItem?.scaledScore}`,
        `${valueToAdd.toUpperCase()}:${newItem?.scaledScore}`,
        Number(newItem?.ciMin),
        Number(newItem?.scaledScore),
        Number(newItem?.scaledScore),
        Number(newItem?.ciMax),
      ];
      const valueToUpdateReverse =
        String(currentRsid) === 'fiveToSixUsp' ||
        String(currentRsid) === 'sevenToEightUsp' ||
        String(currentRsid) === 'nineToTenUsp' ||
        String(currentRsid) === 'elevenToTwelveUsp' ||
        String(currentRsid) === 'thirteenToFourteenUsp' ||
        String(currentRsid) === 'fifteenToTwentyOneUsp'
          ? 'usp'
          : String(currentRsid);
      if (
        currentRsid === 'fd' ||
        currentRsid === 'sc' ||
        currentRsid === 'wc' ||
        currentRsid === 'sr' ||
        currentRsid === 'lc' ||
        currentRsid === 'fiveToSixUsp' ||
        currentRsid === 'sevenToEightUsp' ||
        currentRsid === 'nineToTenUsp' ||
        currentRsid === 'elevenToTwelveUsp' ||
        currentRsid === 'thirteenToFourteenUsp' ||
        currentRsid === 'fifteenToTwentyOneUsp'
      ) {
        // setChartTwo([...chartTwo, theNewItem]);
        setChartTwo([
          ...chartTwo.filter(
            (z: any) => String(z[0].split(':')[0]) !== String(valueToUpdateReverse.toUpperCase())
          ),
          theNewItem,
        ]);
      }

      if (
        currentRsid === 'rs' ||
        currentRsid === 'fs' ||
        currentRsid === 'ws' ||
        currentRsid === 'wd' ||
        currentRsid === 'sa'
      ) {
        // setChartThree([...chartThree, theNewItem]);
        setChartThree([
          ...chartTwo.filter(
            (z: any) => String(z[0].split(':')[0]) !== String(valueToUpdateReverse.toUpperCase())
          ),
          theNewItem,
        ]);
      }
    }

    // reportCompilerOutput.find((i: any) => i.rsid === currentRsid)
    //   ? reportCompilerOutput.find((i: any) => i.rsidReference === 'usp')
    //     ? removeOldUspAndAddNewScore()
    //     : updateNewScore()
    //   : addNewScore();

    // reportCompilerOutput.find((i: any) => i.rsidReference === 'usp')
    //   ? removeAllUsps()
    //   : reportCompilerOutput.find((i: any) => i.rsid === currentRsid)
    //   ? updateNewScore()
    //   : addNewScore();

    if (
      reportCompilerOutput.find(
        (i: any) => i.rsidReference === 'usp' && newItem.rsidReference === 'usp'
      )
    ) {
      removeAllUsps();
      // addNewScore();
    } else {
      if (reportCompilerOutput.find((i: any) => i.rsid === currentRsid)) {
        updateNewScore();
      } else {
        addNewScore();
      }
    }

    // const data = [
    //   ['day', 'a', 'b', 'c', 'd'],
    //   ['Mon', 20, 28, 38, 45],
    //   ['Tue', 31, 38, 55, 66],
    //   ['Wed', 50, 55, 77, 80],
    //   ['Thu', 50, 77, 66, 77],
    //   ['Fri', 15, 66, 22, 68],
    // ];

    // const options = {
    //   legend: 'none',
    // };

    // setChartOne(data);
    // setOptionsChartOne(options);

    reportCompilerOutput.find((i: any) => i.rsid === currentRsid) &&
      toast({
        title: 'Report udpated.',
        description: 'You updated a score that was previously calculated',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    setTypedScore('');
    console.log('REQBOD', requestBody);

    // setActiveStep(activeStep + 1);
  };
  // const CalculateTss = () => {
  //   useTimeout(() => triggerCalculateTss, 5000);
  //   // console.log('Triggered');
  // };
  // const CalculateTss = () => useTimeout(() => triggerCalculateTss(activeStep), 5000);

  // const { clear, reset } = useTimeout(() => setCount(0), 1000)

  // fdFinaleScore: null,
  // fsFinaleScore: null,
  // lcFinaleScore: null,
  // pacFinaleScore: null,

  // ppFinaleScore: null,
  // rsFinaleScore: null,
  // srFinaleScore: null,
  // saFinaleScore: null,

  // scFinaleScore: null,
  // wcFinaleScore: null,
  // wdFinaleScore: null,
  // wsFinaleScore: null,

  // uspFinaleScore: null,

  const wcScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'wc')?.scaledScore;
  const fdScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'fd')?.scaledScore;
  const fsScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'fs')?.scaledScore;
  const rsScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'rs')?.scaledScore;
  const uspScaledScore = reportCompilerOutput?.find(
    (z: any) =>
      z.rsid === 'fiveToSixUsp' ||
      z.rsid === 'sevenToEightUsp' ||
      z.rsid === 'nineToTenUsp' ||
      z.rsid === 'elevenToTwelveUsp' ||
      z.rsid === 'thirteenToFourteenUsp' ||
      z.rsid === 'fifteenToTwentyOneUsp'
  )?.scaledScore;
  const wdScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'wd')?.scaledScore;
  const saScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'sa')?.scaledScore;
  const srScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'sr')?.scaledScore;

  const scScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'sc')?.scaledScore;
  const lcScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'lc')?.scaledScore;
  const wsScaledScore = reportCompilerOutput?.find((z: any) => z.rsid === 'ws')?.scaledScore;

  // Number(reportCompiler.ageYears) >= 9  && <=12

  const clsSumOfScaledScore =
    Number(reportCompiler.ageYears) <= 8
      ? [
          Number(scScaledScore),
          Number(wsScaledScore),
          Number(fsScaledScore),
          Number(rsScaledScore),
        ].reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0)
      : Number(reportCompiler.ageYears) >= 9 && Number(reportCompiler.ageYears) <= 12
      ? [
          Number(wcScaledScore),
          Number(fsScaledScore),
          Number(rsScaledScore),
          Number(srScaledScore),
        ].reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0)
      : [
          Number(fsScaledScore),
          Number(rsScaledScore),
          Number(uspScaledScore),
          Number(srScaledScore),
        ].reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0);

  const rliSumOfScaledScore =
    Number(reportCompiler.ageYears) <= 8
      ? [Number(scScaledScore), Number(wcScaledScore), Number(fdScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        )
      : Number(reportCompiler.ageYears) >= 9 && Number(reportCompiler.ageYears) <= 12
      ? [Number(wcScaledScore), Number(fdScaledScore), Number(srScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        )
      : [Number(wcScaledScore), Number(uspScaledScore), Number(srScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        );

  const eliSumOfScaledScore =
    Number(reportCompiler.ageYears) <= 8
      ? [Number(wsScaledScore), Number(fsScaledScore), Number(rsScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        )
      : Number(reportCompiler.ageYears) >= 9 && Number(reportCompiler.ageYears) <= 12
      ? [Number(fsScaledScore), Number(rsScaledScore), Number(saScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        )
      : [Number(fsScaledScore), Number(rsScaledScore), Number(saScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        );

  const lciSumOfScaledScore =
    Number(reportCompiler.ageYears) <= 8
      ? [Number(lcScaledScore), Number(wcScaledScore), Number(fdScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        )
      : Number(reportCompiler.ageYears) >= 9 && Number(reportCompiler.ageYears) <= 12
      ? [Number(wcScaledScore), Number(uspScaledScore), Number(wdScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        )
      : [Number(wcScaledScore), Number(uspScaledScore), Number(saScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        );

  const lsiOrLmiSumOfScaledScore =
    Number(reportCompiler.ageYears) <= 8
      ? [
          Number(scScaledScore),
          Number(wsScaledScore),
          Number(fsScaledScore),
          Number(rsScaledScore),
        ].reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0)
      : Number(reportCompiler.ageYears) >= 9 && Number(reportCompiler.ageYears) <= 12
      ? [Number(fdScaledScore), Number(fsScaledScore), Number(rsScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        )
      : [Number(fdScaledScore), Number(fsScaledScore), Number(rsScaledScore)].reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + currentValue;
          },
          0
        );
  function pacFinalScore(rawScoreTotal: any) {
    setReportCompiler({
      ...reportCompiler,
      pacFinaleScore: rawScoreTotal,
      tot: rawScoreTotal,
    });
    setSavedPac(rawScoreTotal);
    setTot(rawScoreTotal);
  }
  const saveFinalCalculatedRawScore = (rawScoreTotal: any) => {
    console.log('ITS HERE UPDATED', rawScoreTotal);

    // setCurrentRsid(rsid);
    currentRsid === 'fd' &&
      setReportCompiler({
        ...reportCompiler,
        fdFinaleScore: rawScoreTotal,
      });

    currentRsid === 'fs' &&
      setReportCompiler({
        ...reportCompiler,
        fsFinaleScore: rawScoreTotal,
      });

    currentRsid === 'lc' &&
      setReportCompiler({
        ...reportCompiler,
        lcFinaleScore: rawScoreTotal,
      });

    currentRsid === 'pac' && pacFinalScore(rawScoreTotal);

    currentRsid === 'pp' &&
      setReportCompiler({
        ...reportCompiler,
        ppFinaleScore: rawScoreTotal,
      });

    currentRsid === 'rs' &&
      setReportCompiler({
        ...reportCompiler,
        rsFinaleScore: rawScoreTotal,
      });

    currentRsid === 'sr' &&
      setReportCompiler({
        ...reportCompiler,
        srFinaleScore: rawScoreTotal,
      });

    currentRsid === 'sa' &&
      setReportCompiler({
        ...reportCompiler,
        saFinaleScore: rawScoreTotal,
      });

    currentRsid === 'sc' &&
      setReportCompiler({
        ...reportCompiler,
        scFinaleScore: rawScoreTotal,
      });

    currentRsid === 'wc' &&
      setReportCompiler({
        ...reportCompiler,
        wcFinaleScore: rawScoreTotal,
      });

    currentRsid === 'wd' &&
      setReportCompiler({
        ...reportCompiler,
        wdFinaleScore: rawScoreTotal,
      });

    currentRsid === 'ws' &&
      setReportCompiler({
        ...reportCompiler,
        wsFinaleScore: rawScoreTotal,
      });

    (currentRsid === 'fiveToSixUsp' ||
      currentRsid === 'sevenToEightUsp' ||
      currentRsid === 'nineToTenUsp' ||
      currentRsid === 'elevenToTwelveUsp' ||
      currentRsid === 'thirteenToFourteenUsp' ||
      currentRsid === 'fifteenToTwentyOneUsp') &&
      setReportCompiler({
        ...reportCompiler,
        uspFinaleScore: rawScoreTotal,
      });
    setModalContent('none');
    // CalculateReport(currentRsid);

    console.log('modalContent after save', modalContent);
    //  fd: '',
    //  fs: '',
    //  lc: '',
    //  pac: '',
    //  pp: '',
    //  rs: '',
    //  sr: '',
    //  sa: '',
    //  sc: '',
    //  wc: '',
    //  wd: '',
    //  ws: '',
    //  usp: '',
  };

  const saveFinalEnteredRawScore = (rawScoreTotal: any) => {
    console.log('ITS HERE', rawScoreTotal);
    // setCurrentRsid(rsid);
    currentRsid === 'fd' &&
      setReportCompiler({
        ...reportCompiler,
        fdFinaleScore: rawScoreTotal,
      });

    currentRsid === 'fs' &&
      setReportCompiler({
        ...reportCompiler,
        fsFinaleScore: rawScoreTotal,
      });

    currentRsid === 'lc' &&
      setReportCompiler({
        ...reportCompiler,
        lcFinaleScore: rawScoreTotal,
      });

    currentRsid === 'pac' && pacFinalScore(rawScoreTotal);

    currentRsid === 'pp' &&
      setReportCompiler({
        ...reportCompiler,
        ppFinaleScore: rawScoreTotal,
      });

    currentRsid === 'rs' &&
      setReportCompiler({
        ...reportCompiler,
        rsFinaleScore: rawScoreTotal,
      });

    currentRsid === 'sr' &&
      setReportCompiler({
        ...reportCompiler,
        srFinaleScore: rawScoreTotal,
      });

    currentRsid === 'sa' &&
      setReportCompiler({
        ...reportCompiler,
        saFinaleScore: rawScoreTotal,
      });

    currentRsid === 'sc' &&
      setReportCompiler({
        ...reportCompiler,
        scFinaleScore: rawScoreTotal,
      });

    currentRsid === 'wc' &&
      setReportCompiler({
        ...reportCompiler,
        wcFinaleScore: rawScoreTotal,
      });

    currentRsid === 'wd' &&
      setReportCompiler({
        ...reportCompiler,
        wdFinaleScore: rawScoreTotal,
      });

    currentRsid === 'ws' &&
      setReportCompiler({
        ...reportCompiler,
        wsFinaleScore: rawScoreTotal,
      });

    (currentRsid === 'fiveToSixUsp' ||
      currentRsid === 'sevenToEightUsp' ||
      currentRsid === 'nineToTenUsp' ||
      currentRsid === 'elevenToTwelveUsp' ||
      currentRsid === 'thirteenToFourteenUsp' ||
      currentRsid === 'fifteenToTwentyOneUsp') &&
      setReportCompiler({
        ...reportCompiler,
        uspFinaleScore: rawScoreTotal,
      });
    setModalContent('none');
    // CalculateReport(currentRsid);

    //  fd: '',
    //  fs: '',
    //  lc: '',
    //  pac: '',
    //  pp: '',
    //  rs: '',
    //  sr: '',
    //  sa: '',
    //  sc: '',
    //  wc: '',
    //  wd: '',
    //  ws: '',
    //  usp: '',
  };

  const handleModalOpen = (rstitle: any, rsContent: any, rsid: any) => {
    setRsTitle(rstitle);
    // rsid === 'fiveToSixUsp' ||
    // rsid === 'sevenToEightUsp' ||
    // rsid === 'nineToTenUsp' ||
    // rsid === 'elevenToTwelveUsp' ||
    // rsid === 'thirteenToFourteenUsp' ||
    // // rsid === 'fifteenToTwentyOneUsp'
    //   ? setUspModalContent(rsContent)
    //   : setModalContent(rsContent);
    // setReportCompilerOutput([defaultReportCompilerOutput]);
    setModalContent(rsContent);
    onOpen();
    console.log('rsid: ', rsid);

    setReportCompiler({
      ...reportCompiler,
      selectedRsid: rsid,
    });

    setCurrentRsid(rsid);
    rsid === 'fd' &&
      setReportCompiler({
        ...reportCompiler,
        fd: rsContent,
      });

    rsid === 'fs' &&
      setReportCompiler({
        ...reportCompiler,
        fs: rsContent,
      });

    rsid === 'lc' &&
      setReportCompiler({
        ...reportCompiler,
        lc: rsContent,
      });

    rsid === 'pac' &&
      setReportCompiler({
        ...reportCompiler,
        pac: rsContent,
      });

    rsid === 'pp' &&
      setReportCompiler({
        ...reportCompiler,
        pp: rsContent,
      });

    rsid === 'rs' &&
      setReportCompiler({
        ...reportCompiler,
        rs: rsContent,
      });

    rsid === 'sr' &&
      setReportCompiler({
        ...reportCompiler,
        sr: rsContent,
      });

    rsid === 'sa' &&
      setReportCompiler({
        ...reportCompiler,
        sa: rsContent,
      });

    rsid === 'sc' &&
      setReportCompiler({
        ...reportCompiler,
        sc: rsContent,
      });

    rsid === 'wc' &&
      setReportCompiler({
        ...reportCompiler,
        wc: rsContent,
      });

    rsid === 'wd' &&
      setReportCompiler({
        ...reportCompiler,
        wd: rsContent,
      });

    rsid === 'ws' &&
      setReportCompiler({
        ...reportCompiler,
        ws: rsContent,
      });

    (rsid === 'fiveToSixUsp' ||
      rsid === 'sevenToEightUsp' ||
      rsid === 'nineToTenUsp' ||
      rsid === 'elevenToTwelveUsp' ||
      rsid === 'thirteenToFourteenUsp' ||
      rsid === 'fifteenToTwentyOneUsp') &&
      setReportCompiler({
        ...reportCompiler,
        usp: rsContent,
      });

    // (currentUsp === 'fiveToSixUsp' ||
    //   currentUsp === 'sevenToEightUsp' ||
    //   currentUsp === 'nineToTenUsp' ||
    //   currentUsp === 'elevenToTwelveUsp' ||
    //   currentUsp === 'thirteenToFourteenUsp' ||
    //   currentUsp === 'fifteenToTwentyOneUsp') &&
    //   setReportCompiler({
    //     ...reportCompiler,
    //     usp: rsContent,
    //   });

    //  fd: '',
    //  fs: '',
    //  lc: '',
    //  pac: '',
    //  pp: '',
    //  rs: '',
    //  sr: '',
    //  sa: '',
    //  sc: '',
    //  wc: '',
    //  wd: '',
    //  ws: '',
    //  usp: '',
  };

  // null: N/A
  // 0: 0
  // 1: 1

  // Start by creating the report state.
  const [report, setReport] = useState<any>([]);
  const [chartEventsStateChartOne, setChartEventsStateChartOne] = useState<any>('');
  const [chartEventsStateChartTwo, setChartEventsStateChartTwo] = useState<any>('');
  const [chartEventsStateChartThree, setChartEventsStateChartThree] = useState<any>('');

  function updateUserType(userType: string) {}
  // REACT_APP_GOOGLE_DRIVE_API_KEY_UL
  useEffect(() => {
    const allClientProfiles = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
    const allClientProfileTables = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES_TABLE;
    // const url = 'https://sheets.googleapis.com/v4/spreadsheets/' + spreadsheet_id + '/values/' + tab_name + '?alt=json&key=' + api_key;
    const spreadsheet_id = '1kX-poFOSJKmUrHIOZvZ2ruIb69xVKuGKDy5l2inq8-Q';
    // const designppl_app_a_spreadsheet_id = '1m9zm_jiV9cObvsbymxDfmgH1wVKOrWYmTYmHBCG6lUc';
    // const tab_name = '5:0-5:5';
    // const api_key = 'AIzaSyAYoWt5owV4mad5jvyXuPsKMMAi-qUCxTM';
    // const url =
    //   'https://sheets.googleapis.com/v4/spreadsheets/' +
    //   designppl_app_a_spreadsheet_id +
    //   '/values/' +
    //   tab_name +
    //   '?alt=json&key=' +
    //   api_key;
    // console.log(url);
    // console.log(appendixA);
    const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
    // rows &&
    //   getAllProfiles(EP, bearerToken!).then((values) => {
    //     console.log(values);
    //     setRows(values);
    //   });
    setCurrentRsid(reportCompiler.selectedUsp);
    // setChartOne(dataToCandlestick);

    localStorage.removeItem('rliEli');
    localStorage.removeItem('lciLmi');
    localStorage.removeItem('lciLsi');
    localStorage.removeItem('sotssCLSCIMAX');
    localStorage.removeItem('sotssCLSCIMIN');
    localStorage.removeItem('sotssRLICIMAX');
    localStorage.removeItem('sotssRLICIMIN');
    localStorage.removeItem('sotssELICIMAX');
    localStorage.removeItem('sotssELICIMIN');
    localStorage.removeItem('sotssLCICIMAX');
    localStorage.removeItem('sotssLCICIMIN');
    localStorage.removeItem('sotssLMICIMAX');
    localStorage.removeItem('sotssLMICIMIN');
    localStorage.removeItem('sotssLSICIMAX');
    localStorage.removeItem('sotssLSICIMIN');
    localStorage.removeItem('sotssCLS');
    localStorage.removeItem('sotssRLI');
    localStorage.removeItem('sotssELI');
    localStorage.removeItem('sotssLCI');
    localStorage.removeItem('sotssLMI');
    localStorage.removeItem('sotssLSI');
    localStorage.removeItem('CLISPImage');
    localStorage.removeItem('CLISPImageOne');
    localStorage.removeItem('CLISPImageTwo');
    localStorage.removeItem('CLISPImageThree');

    // Receptive Language (Understanding) scores : FD, SC, WC, SR, LC, USP
    localStorage.removeItem('fdScaledScore');
    localStorage.removeItem('fdCiMax');
    localStorage.removeItem('fdCiMin');

    localStorage.removeItem('scScaledScore');
    localStorage.removeItem('scCiMax');
    localStorage.removeItem('scCiMin');

    localStorage.removeItem('wcScaledScore');
    localStorage.removeItem('wcCiMax');
    localStorage.removeItem('wcCiMin');

    localStorage.removeItem('srScaledScore');
    localStorage.removeItem('srCiMax');
    localStorage.removeItem('srCiMin');

    localStorage.removeItem('lcScaledScore');
    localStorage.removeItem('lcCiMax');
    localStorage.removeItem('lcCiMin');

    localStorage.removeItem('fiveToSixUspScaledScore');
    localStorage.removeItem('fiveToSixUspCiMax');
    localStorage.removeItem('fiveToSixUspCiMin');

    localStorage.removeItem('sevenToEightUspScaledScore');
    localStorage.removeItem('sevenToEightUspCiMax');
    localStorage.removeItem('sevenToEightUspCiMin');

    localStorage.removeItem('nineToTenUspScaledScore');
    localStorage.removeItem('nineToTenUspCiMax');
    localStorage.removeItem('nineToTenUspCiMin');

    localStorage.removeItem('elevenToTwelveUspScaledScore');
    localStorage.removeItem('elevenToTwelveUspCiMax');
    localStorage.removeItem('elevenToTwelveUspCiMin');

    localStorage.removeItem('thirteenToFourteenUspScaledScore');
    localStorage.removeItem('thirteenToFourteenUspCiMax');
    localStorage.removeItem('thirteenToFourteenUspCiMin');

    localStorage.removeItem('fifteenToTwentyOneUspScaledScore');
    localStorage.removeItem('fifteenToTwentyOneUspCiMax');
    localStorage.removeItem('fifteenToTwentyOneUspCiMin');

    // localStorage.removeItem('uspScaledScore');
    // localStorage.removeItem('uspCiMax');
    // localStorage.removeItem('uspCiMin');

    // {rsidRow === 'fiveToSixUsp' ||
    // rsidRow === 'sevenToEightUsp' ||
    // rsidRow === 'nineToTenUsp' ||
    // rsidRow === 'elevenToTwelveUsp' ||
    // rsidRow === 'thirteenToFourteenUsp' ||
    // rsidRow === 'fifteenToTwentyOneUsp' ? (

    // Expressive Language (Talking) scores: RS, FS, WS, SA
    localStorage.removeItem('rsScaledScore');
    localStorage.removeItem('rsCiMax');
    localStorage.removeItem('rsCiMin');

    localStorage.removeItem('fsScaledScore');
    localStorage.removeItem('fsCiMax');
    localStorage.removeItem('fsCiMin');

    localStorage.removeItem('wsScaledScore');
    localStorage.removeItem('wsCiMax');
    localStorage.removeItem('wsCiMin');

    localStorage.removeItem('saScaledScore');
    localStorage.removeItem('saCiMax');
    localStorage.removeItem('saCiMin');

    localStorage.removeItem('ppCiMax');
    localStorage.removeItem('ppScaledScore');
    localStorage.removeItem('ppCiMin');

    localStorage.removeItem('wdScaledScore');
    localStorage.removeItem('wdCiMax');
    localStorage.removeItem('wdCiMin');

    localStorage.removeItem('pacScaledScore');
    localStorage.removeItem('pacCiMax');
    localStorage.removeItem('pacCiMin');
  }, []);
  const [snapshotChart, setSnapshotChart] = useState(0);
  const [imageUriChartOne, setImageUriChartOne] = useState('');
  const [imageUriChartTwo, setImageUriChartTwo] = useState('');
  const [imageUriChartThree, setImageUriChartThree] = useState('');

  useEffect(() => {
    setChartEventsStateChartOne([
      {
        eventName: 'ready' as GoogleVizEventName,
        callback: ({ chartWrapper }: { chartWrapper: any }) => {
          const chart = chartWrapper.getChart();
          const uri = chart.getImageURI();
          setImageUriChartOne(uri);
        },
      },
    ]);
  }, [chartOne]);

  useEffect(() => {
    setChartEventsStateChartTwo([
      {
        eventName: 'ready' as GoogleVizEventName,
        callback: ({ chartWrapper }: { chartWrapper: any }) => {
          const chart = chartWrapper.getChart();
          const uri = chart.getImageURI();
          setImageUriChartTwo(uri);
        },
      },
    ]);
  }, [chartTwo]);

  useEffect(() => {
    setChartEventsStateChartThree([
      {
        eventName: 'ready' as GoogleVizEventName,
        callback: ({ chartWrapper }: { chartWrapper: any }) => {
          const chart = chartWrapper.getChart();
          const uri = chart.getImageURI();
          setImageUriChartThree(uri);
        },
      },
    ]);
  }, [chartThree]);

  // useEffect(() => {
  //   setChartEventsState([
  //     {
  //       eventName: 'ready' as GoogleVizEventName,
  //       callback: ({ chartWrapper }: { chartWrapper: any }) => {
  //         const chart = chartWrapper.getChart();
  //         const uri = chart.getImageURI();
  //         setImageURI(uri);
  //       },
  //     },
  //   ]);
  // }, [snapshotChart]);

  // const chartEvents = [
  //   {
  //     eventName: 'ready' as GoogleVizEventName,
  //     callback: ({ chartWrapper }: { chartWrapper: any }) => {
  //       const chart = chartWrapper.getChart();
  //       const uri = chart.getImageURI();
  //       setImageURI(uri);
  //     },
  //   },
  // ];

  // const chartEvents = [
  //   {
  //     eventName: 'ready' as GoogleVizEventName,
  //     callback: ({ chartWrapper }: { chartWrapper: any }) => {
  //       const chart = chartWrapper.getChart();
  //       const uri = chart.getImageURI();
  //       console.log('chartEvents', uri);
  //       setImageURI(uri);
  //     },
  //   },
  // ];

  // console.log('chartEvents: ', chartEvents);
  interface InvoiceFields {
    'Invoice Date': string;
    'Invoice Due Date': string;
    'Invoice Status': string;
    'Invoice Posted to Xero?': boolean;
    'Invoice No': string;
    Reference: string;
  }

  interface Invoices {
    createdAt: string;
    createdBy: string;
    id: string;
    modifiedAt: string;
    modifiedBy: string;
    fields: InvoiceFields;
  }
  // console.log(rows);

  const TheRow = (props: {
    title: string;
    rsidRow: string;
    ciLevel?: number;
    scaledScore?: number;
    rawScore?: number;
    scaledScorePoints?: number;
    ciMin?: number;
    ciMax?: number;
    pr?: number;
    prciFrom?: number;
    prciTo?: number;
    testAgeEquivalent?: number;
  }) => {
    const {
      title,
      rsidRow,
      ciLevel,
      scaledScore,
      rawScore,
      scaledScorePoints,
      ciMin,
      ciMax,
      pr,
      prciFrom,
      prciTo,
      testAgeEquivalent,
    } = props;
    return (
      <Tr bg={'gray.100'}>
        <Td p={'2'} fontWeight={'bold'}>
          {title}
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={rawScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={scaledScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={scaledScorePoints} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={ciMin} />
        </Td>
        <Td p={'2'}>to</Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={ciMax} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={pr} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={prciFrom} />
        </Td>
        <Td p={'2'}>to</Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={prciTo} />
        </Td>
        {rsidRow === 'fiveToSixUsp' ||
        rsidRow === 'sevenToEightUsp' ||
        rsidRow === 'nineToTenUsp' ||
        rsidRow === 'elevenToTwelveUsp' ||
        rsidRow === 'thirteenToFourteenUsp' ||
        rsidRow === 'fifteenToTwentyOneUsp' ? (
          <Td p={'2'}></Td>
        ) : (
          <Td p={'2'}>
            <Input bg={'white'} color={'black'} value={testAgeEquivalent} />
          </Td>
        )}
      </Tr>
    );
  };

  const TheRowCLSAIS = (props: {
    title: string;
    scFinaleScore: number;
    lcFinaleScore: number;
    wsFinaleScore: number;
    wcFinaleScore: number;
    fdFinaleScore: number;
    fsFinaleScore: number;
    rsFinaleScore: number;
  }) => {
    const {
      title,
      scFinaleScore,
      lcFinaleScore,
      wsFinaleScore,
      wcFinaleScore,
      fdFinaleScore,
      fsFinaleScore,
      rsFinaleScore,
    } = props;

    // SC	LC	WS	WC	FD	FS	RS
    return (
      <Tr bg={'gray.100'}>
        <Td p={'2'}>{title}</Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={scFinaleScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={''} isDisabled />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={wsFinaleScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={wcFinaleScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={fdFinaleScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={fsFinaleScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={rsFinaleScore} />
        </Td>
        <Td p={'2'} textAlign={'center'}>
          <Divider orientation="vertical" />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={0} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={0} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={0} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={0} />
        </Td>
        <Td p={'2'}>to</Td>

        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={0} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={0} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={0} />
        </Td>
        <Td p={'2'}>to</Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} />
        </Td>
      </Tr>
    );
  };
  const todayDate = new Date();
  // console.log(modalContent);

  const calculateAgeAtTest = () => {
    setAgeCalculated(0);
    const diff = moment(reportCompiler.dateOfTest).diff(
      moment(reportCompiler.dateOfBirth),
      'milliseconds'
    );
    const duration = moment.duration(diff);
    setReportCompiler({
      ...reportCompiler,
      ageAtTestCode: duration,
    });
  };

  const saveCalculateAgeToCompiler = () => {
    const savedAge = reportCompiler?.ageAtTestCode?._data?.years;
    const checkValue =
      // savedAge === 5 || savedAge === 6
      savedAge <= 5
        ? 'fiveToSixUsp'
        : savedAge === 7 || savedAge === 8
        ? 'sevenToEightUsp'
        : savedAge === 9 || savedAge === 10
        ? 'nineToTenUsp'
        : savedAge === 11 || savedAge === 12
        ? 'elevenToTwelveUsp'
        : savedAge === 13 || savedAge === 14
        ? 'thirteenToFourteenUsp'
        : savedAge === 15 ||
          savedAge === 16 ||
          savedAge === 17 ||
          savedAge === 18 ||
          savedAge === 19 ||
          savedAge === 20 ||
          savedAge === 21
        ? 'fifteenToTwentyOneUsp'
        : 'fiveToSixUsp';
    setReportCompiler({
      ...reportCompiler,
      ageAtTest:
        reportCompiler?.ageAtTestCode?._data?.years +
        ' Years, ' +
        reportCompiler?.ageAtTestCode?._data?.months +
        ' Months and ' +
        reportCompiler?.ageAtTestCode?._data?.days +
        ' Days',
      ageYears: reportCompiler?.ageAtTestCode?._data?.years,
      ageMonths: reportCompiler?.ageAtTestCode?._data?.months,
      selectedUsp: checkValue,
    });
    setCurrentRsid(checkValue);
    setCurrentUsp(checkValue);

    // reportCompiler?.ageYears !== null && setCurrentRsid(checkValue);

    // return checkValue;

    // setCurrentRsid(
    //   Number(reportCompiler?.ageYears === null)
    //     ? 'fiveToSixusp'
    //     : checkUsp(Number(reportCompiler.ageYears))
    // );
    // const ageToRsid = Number(reportCompiler.ageYears !== null)
    //   ? checkUsp(Number(reportCompiler.ageYears))
    //   : 'fiveToSixusp';
  };

  const checkUsp = (savedAge: number) => {
    const checkValue =
      savedAge === 5 || savedAge === 6
        ? 'fiveToSixUsp'
        : savedAge === 7 || savedAge === 8
        ? 'sevenToEightUsp'
        : savedAge === 9 || savedAge === 10
        ? 'nineToTenUsp'
        : savedAge === 11 || savedAge === 12
        ? 'elevenToTwelveUsp'
        : savedAge === 13 || savedAge === 14
        ? 'thirteenToFourteenUsp'
        : savedAge === 15 ||
          savedAge === 16 ||
          savedAge === 17 ||
          savedAge === 18 ||
          savedAge === 19 ||
          savedAge === 20 ||
          savedAge === 21
        ? 'fifteenToTwentyOneUsp'
        : 'none';

    return checkValue;
  };

  const automatedRsidBasedOnAge = () => {
    return setCurrentRsid(
      Number(reportCompiler?.ageYears === null)
        ? 'fiveToSixusp'
        : checkUsp(Number(reportCompiler.ageYears))
    );
  };
  const ageReset = () => {
    setReportCompiler({
      ...reportCompiler,
      dateOfBirth: null,
      dateOfTest: null,
      ageAtTestCode: null,
      ageAtTest: null,
    });
  };

  const arr = [{ x: 1 }, { x: 2 }, { x: 4 }];
  const addRawScoreData = (crsid: string) => {
    return crsid === 'fd'
      ? reportCompiler.fd?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'fs'
      ? reportCompiler.fs?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'lc'
      ? reportCompiler.lc?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'pac'
      ? reportCompiler.pac?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'pp'
      ? reportCompiler.pp?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'rs'
      ? reportCompiler.rs?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'sr'
      ? reportCompiler.sr?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'sa'
      ? reportCompiler.sa?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'sc'
      ? reportCompiler.sc?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'wc'
      ? reportCompiler.wc?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'wd'
      ? reportCompiler.wd?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'ws'
      ? reportCompiler.ws?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : crsid === 'fiveToSixUsp' ||
        crsid === 'sevenToEightUsp' ||
        crsid === 'nineToTenUsp' ||
        crsid === 'elevenToTwelveUsp' ||
        crsid === 'thirteenToFourteenUsp' ||
        crsid === 'fifteenToTwentyOneUsp'
      ? reportCompiler.usp?.reduce(
          (sum: number, { enteredValue }: any) => sum + Number(enteredValue),
          0
        )
      : '';

    //  lc: '',
    //  pac: '',
    //  pp: '',
    //  rs: '',
    //  sr: '',
    //  sa: '',
    //  sc: '',
    //  wc: '',
    //  wd: '',
    //  ws: '',
    //  usp: '',
  };
  const updateObservation = (cat: string, selection: any, selectionId: any) => {
    if (cat === 'responseTime') {
      setResponseTime(selection);
      setReportCompiler({
        ...reportCompiler,
        otherObservations_ResponseTime: {
          title: selection?.value,
          selectionId: selectionId,
        },
      });
    }

    if (cat === 'needForRepetitionOfStimuli') {
      setNeedForRepetitionOfStimuli(selection);
      setReportCompiler({
        ...reportCompiler,
        otherObservations_NeedForRepetitionOfStimuli: {
          title: selection?.value,
          selectionId: selectionId,
        },
      });
    }

    if (cat === 'attentionDuringTesting') {
      setAttentionDuringTesting(selection);
      setReportCompiler({
        ...reportCompiler,
        otherObservations_AttentionDuringTesting: {
          title: selection?.value,
          selectionId: selectionId,
        },
      });
    }

    if (cat === 'attitudesTowardsTesting') {
      setAttitudesTowardsTesting(selection);
      setReportCompiler({
        ...reportCompiler,
        otherObservations_AttitudesTowardsTesting: {
          title: selection?.value,
          selectionId: selectionId,
        },
      });
    }

    if (cat === 'socialLanguageRegisterandQuantity') {
      setSocialLanguageRegisterandQuantity(selection);
      setReportCompiler({
        ...reportCompiler,
        otherObservations_SocialLanguageRegisterandQuantity: {
          title: selection?.value,
          selectionId: selectionId,
        },
      });
    }
  };
  useEffect(() => {}, [reportCompiler]);

  // const test = {
  //   rsid: 'fd',
  //   title: 'Following Directions(FD)',
  //   scaledScore: '1',
  //   ciFd: '1',
  //   prFd: '0.1',
  //   ciMin: 0,
  //   ciMax: 2,
  //   prciFromFd: '<0.1',
  //   prciToFd: '0.4',
  //   testAge: '3:11',
  //   clsSumOfScaledScore: null,
  //   rliSumOfScaledScore: null,
  //   eliSumOfScaledScore: null,
  //   lciSumOfScaledScore: null,
  //   lsiOrLmiSumOfScaledScore: null,
  //   fdItemsAnalysisValuesZero: [],
  //   fdItemsAnalysisValuesCategoriesZero: [],
  //   fdItemsAnalysisValuesOne: [
  //     { item: '1. Point to the white triangle. Go.', score: '(0 to 1)', enteredValue: '1', id: 1 },
  //     { item: '2. Point to the black circles. Go.', score: '(0 to 1)', enteredValue: '1', id: 2 },
  //     {
  //       item: '3. Point to the big circle and the little circle. Go.',
  //       score: '(0 to 1)',
  //       enteredValue: '1',
  //       id: 3,
  //     },
  //   ],
  //   fdItemsAnalysisValuesCategoriesOne: [
  //     'No Orientation - 1-Level Commad',
  //     'One Modifiers ',
  //     'No Orientation - 2-Level Commad',
  //   ],
  // };
  const passReportCompilerToMakeEP = process.env.REACT_APP_PASS_REPORT_COMPILER_TO_MAKE_EP;
  const passReportCompilerToMakeEPToCreateReport =
    'https://hook.eu1.make.com/5xy18dce3zw83rvavpbyhnikgd461q28';

  function getIndexRangeForScores(startScore: number, endScore: number) {
    const firstScore = 160;
    const difference = -5;

    // Helper function to round to nearest multiple of 5
    function roundToNearestFive(score: number) {
      return Math.round(score / 5) * 5;
    }

    // Round start and end scores to the nearest multiples of 5
    startScore = roundToNearestFive(startScore);
    endScore = roundToNearestFive(endScore);

    // Calculate indices
    const startIndex = (startScore - firstScore) / difference + 1;
    const endIndex = (endScore - firstScore) / difference + 1;

    // Generate array of indices
    const indices = [];
    for (let i = startIndex; i <= endIndex; i++) {
      indices.push(i);
    }

    return indices;
  }

  // Example usage
  // const startScore = 74;
  // const endScore = 90;
  // const indices = getIndexRangeForScores(startScore, endScore);
  // console.log(indices); // Output: [18, 19, 20, 21, 22]

  function getIndexForScore(score: number) {
    const firstScore = 160;
    const difference = -5;

    // Round the score to the nearest multiple of 5
    if (score % 5 !== 0) {
      score = Math.round(score / 5) * 5;
    }

    const index = (score - firstScore) / difference + 1;
    return index;
  }

  // Example usage
  // const score = 73;
  // const index = getIndexForScore(score);
  // console.log(index);  // Output: 18 (since 73 is rounded to 75)

  const sotssClsRange = getIndexRangeForScores(
    Number(localStorage.getItem('sotssCLSCIMIN')),
    Number(localStorage.getItem('sotssCLSCIMAX'))
  );

  const sotssRliRange = getIndexRangeForScores(
    Number(localStorage.getItem('sotssRLICIMIN')),
    Number(localStorage.getItem('sotssRLICIMAX'))
  );

  const sotssEliRange = getIndexRangeForScores(
    Number(localStorage.getItem('sotssELICIMIN')),
    Number(localStorage.getItem('sotssELICIMAX'))
  );

  const sotssLciRange = getIndexRangeForScores(
    Number(localStorage.getItem('sotssLCICIMIN')),
    Number(localStorage.getItem('sotssLCICIMAX'))
  );

  const sotssLmiRange = getIndexRangeForScores(
    Number(localStorage.getItem('sotssLMICIMIN')),
    Number(localStorage.getItem('sotssLMICIMAX'))
  );

  const sotssLsiRange = getIndexRangeForScores(
    Number(localStorage.getItem('sotssLSICIMIN')),
    Number(localStorage.getItem('sotssLSICIMAX'))
  );

  console.log('sotssClsRange: ', sotssClsRange);
  const sotssCLSDOCSJSON = sotssClsRange.map((i: any) => ({
    updateTableCellStyle: {
      tableCellStyle: {
        backgroundColor: {
          color: {
            rgbColor: {
              red: 1,
            },
          },
        },
      },
      fields: 'backgroundColor',
      tableRange: {
        columnSpan: 1,
        rowSpan: 1,
        tableCellLocation: {
          columnIndex: i,
          rowIndex: i,
          tableStartLocation: {
            index: 1141,
          },
        },
      },
    },
  }));

  const sotssCLSDOCSJSONFINAL = {
    requests: sotssCLSDOCSJSON,
  };

  const checkJSONMake = {
    requests: [
      {
        updateTableCellStyle: {
          tableCellStyle: {
            backgroundColor: {
              color: {
                rgbColor: {
                  green: 1,
                },
              },
            },
          },
          fields: 'backgroundColor',
          tableRange: {
            columnSpan: 1,
            rowSpan: 1,
            tableCellLocation: {
              columnIndex: 2,
              rowIndex: 2,
              tableStartLocation: {
                index: 1141,
              },
            },
          },
        },
      },
      {
        updateTableCellStyle: {
          tableCellStyle: {
            backgroundColor: {
              color: {
                rgbColor: {
                  red: 1,
                },
              },
            },
          },
          fields: 'backgroundColor',
          tableRange: {
            columnSpan: 1,
            rowSpan: 1,
            tableCellLocation: {
              columnIndex: 3,
              rowIndex: 3,
              tableStartLocation: {
                index: 1141,
              },
            },
          },
        },
      },
    ],
  };

  // Custom replacer function to add \n
  function customReplacer(key: any, value: any) {
    if (typeof value === 'string') {
      return value.replace(/ /g, '\n');
    }
    return value;
  }
  // const jsonString = JSON.stringify(checkJSONMake, customReplacer, 2);
  const jsonString = JSON.stringify(sotssCLSDOCSJSON, customReplacer, 2);
  const clsipImageFallbackTemplate = '1ZGDbXxVkskv_Sznw4voo2b_6d9KkY_LS';
  console.log('sotssCLSDOCSJSON ', sotssCLSDOCSJSON);
  console.log('TEMPLATE TEST', newCandlestickOneImageId);
  const requestBody = {
    data: {
      txFullName: localStorage.getItem('txFullName'),
      txEmail: localStorage.getItem('txEmail'),
      txJobTitle: localStorage.getItem('txJobTitle'),
      reportCompiler: [reportCompiler],
      reportCompilerOutput: [reportCompilerOutput],
      CLISPImageFallbackTemplate: [clsipImageFallbackTemplate],
      CLISPImageOne: [newCandlestickOneImageId],
      CLISPImageTwo: [newCandlestickTwoImageId],
      CLISPImageThree: [newCandlestickThreeImageId],
      sotss: [
        {
          sotssCLS: localStorage.getItem('sotssCLS'),
          sotssCLSCIMIN: localStorage.getItem('sotssCLSCIMIN'),
          sotssCLSCIMAX: localStorage.getItem('sotssCLSCIMAX'),
        },
        {
          sotssRLI: localStorage.getItem('sotssRLI'),
          sotssRLICIMIN: localStorage.getItem('sotssRLICIMIN'),
          sotssRLICIMAX: localStorage.getItem('sotssRLICIMAX'),
        },
        {
          sotssELI: localStorage.getItem('sotssELI'),
          sotssELICIMIN: localStorage.getItem('sotssELICIMIN'),
          sotssELICIMAX: localStorage.getItem('sotssELICIMAX'),
        },
        {
          sotssLCI: localStorage.getItem('sotssLCI'),
          sotssLCICIMIN: localStorage.getItem('sotssLCICIMIN'),
          sotssLCICIMAX: localStorage.getItem('sotssLCICIMAX'),
        },
        {
          sotssLMI: localStorage.getItem('sotssLMI'),
          sotssLMICIMIN: localStorage.getItem('sotssLMICIMIN'),
          sotssLMICIMAX: localStorage.getItem('sotssLMICIMAX'),
        },
        {
          sotssLSI: localStorage.getItem('sotssLSI'),
          sotssLSICIMIN: localStorage.getItem('sotssLSICIMIN'),
          sotssLSICIMAX: localStorage.getItem('sotssLSICIMAX'),
        },
      ],
    },
  };
  const sspIndexProfile = [
    { type: 'wc', score: 8, ciMax: 10, ciMin: 6 },
    { type: 'fd', score: 8, ciMax: 7, ciMin: 9 },
    { type: 'fs', score: 5, ciMax: 3, ciMin: 7 },
    { type: 'rs', score: 5, ciMax: 3, ciMin: 7 },
    { type: 'us', score: 5, ciMax: 3, ciMin: 7 },
    { type: 'wd', score: 5, ciMax: 9, ciMin: 6 },
    { type: 'sa', score: 4, ciMax: 6, ciMin: 6 },
    { type: 'sr', score: 6, ciMax: 5, ciMin: 7 },
    { type: 'pp', score: 10, ciMax: 9, ciMin: 11 },
  ];

  function getImageFromUriFromGoogleChartOne() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let data = 1;
        setChartEventsStateChartOne([
          {
            eventName: 'ready' as GoogleVizEventName,
            callback: ({ chartWrapper }: { chartWrapper: any }) => {
              const chart = chartWrapper.getChart();
              const uri = chart.getImageURI();
              setImageUriChartOne(uri);
            },
          },
        ]);
        if (data) {
          resolve(data); // Resolve the promise with data
        } else {
          reject(new Error('Data not found')); // Reject the promise with an error
        }
      }, 2000);
    });
  }

  function getImageFromUriFromGoogleChartTwo() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let data = 1;
        setChartEventsStateChartTwo([
          {
            eventName: 'ready' as GoogleVizEventName,
            callback: ({ chartWrapper }: { chartWrapper: any }) => {
              const chart = chartWrapper.getChart();
              const uri = chart.getImageURI();
              setImageUriChartTwo(uri);
            },
          },
        ]);
        if (data) {
          resolve(data); // Resolve the promise with data
        } else {
          reject(new Error('Data not found')); // Reject the promise with an error
        }
      }, 2000);
    });
  }

  function getImageFromUriFromGoogleChartThree() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let data = 1;
        setChartEventsStateChartThree([
          {
            eventName: 'ready' as GoogleVizEventName,
            callback: ({ chartWrapper }: { chartWrapper: any }) => {
              const chart = chartWrapper.getChart();
              const uri = chart.getImageURI();
              setImageUriChartThree(uri);
            },
          },
        ]);
        if (data) {
          resolve(data); // Resolve the promise with data
        } else {
          reject(new Error('Data not found')); // Reject the promise with an error
        }
      }, 2000);
    });
  }

  const age = Number(reportCompiler.ageYears);

  const validationMessage =
    isNaN(age) || age < 5 || age > 21 ? 'Please enter a valid age between 5 and 21' : '';
  const [showCrButton, setShowCrButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      activeStep === 7 && setShowCrButton(true);
    }, 3000);

    // Clean up the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [activeStep]);

  useEffect(() => {
    getImageFromUriFromGoogleChartOne();
  }, [chartOne]);

  useEffect(() => {
    getImageFromUriFromGoogleChartTwo();
  }, [chartTwo]);

  useEffect(() => {
    getImageFromUriFromGoogleChartThree();
  }, [chartThree]);

  useEffect(() => {
    setNewCandlestickOneImageId(localStorage.getItem('CLISPImageOne')!);
  }, [clsipImageCreatedOne]);

  useEffect(() => {
    setNewCandlestickTwoImageId(localStorage.getItem('CLISPImageTwo')!);
  }, [clsipImageCreatedTwo]);

  useEffect(() => {
    setNewCandlestickThreeImageId(localStorage.getItem('CLISPImageThree')!);
  }, [clsipImageCreatedThree]);

  console.log('imageUriChartOne HERE ', chartEventsStateChartOne);
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white" borderRadius={20}>
          <Flex flexDirection={'column'} alignItems={'end'}>
            <Image width="200px" objectFit="cover" src={UL_logo_large} alt="Unlocking Language" />
          </Flex>
          <Heading pb={6}>CELF 5 Report Maker</Heading>
          {/* <Box p={2} bg={'pink'}>
            currentRsid: {currentRsid}
          </Box>
          <Box p={2} bg={'orange'}>
            currentUsp: {currentUsp}
          </Box>
          <Box p={2} bg={'lightblue'}>
            savedPac: {savedPac}
          </Box>
          <Box p={2} bg={'mediumpurple'}>
            <Box>{visible ? "I'm visible for 5000ms" : 'You can no longer see this content'}</Box>
          </Box>
          <Box p={2} bg={'lightgreen'}>
            <Box>{activeStep}</Box>
          </Box>
          <Box>reportCompiler:{JSON.stringify(reportCompiler)}</Box> */}
          <Box width={'100%'}>
            <HStack alignItems={'start'}>
              <Stepper
                index={activeStep}
                orientation="vertical"
                height="60vh"
                gap="0"
                colorScheme={'green'}
                size="lg"
                width={'15%'}
                // overflow={'hidden'}
                overflowWrap={'break-word'}
              >
                {steps.map((step, index) => (
                  <Step
                    key={index}
                    // onClick={() => setActiveStep(index + 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <StepIndicator color={'brand.300'}>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle style={{ marginTop: 6 }}>{step.title}</StepTitle>
                      <StepDescription>&nbsp;</StepDescription>
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
              <VStack width={'85%'}>
                {activeStep === 7 && (
                  <Box width={'100%'} float={'left'}>
                    <HStack mb={5} justifyContent={'flex-end'}>
                      <Button
                        variant={'defaultButton'}
                        _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                        onClick={() => {
                          setActiveStep(activeStep - 1);
                        }}
                        mr={5}
                      >
                        Back
                      </Button>
                      {showCrButton ? (
                        <Button
                          _hover={{ backgroundColor: 'purple.600', color: 'white' }}
                          onClick={() => {
                            let base64ImageChartOne = imageUriChartOne!
                              .toString()
                              .split(';base64,')
                              .pop();

                            let base64ImageChartTwo = imageUriChartTwo!
                              .toString()
                              .split(';base64,')
                              .pop();
                            let base64ImageChartThree = imageUriChartThree!
                              .toString()
                              .split(';base64,')
                              .pop();
                            const currentTimestamp = new Date();
                            uploadFileToClientUploadFolder(
                              base64ImageChartOne,
                              reportCompiler.studentName !== null
                                ? reportCompiler.studentName +
                                    ' Core Language & Index Score Profile: Scores ' +
                                    currentTimestamp
                                : 'Core Language & Index Score Profile: Scores' + currentTimestamp,
                              reportCompiler.studentName !== null
                                ? reportCompiler.studentName +
                                    ' Core Language & Index Score Profile: Scores' +
                                    currentTimestamp
                                : 'Core Language & Index Score Profile: Scores' + currentTimestamp,
                              '16znu0Or6ku_RY0GOOR7xMXMU_fMAnxgX',
                              getFileTypeFromBase64(base64ImageChartOne),
                              getFileSizeFromBase64(base64ImageChartOne)
                            ).then((uploadData: any) => {
                              console.log('uploaded to drive:' + uploadData);
                              console.log(
                                'uploaded screenshot url',
                                `https://drive.google.com/file/d/${uploadData}/view`
                              );
                              const uploadFileSizeOne = getFileSizeFromBase64(base64ImageChartOne);
                              localStorage.setItem(
                                'CLISPImageOne',
                                Number(uploadFileSizeOne) === 0
                                  ? clsipImageFallbackTemplate
                                  : uploadData
                              );
                              setClsipImageCreatedOne(1);
                              setCreateReportButton(1);
                            });

                            uploadFileToClientUploadFolder(
                              base64ImageChartTwo,
                              reportCompiler.studentName !== null
                                ? reportCompiler.studentName +
                                    ' Receptive Language (Understanding) scores' +
                                    currentTimestamp
                                : ' Receptive Language (Understanding) scores' + currentTimestamp,
                              reportCompiler.studentName !== null
                                ? reportCompiler.studentName +
                                    '  Receptive Language (Understanding) scores' +
                                    currentTimestamp
                                : ' Receptive Language (Understanding) scores' + currentTimestamp,
                              '16znu0Or6ku_RY0GOOR7xMXMU_fMAnxgX',
                              getFileTypeFromBase64(base64ImageChartTwo),
                              getFileSizeFromBase64(base64ImageChartTwo)
                            ).then((uploadData: any) => {
                              console.log('uploaded to drive:' + uploadData);
                              console.log(
                                'uploaded screenshot url two',
                                `https://drive.google.com/file/d/${uploadData}/view`
                              );
                              const uploadFileSizeTwo = getFileSizeFromBase64(base64ImageChartTwo);

                              localStorage.setItem(
                                'CLISPImageTwo',
                                Number(uploadFileSizeTwo) === 0
                                  ? clsipImageFallbackTemplate
                                  : uploadData
                              );
                              setClsipImageCreatedTwo(1);
                              setCreateReportButton(1);
                            });

                            uploadFileToClientUploadFolder(
                              base64ImageChartThree,
                              reportCompiler.studentName !== null
                                ? reportCompiler.studentName +
                                    ' Expressive Language (Talking) scores' +
                                    currentTimestamp
                                : ' Expressive Language (Talking) scores' + currentTimestamp,
                              reportCompiler.studentName !== null
                                ? reportCompiler.studentName +
                                    '  Expressive Language (Talking) scores' +
                                    currentTimestamp
                                : ' Expressive Language (Talking) scores' + currentTimestamp,
                              '16znu0Or6ku_RY0GOOR7xMXMU_fMAnxgX',
                              getFileTypeFromBase64(base64ImageChartThree),
                              getFileSizeFromBase64(base64ImageChartThree)
                            ).then((uploadData: any) => {
                              console.log('uploaded to drive:' + uploadData);
                              console.log(
                                'uploaded screenshot url three',
                                `https://drive.google.com/file/d/${uploadData}/view`
                              );
                              const uploadFileSizeThree =
                                getFileSizeFromBase64(base64ImageChartThree);

                              localStorage.setItem(
                                'CLISPImageThree',
                                Number(uploadFileSizeThree) === 0
                                  ? clsipImageFallbackTemplate
                                  : uploadData
                              );
                              setClsipImageCreatedThree(1);
                              setCreateReportButton(1);
                            });

                            setCelfLoadingIcon(false);
                            setActiveStep(activeStep + 1);
                          }}
                          bg={'purple.700'}
                          color={'white'}
                        >
                          Compile Report
                        </Button>
                      ) : (
                        <Box p={10} width={150}>
                          <Progress size="xs" isIndeterminate colorScheme="purple.700" />
                        </Box>
                      )}
                    </HStack>
                  </Box>
                )}
                <Box
                  width={'100%'}
                  height={'100vh'}
                  overflowY={'scroll'}
                  // bg={'yellow'}
                >
                  {activeStep === 1 && (
                    <Box
                      bg={'gray.100'}
                      p={10}
                      width={'100%'}
                      height={'100vh'}
                      borderRadius={20}
                      overflowY={'scroll'}
                    >
                      <HStack justifyContent="space-between" alignItems="center">
  <Heading mt={-2}>Student details</Heading>
  <Button
    variant={'defaultButton'}
    onClick={() => {
      window.open(
        'https://unlockinglanguage.retool.com/embedded/public/0529cb05-d300-4497-8b94-e5d6fccbb03f',
        '_blank'
      );
    }}
  >
    External RAPT Interpretation
  </Button>
</HStack>

                      <Box p={'5'} width={'100%'}>
                        <FormControl pt={'5'}>
                          <FormLabel>Student name: </FormLabel>
                          <Input
                            bg={'white'}
                            name="studentName"
                            value={reportCompiler.studentName}
                            onChange={(e: any) =>
                              setReportCompiler({ ...reportCompiler, studentName: e.target.value })
                            }
                            required
                          ></Input>
                        </FormControl>
                        <FormControl pt={'5'}>
                          <FormLabel>Address: </FormLabel>
                          <Textarea
                            bg={'white'}
                            name="address"
                            value={reportCompiler.address}
                            onChange={(e: any) =>
                              setReportCompiler({ ...reportCompiler, address: e.target.value })
                            }
                          ></Textarea>
                        </FormControl>
                        <FormControl pt={'5'}>
                          <FormLabel>Gender: </FormLabel>
                          <RadioGroup
                            size="md"
                            colorScheme="green"
                            name="gender"
                            value={reportCompiler.gender}
                            onClick={(e: any) =>
                              setReportCompiler({ ...reportCompiler, gender: e.target.value })
                            }
                          >
                            <Stack direction="row">
                              <Radio value="male" bg={'white'}>
                                Male
                              </Radio>
                              <Radio value="female" bg={'white'}>
                                Female
                              </Radio>
                              <Radio value="other" bg={'white'}>
                                Other
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </FormControl>
                        <FormControl pt={'5'}>
                          <FormLabel>School year: </FormLabel>
                          <Input
                            bg={'white'}
                            name="schoolYear"
                            type="number"
                            value={reportCompiler.schoolYear}
                            onChange={(e: any) =>
                              setReportCompiler({ ...reportCompiler, schoolYear: e.target.value })
                            }
                            required
                          ></Input>
                        </FormControl>
                        <FormControl pt={'5'}>
                          <FormLabel>School: </FormLabel>
                          <Input
                            bg={'white'}
                            name="school"
                            value={reportCompiler.school}
                            onChange={(e: any) =>
                              setReportCompiler({ ...reportCompiler, school: e.target.value })
                            }
                          ></Input>
                        </FormControl>
                        <FormControl pt={'5'}>
                          <FormLabel>Teacher: </FormLabel>
                          <Input
                            bg={'white'}
                            name="teacher"
                            value={reportCompiler.teacher}
                            onChange={(e: any) =>
                              setReportCompiler({ ...reportCompiler, teacher: e.target.value })
                            }
                          ></Input>
                        </FormControl>
                        <FormControl pt={'5'}>
                          <FormLabel>Examiner: </FormLabel>
                          <Input
                            bg={'white'}
                            name="examiner"
                            value={reportCompiler.examiner}
                            onChange={(e: any) =>
                              setReportCompiler({ ...reportCompiler, examiner: e.target.value })
                            }
                          ></Input>
                        </FormControl>
                        <HStack>
                          <Button
                            variant={'defaultButton'}
                            _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                            onClick={() => {
                              setActiveStep(2);
                            }}
                            my={5}
                            isDisabled={
                              reportCompiler.studentName === null ||
                              reportCompiler.gender === null ||
                              reportCompiler.schoolYear === null
                                ? true
                                : false
                            }
                          >
                            Next
                          </Button>
                        </HStack>
                      </Box>
                    </Box>
                  )}

                  {activeStep === 2 && (
                    <Box bg={'gray.100'} p={10} width={'100%'} height={'100vh'} borderRadius={20}>
                      <Box width={'100%'} height={'100vh'}>
                        <Heading>Calculation of a students age</Heading>

                        <FormControl pt={'5'}>
                          <FormLabel>Date of test:</FormLabel>
                          <Input
                            bg={'white'}
                            name="dateOfTest"
                            value={moment(reportCompiler.dateOfTest).format('YYYY-MM-DD')}
                            type="date"
                            width={200}
                            onChange={(e: any) =>
                              setReportCompiler({ ...reportCompiler, dateOfTest: e.target.value })
                            }
                          />
                        </FormControl>

                        <FormControl pt={'5'}>
                          <FormLabel>Date of birth:</FormLabel>
                          <Input
                            bg={'white'}
                            name="dateOfBirth"
                            value={moment(reportCompiler.dateOfBirth).format('YYYY-MM-DD')}
                            type="date"
                            width={200}
                            onChange={(e: any) =>
                              setReportCompiler({ ...reportCompiler, dateOfBirth: e.target.value })
                            }
                          />
                        </FormControl>

                        <Box>
                          {ageCalculated === 0 && (
                            <FormControl pt={'5'}>
                              <FormLabel>Age at test: </FormLabel>
                              <Button
                                variant={'defaultButton'}
                                _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                                onClick={() => {
                                  calculateAgeAtTest();
                                }}
                                isDisabled={
                                  reportCompiler.dateOfBirth === null ||
                                  reportCompiler.dateOfTest === null ||
                                  reportCompiler.ageOfTest
                                    ? true
                                    : false
                                }
                                mb={10}
                              >
                                Calculate age at test
                              </Button>
                              {
                                <Box>
                                  {reportCompiler.ageAtTestCode !== null ? (
                                    <HStack mb={10}>
                                      <Text fontWeight={'bold'} fontSize={30}>
                                        {reportCompiler?.ageAtTestCode?._data?.years} Years,
                                      </Text>
                                      <Text fontWeight={'bold'} fontSize={30}>
                                        {reportCompiler?.ageAtTestCode?._data?.months} Months and
                                      </Text>
                                      <Text fontWeight={'bold'} fontSize={30}>
                                        {reportCompiler?.ageAtTestCode?._data?.days} Days
                                      </Text>

                                      <Button
                                        variant={'defaultButton'}
                                        _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                                        onClick={() => {
                                          ageReset();
                                        }}
                                        isDisabled={
                                          reportCompiler.dateOfBirth === null ||
                                          reportCompiler.dateOfTest === null ||
                                          reportCompiler.ageAtTestCode === null
                                            ? true
                                            : false
                                        }
                                        px={10}
                                      >
                                        Reset
                                      </Button>
                                    </HStack>
                                  ) : (
                                    ''
                                  )}
                                  <Button
                                    variant={'defaultButton'}
                                    _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                                    onClick={() => {
                                      saveCalculateAgeToCompiler();
                                    }}
                                    isDisabled={
                                      reportCompiler.dateOfBirth === null ||
                                      reportCompiler.dateOfTest === null ||
                                      reportCompiler.ageAtTestCode === null
                                        ? true
                                        : false
                                    }
                                  >
                                    Save Calculated Age to Report
                                  </Button>
                                </Box>
                              }
                            </FormControl>
                          )}
                          <HStack>
                            <Button
                              variant={'defaultButton'}
                              _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                              onClick={() => {
                                setActiveStep(activeStep - 1);
                              }}
                              my={10}
                              mr={5}
                            >
                              Back
                            </Button>
                            <Button
                              variant={'defaultButton'}
                              _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                              onClick={() => {
                                setActiveStep(activeStep + 1);
                              }}
                              my={10}
                              isDisabled={
                                reportCompiler.ageAtTestCode === null ||
                                reportCompiler.ageAtTest === null ||
                                reportCompiler.ageYears === null ||
                                reportCompiler.ageYears < 5 ||
                                reportCompiler.ageYears > 21
                                  ? true
                                  : false
                              }
                            >
                              Next
                            </Button>
                            <Box ml={5} fontWeight={'bold'} color={'red'}>
                              {/* {Number(reportCompiler.ageYears) < 5 ||
                              Number(reportCompiler.ageYears) > 21
                                ? 'Please enter a valid age between 5 and 21'
                                : ''} */}
                              {validationMessage && <Text>{validationMessage}</Text>}
                            </Box>
                          </HStack>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {activeStep === 4 && (
                    <Box bg={'gray.100'} p={10} width={'100%'} borderRadius={20}>
                      <Box>
                        <Heading>Raw scores calculation</Heading>
                      </Box>
                      <Box width={'100%'} mt={10} overflow={'scroll'} height={'100vh'} pt={'5'}>
                        <VStack width={'100%'} bg="transparent">
                          {rsModalData.map((z: any, u: any) => (
                            <Box
                              bg={'transparent'}
                              width={'100%'}
                              textAlign={'center'}
                              alignItems={'center'}
                              fontWeight={'bold'}
                              key={u}
                            >
                              <HStack
                                _hover={{
                                  backgroundColor: 'brand.100',
                                  cursor: 'pointer',
                                  borderRadius: 5,
                                }}
                                p="2"
                              >
                                <Box
                                  key={u}
                                  width={'100%'}
                                  textAlign={'left'}
                                  alignItems={'left'}
                                  fontWeight={'bold'}
                                  bg={'transparent'}
                                >
                                  {z.rsTitle}
                                </Box>
                                <Box w={150}></Box>

                                <Text fontWeight={'bold'} fontSize={30} width={100}>
                                  {z.rsid === 'fd'
                                    ? reportCompiler.fdFinaleScore === null
                                      ? 0
                                      : reportCompiler.fdFinaleScore
                                    : z.rsid === 'fs'
                                    ? reportCompiler.fsFinaleScore === null
                                      ? 0
                                      : reportCompiler.fsFinaleScore
                                    : z.rsid === 'lc'
                                    ? reportCompiler.lcFinaleScore === null
                                      ? 0
                                      : reportCompiler.lcFinaleScore
                                    : z.rsid === 'pac'
                                    ? reportCompiler.pacFinaleScore === null
                                      ? 0
                                      : reportCompiler.pacFinaleScore
                                    : z.rsid === 'pp'
                                    ? reportCompiler.ppFinaleScore === null
                                      ? 0
                                      : reportCompiler.ppFinaleScore
                                    : z.rsid === 'rs'
                                    ? reportCompiler.rsFinaleScore === null
                                      ? 0
                                      : reportCompiler.rsFinaleScore
                                    : z.rsid === 'sr'
                                    ? reportCompiler.srFinaleScore === null
                                      ? 0
                                      : reportCompiler.srFinaleScore
                                    : z.rsid === 'sa'
                                    ? reportCompiler.saFinaleScore === null
                                      ? 0
                                      : reportCompiler.saFinaleScore
                                    : z.rsid === 'sc'
                                    ? reportCompiler.scFinaleScore === null
                                      ? 0
                                      : reportCompiler.scFinaleScore
                                    : z.rsid === 'wc'
                                    ? reportCompiler.wcFinaleScore === null
                                      ? 0
                                      : reportCompiler.wcFinaleScore
                                    : z.rsid === 'wd'
                                    ? reportCompiler.wdFinaleScore === null
                                      ? 0
                                      : reportCompiler.wdFinaleScore
                                    : z.rsid === 'ws'
                                    ? reportCompiler.wsFinaleScore === null
                                      ? 0
                                      : reportCompiler.wsFinaleScore
                                    : z.rsid === 'usp'
                                    ? reportCompiler.uspFinaleScore === null
                                      ? 0
                                      : reportCompiler.uspFinaleScore
                                    : ''}
                                  {/* {reportCompiler.`${z.enteredValue}`+ 'FinaleScore'} */}
                                </Text>
                                <Button
                                  variant={'defaultButton'}
                                  onClick={() => handleModalOpen(z.rsTitle, z.rsData, z.rsid)}
                                >
                                  Add score
                                </Button>
                              </HStack>
                            </Box>
                          ))}
                          {uspModalData
                            .filter((d: any) => String(d.rsid) === String(currentUsp))
                            .map((p: any, u: any) => (
                              <Box
                                bg={'transparent'}
                                width={'100%'}
                                textAlign={'center'}
                                alignItems={'center'}
                                fontWeight={'bold'}
                                key={u}
                              >
                                <HStack>
                                  <Box
                                    width={'100%'}
                                    textAlign={'left'}
                                    alignItems={'left'}
                                    fontWeight={'bold'}
                                    background={'transparent'}
                                  >
                                    {p.rsTitle}
                                  </Box>
                                  <Box w={150}>
                                    <Select
                                      placeholder="Change USP Level"
                                      // value={checkUsp(Number(reportCompiler.ageYears))}
                                      value={currentRsid}
                                      onChange={(e: any) => {
                                        console.log('currentUsp', e.target.value);
                                        console.log('currentRsid', e.target.value);
                                        console.log('modalContent', modalContent);
                                        setCurrentUsp(e.target.value);
                                        setCurrentRsid(e.target.value);
                                        setReportCompiler({
                                          ...reportCompiler,
                                          selectedUsp: e.target.value,
                                        });
                                      }}
                                      bg={'white'}
                                      width={'200'}
                                    >
                                      <option value="fiveToSixUsp">5 to 6 years</option>
                                      <option value="sevenToEightUsp">7 to 8 years</option>
                                      <option value="nineToTenUsp">9 to 10 years</option>
                                      <option value="elevenToTwelveUsp">11 to 12 years</option>
                                      <option value="thirteenToFourteenUsp">13 to 14 years</option>
                                      <option value="fifteenToTwentyOneUsp">15 to 21 years</option>
                                    </Select>
                                  </Box>

                                  <Text fontWeight={'bold'} fontSize={30} width={'10%'}>
                                    {/* {z.rsid === 'fiveToSixUsp' ||
                                      z.rsid === 'sevenToEightUsp' ||
                                      z.rsid === 'nineToTenUsp' ||
                                      z.rsid === 'elevenToTwelveUsp' ||
                                      z.rsid === 'thirteenToFourteenUsp' ||
                                      (z.rsid === 'fifteenToTwentyOneUsp' &&
                                        (reportCompiler.uspFinaleScore === null
                                          ? 0
                                          : reportCompiler.uspFinaleScore))} */}
                                    {reportCompiler.uspFinaleScore === null
                                      ? 0
                                      : reportCompiler.uspFinaleScore}
                                    {/* {reportCompiler.`${z.enteredValue}`+ 'FinaleScore'} */}
                                  </Text>

                                  <Button
                                    variant={'defaultButton'}
                                    onClick={() => handleModalOpen(p.rsTitle, p.rsData, p.rsid)}
                                  >
                                    Add score
                                  </Button>
                                </HStack>
                              </Box>
                            ))}
                          <Modal
                            isCentered
                            onClose={onClose}
                            isOpen={isOpen}
                            motionPreset="slideInBottom"
                            size={'5xl'}
                            scrollBehavior={'inside'}
                          >
                            <ModalOverlay />

                            <ModalContent>
                              <VStack overflow={'scroll'} height={'100vh'}>
                                <ModalHeader>{rsTitle}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody
                                  overflow={'scroll'}
                                  alignContent={'center'}
                                  // alignItems={'center'}
                                  textAlign={'center'}
                                  width={'100%'}
                                  // bg={'pink'}
                                >
                                  {/* {JSON.stringify(modalContent)} */}

                                  <Tabs
                                    onChange={(index) => setTabIndex(index)}
                                    variant="soft-rounded"
                                    colorScheme="green"
                                  >
                                    <TabList
                                      width={'100%'}
                                      display={'flex'}
                                      flexDirection={'row'}
                                      textAlign={'center'}
                                      justifyContent={'center'}
                                      alignItems={'center'}
                                      // bg={'yellow'}
                                    >
                                      <Tab>Enter Score </Tab>
                                      <Tab>Calculate Score</Tab>
                                    </TabList>

                                    <TabPanels>
                                      <TabPanel>
                                        <Box
                                          width={'100%'}
                                          textAlign={'center'}
                                          alignContent={'center'}
                                          alignItems={'center'}
                                        >
                                          <VStack
                                            textAlign={'center'}
                                            alignContent={'center'}
                                            alignItems={'center'}
                                            width={'100%'}
                                          >
                                            <Input
                                              bg={'white'}
                                              name={'typedScore'}
                                              value={
                                                typedScore
                                                // currentRsid === 'fd'
                                                //   ? reportCompiler.fdFinaleScore
                                                //   : currentRsid === 'fs'
                                                //   ? reportCompiler.fsFinaleScore
                                                //   : currentRsid === 'fd'
                                                //   ? reportCompiler.lcFinaleScore
                                                //   : currentRsid === 'lc'
                                                //   ? reportCompiler.fdFinaleScore
                                                //   : currentRsid === 'pac'
                                                //   ? reportCompiler.pacFinaleScore
                                                //   : currentRsid === 'pp'
                                                //   ? reportCompiler.ppFinaleScore
                                                //   : currentRsid === 'rs'
                                                //   ? reportCompiler.rsFinaleScore
                                                //   : currentRsid === 'sr'
                                                //   ? reportCompiler.srFinaleScore
                                                //   : currentRsid === 'sa'
                                                //   ? reportCompiler.saFinaleScore
                                                //   : currentRsid === 'sc'
                                                //   ? reportCompiler.scFinaleScore
                                                //   : currentRsid === 'wc'
                                                //   ? reportCompiler.wcFinaleScore
                                                //   : currentRsid === 'wd'
                                                //   ? reportCompiler.wdFinaleScore
                                                //   : currentRsid === 'ws'
                                                //   ? reportCompiler.wsFinaleScore
                                                //   : currentRsid === 'usp'
                                                //   ? reportCompiler.uspFinaleScore
                                                //   : ''
                                              }
                                              mr={2}
                                              width={200}
                                              type={'number'}
                                              max={1}
                                              onChange={(e: any) => {
                                                // currentRsid === 'fd' &&
                                                setTypedScore(e.target.value);

                                                // currentRsid === 'fs' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.fsFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'lc' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.lcFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'pac' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.pacFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'pp' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.ppFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'rs' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.rsFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'sr' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.srFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'sa' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.saFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'sc' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.scFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'wc' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.wcFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'wd' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.wdFinaleScore = e.target.value),
                                                //   });

                                                // currentRsid === 'ws' &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.wsFinaleScore = e.target.value),
                                                //   });

                                                // (currentRsid === 'fiveToSixUsp' ||
                                                //   currentRsid === 'sevenToEightUsp' ||
                                                //   currentRsid === 'nineToTenUsp' ||
                                                //   currentRsid === 'elevenToTwelveUsp' ||
                                                //   currentRsid === 'thirteenToFourteenUsp' ||
                                                //   currentRsid === 'fifteenToTwentyOneUsp') &&
                                                //   setReportCompiler({
                                                //     ...reportCompiler,
                                                //     ...(reportCompiler.uspFinaleScore = e.target.value),
                                                //   });
                                              }}
                                            ></Input>

                                            <Box mt={10} bg={'gray.100'} borderRadius={10} p={10}>
                                              Please input the raw score, or alternatively, input
                                              the scores separately using the "Calculate Score" tab
                                              provided above. Entering the scores separately will
                                              include an item analysis for each subtest in your
                                              report.
                                            </Box>
                                          </VStack>
                                        </Box>
                                      </TabPanel>
                                      <TabPanel>
                                        <Box>
                                          {modalContent !== 'none' &&
                                            modalContent?.map((i: any, k: any) => {
                                              let ModalOptionValues =
                                                currentRsid && currentRsid === 'fd'
                                                  ? reportCompiler?.fd[k]?.enteredValue
                                                  : currentRsid === 'fs'
                                                  ? reportCompiler?.fs[k]?.enteredValue
                                                  : currentRsid === 'lc'
                                                  ? reportCompiler?.lc[k]?.enteredValue
                                                  : currentRsid === 'pac'
                                                  ? reportCompiler?.pac[k]?.enteredValue
                                                  : currentRsid === 'pp'
                                                  ? reportCompiler?.pp[k]?.enteredValue
                                                  : currentRsid === 'rs'
                                                  ? reportCompiler?.rs[k]?.enteredValue
                                                  : currentRsid === 'sr'
                                                  ? reportCompiler?.sr[k]?.enteredValue
                                                  : currentRsid === 'sa'
                                                  ? reportCompiler?.sa[k]?.enteredValue
                                                  : currentRsid === 'sc'
                                                  ? reportCompiler?.sc[k]?.enteredValue
                                                  : currentRsid === 'wc'
                                                  ? reportCompiler?.wc[k]?.enteredValue
                                                  : currentRsid === 'wd'
                                                  ? reportCompiler?.wd[k]?.enteredValue
                                                  : currentRsid === 'ws'
                                                  ? reportCompiler?.ws[k]?.enteredValue
                                                  : currentRsid === 'fiveToSixUsp' ||
                                                    currentRsid === 'sevenToEightUsp' ||
                                                    currentRsid === 'nineToTenUsp' ||
                                                    currentRsid === 'elevenToTwelveUsp' ||
                                                    currentRsid === 'thirteenToFourteenUsp' ||
                                                    currentRsid === 'fifteenToTwentyOneUsp'
                                                  ? reportCompiler?.usp[k]?.enteredValue
                                                  : 'none';

                                              return (
                                                <Box
                                                  key={k}
                                                  width={'100%'}
                                                  px={'0%'}
                                                  pb={2}
                                                  background={'transparent'}
                                                  textAlign={'left'}
                                                >
                                                  <HStack>
                                                    <Text mr={2} width={'70%'}>
                                                      {i.item}
                                                    </Text>
                                                    <Text mr={2} width={'10%'}>
                                                      {i.score}
                                                    </Text>
                                                    {ModalOptionValues !== 0 && (
                                                      <Select
                                                        placeholder="Select"
                                                        bg={'white'}
                                                        name={k}
                                                        value={ModalOptionValues}
                                                        mr={2}
                                                        width={'10%'}
                                                        onChange={(e: any) => {
                                                          currentRsid === 'fd' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.fd[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'fs' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.fs[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'lc' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.lc[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'pac' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.pac[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'pp' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.pp[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'rs' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.rs[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'sr' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.sr[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'sa' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.sa[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'sc' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.sc[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'wc' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.wc[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'wd' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.wd[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          currentRsid === 'ws' &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.ws[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });

                                                          (currentRsid === 'fiveToSixUsp' ||
                                                            currentRsid === 'sevenToEightUsp' ||
                                                            currentRsid === 'nineToTenUsp' ||
                                                            currentRsid === 'elevenToTwelveUsp' ||
                                                            currentRsid ===
                                                              'thirteenToFourteenUsp' ||
                                                            currentRsid ===
                                                              'fifteenToTwentyOneUsp') &&
                                                            setReportCompiler({
                                                              ...reportCompiler,
                                                              ...(reportCompiler.usp[
                                                                k
                                                              ].enteredValue = e.target.value),
                                                            });
                                                        }}
                                                      >
                                                        {currentRsid === 'rs' ? (
                                                          <>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                          </>
                                                        ) : currentRsid === 'pp' ? (
                                                          <>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                          </>
                                                        ) : currentRsid === 'fs' ? (
                                                          <>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                          </>
                                                        )}
                                                      </Select>
                                                      // <Input
                                                      //   bg={'white'}
                                                      //   name={k}
                                                      //   value={ModalOptionValues}
                                                      //   mr={2}
                                                      //   width={'10%'}
                                                      //   type={'number'}
                                                      //   onChange={(e: any) => {
                                                      //     currentRsid === 'fd' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.fd[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'fs' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.fs[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'lc' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.lc[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'pac' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.pac[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'pp' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.pp[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'rs' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.rs[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'sr' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.sr[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'sa' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.sa[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'sc' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.sc[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'wc' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.wc[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'wd' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.wd[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     currentRsid === 'ws' &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.ws[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });

                                                      //     (currentRsid === 'fiveToSixUsp' ||
                                                      //       currentRsid === 'sevenToEightUsp' ||
                                                      //       currentRsid === 'nineToTenUsp' ||
                                                      //       currentRsid === 'elevenToTwelveUsp' ||
                                                      //       currentRsid === 'thirteenToFourteenUsp' ||
                                                      //       currentRsid ===
                                                      //         'fifteenToTwentyOneUsp') &&
                                                      //       setReportCompiler({
                                                      //         ...reportCompiler,
                                                      //         ...(reportCompiler.usp[k].enteredValue =
                                                      //           e.target.value),
                                                      //       });
                                                      //   }}
                                                      // ></Input>
                                                    )}
                                                  </HStack>
                                                </Box>
                                              );
                                            })}
                                          {/* fiveToSixUsp
                                        sevenToEightUsp
                                        nineToTenUsp
                                        elevenToTwelveUsp
                                        thirteenToFourteenUsp
                                        fifteenToTwentyOneUsp */}
                                        </Box>
                                      </TabPanel>
                                    </TabPanels>
                                  </Tabs>
                                </ModalBody>
                                <Box width={'100%'}>
                                  {/* <Box>{JSON.stringify(modalContent)}</Box> */}

                                  <HStack>
                                    <Text ml={10} width={'90%'} fontWeight={'bold'}>
                                      {'Total Raw Score'}
                                    </Text>
                                    <Text
                                      mr={16}
                                      width={'10%'}
                                      fontWeight={'bold'}
                                      fontSize={'2.5em'}
                                      mt={2}
                                    >
                                      {/* {arr.reduce((sum: any, { x }: any) => sum + x, 0)} */}
                                      {addRawScoreData(currentRsid)}

                                      {/* {modalContent?.reduce(
                                        (sum: any, { enteredValue }: any) => sum + enteredValue,
                                        0
                                      )} */}

                                      {/* {modalContent?.reduce(
                                        (sum: any, { enteredValue }: any) => sum + enteredValue,
                                        0
                                      )} */}
                                      {/* {modalContent.reduce(
                                        (sum: any, { enteredValue }: any) => sum + enteredValue,
                                        0
                                      )} */}
                                      {/* {modalContent.length} */}
                                    </Text>
                                  </HStack>
                                </Box>
                                <ModalFooter>
                                  {tabIndex === 1 ? (
                                    saveTotalScore === 0 ? (
                                      <Button
                                        colorScheme="green"
                                        mr={3}
                                        onClick={() => {
                                          saveFinalCalculatedRawScore(
                                            Number(addRawScoreData(currentRsid))
                                          );
                                          // saveFinalEnteredRawScore(addRawScoreData(currentRsid));
                                          // setTypedScore(addRawScoreData(currentRsid));
                                          setSaveTotalScore(1);
                                          setModalContent('none');
                                        }}
                                      >
                                        Save Calculated Score
                                      </Button>
                                    ) : (
                                      <Button
                                        colorScheme="purple"
                                        mr={3}
                                        onClick={() => {
                                          setModalContent('none');
                                          setSaveTotalScore(0);
                                          CalculateReport(currentRsid);
                                          setTabIndex(0);
                                          onClose();
                                        }}
                                      >
                                        Add to the report
                                      </Button>
                                    )
                                  ) : saveTotalScore === 0 ? (
                                    <Button
                                      colorScheme="green"
                                      mr={3}
                                      onClick={() => {
                                        saveFinalEnteredRawScore(typedScore);
                                        setSaveTotalScore(1);
                                        setModalContent('none');
                                      }}
                                    >
                                      Save Entered Score
                                    </Button>
                                  ) : (
                                    <Button
                                      colorScheme="purple"
                                      mr={3}
                                      onClick={() => {
                                        setModalContent('none');
                                        setSaveTotalScore(0);
                                        CalculateReport(currentRsid);
                                        setTabIndex(0);
                                        onClose();
                                      }}
                                    >
                                      Add to report
                                    </Button>
                                  )}

                                  <Button colorScheme="green" mr={3} onClick={onClose}>
                                    Close
                                  </Button>
                                </ModalFooter>
                              </VStack>
                            </ModalContent>
                          </Modal>
                          <HStack width={'100%'}>
                            {/* <Button
                              variant={'defaultButton'}
                              _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                              onClick={() => {
                                setActiveStep(activeStep - 1);
                              }}
                              my={10}
                              mr={5}
                            >
                              Back
                            </Button> */}
                            <Button
                              variant={'defaultButton'}
                              _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                              onClick={() => {
                                setActiveStep(activeStep + 1);
                              }}
                              my={10}
                            >
                              Next
                            </Button>
                          </HStack>
                        </VStack>
                      </Box>
                    </Box>
                  )}

                  {activeStep === 3 && (
                    <Box bg={'gray.100'} p={10} width={'100%'} height={'100vh'} borderRadius={20}>
                      <Heading>Levels</Heading>
                      <VStack>
                        <FormControl pt={'5'}>
                          <FormLabel>Confidence Interval Level (CI):</FormLabel>
                          <Box width="200px" mt={5}>
                            <Select
                              placeholder="Select CI Level"
                              size="md"
                              value={reportCompiler.ciLevel}
                              bg={'white'}
                              required
                              onChange={(e: any) => {
                                setCiLevel(e.target.value);
                                setReportCompiler({
                                  ...reportCompiler,
                                  ciLevel: e.target.value,
                                });
                              }}
                            >
                              {cii_values.map((i: any, k: any) => (
                                <option value={i} key={k}>
                                  {i}
                                </option>
                              ))}
                            </Select>
                          </Box>
                        </FormControl>

                        <FormControl pt={'5'}>
                          <FormLabel>Statistically Significant Level(SS):</FormLabel>
                          <Box width="200px" mt={5}>
                            <Select
                              placeholder="Select SS Level"
                              size="md"
                              value={reportCompiler.ssLevel}
                              bg={'white'}
                              required
                              onChange={(e: any) => {
                                setSsLevel(e.target.value);
                                setReportCompiler({
                                  ...reportCompiler,
                                  ssLevel: e.target.value,
                                });
                              }}
                            >
                              {sii_values.map((f: any, k: any) => (
                                <option value={f} key={k}>
                                  {f}
                                </option>
                              ))}
                            </Select>
                          </Box>
                        </FormControl>
                      </VStack>

                      <HStack>
                        {/* <Button
                          variant={'defaultButton'}
                          _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          my={10}
                          mr={5}
                        >
                          Back
                        </Button> */}
                        <Button
                          variant={'defaultButton'}
                          _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                          onClick={() => {
                            setActiveStep(activeStep + 1);
                          }}
                          my={10}
                          isDisabled={
                            reportCompiler.ciLevel === null || reportCompiler.ssLevel === null
                              ? true
                              : false
                          }
                        >
                          Next
                        </Button>
                      </HStack>
                    </Box>
                  )}

                  {activeStep === 5 && (
                    <Box
                      bg={'gray.100'}
                      p={10}
                      width={'100%'}
                      height={'100vh'}
                      borderRadius={20}
                      overflowY={'scroll'}
                    >
                      <Box w={'100%'}>
                        <Heading>Other observations</Heading>
                        <Box w={'100%'}>
                          {otherObservations &&
                            otherObservations?.map((i: any, k: any) => {
                              let val =
                                i.id === 'responseTime'
                                  ? responseTime.id
                                  : i.id === 'needForRepetitionOfStimuli'
                                  ? needForRepetitionOfStimuli.id
                                  : i.id === 'attentionDuringTesting'
                                  ? attentionDuringTesting.id
                                  : i.id === 'attitudesTowardsTesting'
                                  ? attitudesTowardsTesting.id
                                  : i.id === 'socialLanguageRegisterandQuantity'
                                  ? socialLanguageRegisterandQuantity.id
                                  : 1;
                              return (
                                <Box key={k} my={5}>
                                  <Text fontWeight={'bold'} fontSize={20} my={5}>
                                    {i.title}
                                  </Text>
                                  <RadioGroup
                                    onChange={(e: any) => {
                                      updateObservation(i.id, i.options[e - 1], e);
                                    }}
                                    value={val}
                                  >
                                    <Stack direction="row" width={'100%'}>
                                      {i?.options &&
                                        i?.options?.map((z: any, f: any) => {
                                          return (
                                            <Box backgroundColor={'white'} p={1} borderRadius={20}>
                                              <Radio value={z.id} key={f} mr={10} m={2}>
                                                <Text>{z.value}</Text>
                                              </Radio>
                                            </Box>
                                          );
                                        })}
                                    </Stack>
                                  </RadioGroup>
                                </Box>
                              );
                            })}
                        </Box>
                        <HStack>
                          <Button
                            variant={'defaultButton'}
                            _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                            onClick={() => {
                              setActiveStep(activeStep - 1);
                            }}
                            my={10}
                            mr={5}
                          >
                            Back
                          </Button>
                          <Button
                            variant={'defaultButton'}
                            _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                            onClick={() => {
                              setActiveStep(activeStep + 1);
                            }}
                            my={10}
                          >
                            Next
                          </Button>
                        </HStack>
                      </Box>
                    </Box>
                  )}

                  {activeStep === 6 && (
                    <Box
                      bg={'gray.100'}
                      p={10}
                      width={'100%'}
                      height={'100vh'}
                      borderRadius={20}
                      overflow={'scroll'}
                    >
                      <Heading>Additional notes</Heading>
                      <Text fontWeight={'bold'} fontSize={20} mt={5}>
                        Stammer or Fluent?
                      </Text>
                      <VStack>
                        <Box
                          w={'100%'}
                          mt={5}
                          display={'flex'}
                          flexDirection={'column'}
                          alignContent={'flex-start'}
                        >
                          <Stack
                            spacing={5}
                            direction="row"
                            alignItems={'flex-start'}
                            width={'50%'}
                          >
                            <Checkbox
                              colorScheme="green"
                              backgroundColor={'white'}
                              py={2.5}
                              px={5}
                              borderRadius={20}
                              onChange={(e: any) =>
                                setReportCompiler({
                                  ...reportCompiler,
                                  stammer: e.target.checked,
                                })
                              }
                            >
                              Stammer
                            </Checkbox>
                            <Checkbox
                              colorScheme="green"
                              backgroundColor={'white'}
                              py={2.5}
                              px={5}
                              borderRadius={20}
                              onChange={(e: any) =>
                                setReportCompiler({
                                  ...reportCompiler,
                                  fluent: e.target.checked,
                                })
                              }
                            >
                              Fluent
                            </Checkbox>
                          </Stack>
                          <Box></Box>
                          <Text fontWeight={'bold'} fontSize={20} mt={5}>
                            Add any additional notes below
                          </Text>
                          <Textarea
                            my={5}
                            py={2.5}
                            px={5}
                            borderRadius={20}
                            backgroundColor={'white'}
                            maxWidth={'75%'}
                            height={300}
                            onChange={(e: any) =>
                              setReportCompiler({
                                ...reportCompiler,
                                additionalNotes: e.target.value,
                              })
                            }
                          ></Textarea>
                        </Box>
                      </VStack>

                      <HStack>
                        <Button
                          variant={'defaultButton'}
                          _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          my={10}
                          mr={5}
                        >
                          Back
                        </Button>
                        <Button
                          variant={'defaultButton'}
                          _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                          onClick={() => {
                            // const fdItem = [
                            //   `FD : ${localStorage.getItem('fdScaleScore')}`,
                            //   Number(localStorage.getItem('fdCiMin')),
                            //   Number(localStorage.getItem('fdScaleScore')),
                            //   Number(localStorage.getItem('fdScaleScore')),
                            //   Number(localStorage.getItem('fdCiMax')),
                            // ];
                            // const fdItem = [
                            //   `FD : 14`,
                            //   Number(13),
                            //   Number(14),
                            //   Number(14),
                            //   Number(15),
                            // ];
                            // Number(localStorage.getItem('fdScaleScore')) > 0 &&
                            //   chartTwoCandleStickData.push(fdItem);
                            // console.log('chartTwoCandleStickData', chartTwoCandleStickData);
                            // setChartTwo(chartTwoCandleStickData);
                            setActiveStep(activeStep + 1);

                            // emptyArray = { rsid: 'none', ciMax: 0, ciMin: 0 };
                            // var arrFd =
                            //   localStorage.getItem('fd') !== undefined
                            //     ? JSON.parse(localStorage.getItem('fd'))
                            //     : emptyArray;

                            // const fdItem = [
                            //   `FD : ${arrFd.rsid}`,
                            //   Number(localStorage.getItem('sotssCLSCIMIN')),
                            //   Number(localStorage.getItem('sotssCLS')),
                            //   Number(localStorage.getItem('sotssCLS')),
                            //   Number(localStorage.getItem('sotssCLSCIMAX')),
                            // ];
                          }}
                          my={10}
                          mr={5}
                        >
                          Next
                        </Button>
                      </HStack>
                    </Box>
                  )}

                  {activeStep === 7 && (
                    <Box>
                      <Box overflow={'scroll'}>
                        <Box
                          bg={'gray.100'}
                          p={10}
                          width={'100%'}
                          height={'auto'}
                          borderRadius={20}
                          overflow={'scroll'}
                          mb={10}
                        >
                          <Heading>Test Scaled Scores</Heading>

                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th p={'2'} textAlign={'center'}></Th>
                                <Th p={'2'} textAlign={'center'}>
                                  Raw Score
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  Scaled Score
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  Scaled Score +/-
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  CI min % level
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  to
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  CI max % level
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  Percentile rank
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  PR CI
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  to
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  PR CI
                                </Th>
                                <Th p={'2'} textAlign={'center'}>
                                  Age Equivalent
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {reportCompilerOutput &&
                                reportCompilerOutput.map((i: any, k: any) => {
                                  const theRowRawScore = getFinaleScore(i.rsid);
                                  return (
                                    <TheRow
                                      title={i.title}
                                      rsidRow={i.rsid}
                                      ciLevel={ciLevel}
                                      rawScore={theRowRawScore}
                                      scaledScore={i.scaledScore}
                                      scaledScorePoints={i.ciFd}
                                      ciMin={i.ciMin}
                                      ciMax={i.ciMax}
                                      pr={i.prFd}
                                      prciFrom={i.prciFromFd}
                                      prciTo={i.prciToFd}
                                      testAgeEquivalent={i.testAge}
                                    />
                                  );
                                })}
                            </Tbody>
                          </Table>
                        </Box>
                        <Box mb={10} width={'140%'}>
                          <StepNine
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            reportCompiler={reportCompiler}
                            setReportCompiler={setReportCompiler}
                            reportCompilerOutput={reportCompilerOutput}
                            setReportCompilerOutput={setReportCompilerOutput}
                            standardScoreToUseFinal={standardScoreToUseFinal}
                            findStandardScoreWithinNestedObj={findStandardScoreWithinNestedObj}
                            findStandardScorePlusMinusPointsWithinNestedObj={
                              findStandardScorePlusMinusPointsWithinNestedObj
                            }
                            wcScaledScore={wcScaledScore}
                            fdScaledScore={fdScaledScore}
                            fsScaledScore={fsScaledScore}
                            rsScaledScore={rsScaledScore}
                            uspScaledScore={uspScaledScore}
                            wdScaledScore={wdScaledScore}
                            saScaledScore={saScaledScore}
                            srScaledScore={srScaledScore}
                            scScaledScore={scScaledScore}
                            lcScaledScore={lcScaledScore}
                            wsScaledScore={wsScaledScore}
                            clsSumOfScaledScore={clsSumOfScaledScore}
                            rliSumOfScaledScore={rliSumOfScaledScore}
                            eliSumOfScaledScore={eliSumOfScaledScore}
                            lciSumOfScaledScore={lciSumOfScaledScore}
                            lsiOrLmiSumOfScaledScore={lsiOrLmiSumOfScaledScore}
                            buildCandleStickOne={buildCandleStickOne}
                            setBuildCandleStickOne={setBuildCandleStickOne}
                            setChartOne={setChartOne}
                          />
                        </Box>
                        {/* 
                    Core Language &amp; Index Score Profile
                    Receptive Language (Understanding) scores : FD, SC, WC, SR, LC, USP
                    Expressive Language (Talking) scores: RS, FS, WS, SA 
                    */}

                        <Box
                          bg={'gray.100'}
                          p={10}
                          width={'100%'}
                          height={'auto'}
                          borderRadius={20}
                          mb={5}
                        >
                          <Box w={'100%'}>
                            <Heading>Disrepancy comparisons</Heading>
                            <TableContainer pt={'5'}>
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th></Th>
                                    <Th>Score 1</Th>
                                    <Th>Score 2</Th>
                                    <Th>Difference</Th>
                                    <Th>Critical Value</Th>
                                    <Th>
                                      Significant<br></br>Difference
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  <Tr>
                                    <Td>
                                      Receptive-Expressive<br></br>Language Index
                                    </Td>
                                    <Td>
                                      <Input
                                        bg={'white'}
                                        name="name"
                                        value={localStorage.getItem('sotssRLI')!}
                                      ></Input>
                                    </Td>
                                    <Td>
                                      <Input
                                        bg={'white'}
                                        name="name"
                                        value={localStorage.getItem('sotssELI')!}
                                      ></Input>
                                    </Td>
                                    <Td>
                                      <Input
                                        bg={'white'}
                                        name="name"
                                        value={
                                          Number(localStorage.getItem('sotssRLI')!) -
                                          Number(localStorage.getItem('sotssELI')!)
                                        }
                                      ></Input>
                                    </Td>
                                    <Td>
                                      <Input
                                        bg={'white'}
                                        name="name"
                                        value={localStorage.getItem('rliEli')!}
                                      ></Input>
                                    </Td>
                                    <Td>
                                      <Input
                                        bg={'white'}
                                        name="name"
                                        value={
                                          Math.abs(
                                            Number(localStorage.getItem('sotssRLI')!) -
                                              Number(localStorage.getItem('sotssELI')!)
                                          ) >= Number(localStorage.getItem('rliEli')!)
                                            ? 'Yes'
                                            : 'No'
                                        }
                                      ></Input>
                                    </Td>
                                  </Tr>
                                  {reportCompiler.ageYears >= 9 ? (
                                    <Tr>
                                      <Td>
                                        Language Content-<br></br>Memory Index
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={localStorage.getItem('sotssLCI')!}
                                        ></Input>
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={localStorage.getItem('sotssLMI')!}
                                        ></Input>
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={
                                            Number(localStorage.getItem('sotssLCI')!) -
                                            Number(localStorage.getItem('sotssLMI')!)
                                          }
                                        ></Input>
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={localStorage.getItem('lciLmi')!}
                                        ></Input>
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={
                                            Math.abs(
                                              Number(localStorage.getItem('sotssLCI')!) -
                                                Number(localStorage.getItem('sotssLMI')!)
                                            ) >= Number(localStorage.getItem('lciLmi')!)
                                              ? 'Yes'
                                              : 'No'
                                          }
                                        ></Input>
                                      </Td>
                                    </Tr>
                                  ) : (
                                    <Tr>
                                      <Td>
                                        Language Content-<br></br>Structure Index
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={localStorage.getItem('sotssLCI')!}
                                        ></Input>
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={localStorage.getItem('sotssLSI')!}
                                        ></Input>
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={
                                            Number(localStorage.getItem('sotssLCI')!) -
                                            Number(localStorage.getItem('sotssLSI')!)
                                          }
                                        ></Input>
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={localStorage.getItem('lciLsi')!}
                                        ></Input>
                                      </Td>
                                      <Td>
                                        <Input
                                          bg={'white'}
                                          name="name"
                                          value={
                                            Math.abs(
                                              Number(localStorage.getItem('sotssLCI')!) -
                                                Number(localStorage.getItem('sotssLSI')!)
                                            ) >= Number(localStorage.getItem('lciLsi')!)
                                              ? 'Yes'
                                              : 'No'
                                          }
                                        ></Input>
                                      </Td>
                                    </Tr>
                                  )}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>

                        <Box
                          // bg={'yellow'}
                          bg={'gray.100'}
                          p={10}
                          width={'100%'}
                          minHeight={'1400'}
                          borderRadius={20}
                          mb={5}
                          overflow={'scroll'}
                        >
                          <Heading as={'h4'} pb={5}>
                            Core Language &amp; Index Score Profile: Scores
                          </Heading>
                          <Box
                            width="800"
                            height="100vh"
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            textAlign={'center'}
                          >
                            <Box ref={ref} width={800} height={959}>
                              {/* imageUriChartOne:{JSON.stringify(imageUriChartOne)} */}
                              {chartEventsStateChartOne !== '' ? (
                                chartOne && (
                                  <Chart
                                    chartType="CandlestickChart"
                                    width="100%"
                                    height="100%"
                                    data={chartOne}
                                    options={optionsChartOne}
                                    ref={chartRef}
                                    chartEvents={chartEventsStateChartOne}
                                  />
                                )
                              ) : (
                                <Image src={reportFallbackTemplate}></Image>
                              )}
                            </Box>
                            <Box display={'none'}>
                              {chartEventsStateChartOne !== '' && (
                                <img src={imageUriChartOne} alt="Chart Image" />
                              )}
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          // bg={'yellow'}
                          bg={'gray.100'}
                          p={10}
                          width={'100%'}
                          minHeight={'1400'}
                          borderRadius={20}
                          mb={5}
                          overflow={'scroll'}
                        >
                          <Heading as={'h4'} pb={5}>
                            Receptive Language (Understanding) scores
                          </Heading>
                          <Box
                            width="800"
                            height="100vh"
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            textAlign={'center'}
                          >
                            <Box ref={ref} width={800} height={959}>
                              {/* {JSON.stringify(chartTwo)} */}
                              {/* imageUriChartTwo:{JSON.stringify(imageUriChartTwo)} */}
                              {chartEventsStateChartTwo !== '' ? (
                                chartTwo && (
                                  <Chart
                                    chartType="CandlestickChart"
                                    width="100%"
                                    height="100%"
                                    data={chartTwo}
                                    // data={chartTwo.forEach((subArray: any) => {
                                    //   subArray.shift(); // Remove the first element from the sub-array
                                    // })}
                                    options={optionsChartTwo}
                                    // ref={chartRef}
                                    chartEvents={chartEventsStateChartTwo}
                                  />
                                )
                              ) : (
                                <Image src={reportFallbackTemplate}></Image>
                              )}
                            </Box>
                            <Box display={'none'}>
                              {chartEventsStateChartTwo !== '' && (
                                <img src={imageUriChartTwo} alt="Chart Image" />
                              )}
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          // bg={'yellow'}
                          bg={'gray.100'}
                          p={10}
                          width={'100%'}
                          minHeight={'1400'}
                          borderRadius={20}
                          mb={5}
                          overflow={'scroll'}
                        >
                          <Heading as={'h4'} pb={5}>
                            Expressive Language (Talking) scores
                          </Heading>
                          <Box
                            width="800"
                            height="100vh"
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            textAlign={'center'}
                          >
                            <Box ref={ref} width={800} height={959}>
                              {chartEventsStateChartThree !== '' ? (
                                chartThree && (
                                  <Chart
                                    chartType="CandlestickChart"
                                    width="100%"
                                    height="100%"
                                    data={chartThree}
                                    options={optionsChartThree}
                                    ref={chartRef}
                                    chartEvents={chartEventsStateChartThree}
                                  />
                                )
                              ) : (
                                <Image src={reportFallbackTemplate}></Image>
                              )}
                            </Box>
                            <Box display={'none'}>
                              {chartEventsStateChartThree !== '' && (
                                <img src={imageUriChartThree} alt="Chart Image" />
                              )}
                            </Box>
                            {/* <Box bg="pink">
                          <Text>Here</Text>
                       
                          <Box>chartEvents:{JSON.stringify(chartEventsState)}</Box>
                          <Box>imageURI:{imageURI !== '' ? 'We got it' : 'No image yet'}</Box>
                          <Box>
                            <Button
                              variant="defaultButton"
                              onClick={() => {
                                getImageFromUriFromGoogleChart();
                              }}
                            >
                              Get image using imageURI
                            </Button>
                          </Box>
                          <Box></Box>
                        </Box> */}
                          </Box>
                          {/* <Box display={'none'}>
                        {imageScreenshot && (
                          <img width={'656px'} src={imageScreenshot} alt={'Screenshot'} />
                        )}
                      </Box> */}
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {activeStep === 8 && (
                    <Box
                      bg={'gray.100'}
                      p={10}
                      width={'100%'}
                      height={'100vh'}
                      borderRadius={20}
                      overflow={'scroll'}
                    >
                      <Heading mb={5}>Save & Upload Report</Heading>
                      {createReportButton === 1 && (
                        <Text w={'100%'} mb={5}>
                          Your CELF report calculations and scoring data is now ready. You can now
                          create a report
                        </Text>
                      )}
                      {secondLoading === 2 && (
                        <Text w={'100%'} mb={5}>
                          Your CELF report for <b>{reportCompiler?.studentName}</b> has been created
                          and uploaded to Google Drive.
                        </Text>
                      )}
                      <Box>
                        <>
                          <Box>
                            {createReportButton === 1 ? (
                              <>
                                <Button
                                  variant={'defaultButton'}
                                  _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                                  onClick={() => {
                                    postDataToEndPointCreateReport(
                                      passReportCompilerToMakeEPToCreateReport,
                                      requestBody
                                    )
                                      .then((data: any) => {
                                        setDataSent(true);
                                        setSecondLoading(2);
                                        setFileCreated(data.data);
                                        confetti({
                                          particleCount: 1000,
                                          spread: 120,
                                          origin: { x: 0.5, y: 0.7 },
                                        });
                                        console.log('here', data);
                                      })
                                      .catch((error) => {
                                        console.log('error', error);
                                        setSecondLoading(3);
                                      });
                                    setSecondLoading(1);
                                    setCreateReportButton(2);
                                  }}
                                  my={5}
                                  mr={5}
                                >
                                  Create report & Save to Google Drive
                                </Button>
                                {/* <Button
                                  variant={'defaultButton'}
                                  _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                                  onClick={() => {
                                    confetti({
                                      particleCount: 200,
                                      spread: 120,
                                      origin: { x: 0.5, y: 0.7 },
                                    });
                                  }}
                                  my={5}
                                  mr={5}
                                >
                                  Confetti
                                </Button> */}
                                {/* {localStorage.getItem('accountType') === 'Admin' ? (
                                  <Box>
                                    <Button
                                      variant={'defaultButton'}
                                      _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                                      onClick={() => {
                                        postDataToEndPointCreateReport(
                                          passReportCompilerToMakeEPToCreateReport,
                                          requestBody
                                        ).then((data: any) => {
                                          setDataSent(true);
                                          setSecondLoading(2);
                                          setFileCreated(data.data);
                                          console.log('here', data);
                                        });
                                        setSecondLoading(1);
                                        setCreateReportButton(2);
                                      }}
                                      my={10}
                                      mr={5}
                                      bg={'red'}
                                    >
                                      Send to Make Endpoint to make report AGAIN!
                                    </Button>
                                  </Box>
                                ) : (
                                  ''
                                )} */}
                              </>
                            ) : createReportButton === 0 ? (
                              <Box
                                width={'100%'}
                                alignContent={'center'}
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                py={200}
                              >
                                <Spinner
                                  thickness="8px"
                                  speed="0.65s"
                                  emptyColor="gray.200"
                                  color="brand.200"
                                  size="xl"
                                />
                                <Text>Creating charts and running calculations</Text>
                              </Box>
                            ) : (
                              ''
                            )}
                          </Box>
                          <Box>
                            {/* <Text>Report Compiler Object sent to Make Endpoint</Text> */}
                            {/* <Text>fileCreated: {JSON.stringify(fileCreated)}</Text> */}
                            {/* <Text>secondLoading: {JSON.stringify(secondLoading)}</Text> */}

                            <Box>
                              {secondLoading === 1 ? (
                                <Box
                                  width={'100%'}
                                  alignContent={'center'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  py={200}
                                >
                                  <Spinner
                                    thickness="8px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="brand.200"
                                    size="xl"
                                  />
                                  <Text>Creating CELF Report and saving to Google Drive...</Text>
                                </Box>
                              ) : secondLoading === 2 ? (
                                <HStack>
                                  <Link href={fileCreated} target={'_blank'}>
                                    <Button variant={'defaultButton'}>View report</Button>
                                  </Link>
                                  <Link href={'/reportingapp'}>
                                    <Button variant={'defaultButton'}>Create another report</Button>
                                  </Link>
                                </HStack>
                              ) : secondLoading === 3 ? (
                                <VStack alignItems={'start'}>
                                  <Alert status="error" borderRadius={10}>
                                    <AlertIcon />
                                    <AlertTitle>Report generation failed!</AlertTitle>
                                    <AlertDescription>
                                      Please try again and double check your raw scores
                                    </AlertDescription>
                                  </Alert>
                                  <Link href={'/reportingapp'} mt={5}>
                                    <Button variant={'defaultButton'}>Start again ...</Button>
                                  </Link>
                                </VStack>
                              ) : (
                                ''
                              )}
                            </Box>
                            <Box>
                              {localStorage.getItem('accountType') === 'Admin' ? (
                                <Box>
                                  <Button
                                    variant={'defaultButton'}
                                    _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                                    onClick={() => {
                                      postDataToEndPointCreateReport(
                                        passReportCompilerToMakeEPToCreateReport,
                                        requestBody
                                      ).then((data: any) => {
                                        setDataSent(true);
                                        setSecondLoading(2);
                                        setFileCreated(data.data);
                                        console.log('here', data);
                                      });
                                      setSecondLoading(1);
                                      setCreateReportButton(2);
                                    }}
                                    my={10}
                                    mr={5}
                                    bg={'red'}
                                  >
                                    Send to Make Endpoint to make report AGAIN!
                                  </Button>
                                </Box>
                              ) : (
                                ''
                              )}
                            </Box>
                          </Box>
                        </>
                      </Box>
                      {/* <Box>createReportButton:{createReportButton}</Box>
                    <Box>secondLoading:{secondLoading}</Box> */}

                      {/* <Box height={500} overflow={'scroll'} bg={'pink'} p={5}>
                      reportCompilerOutput:{JSON.stringify(reportCompilerOutput)}
                    </Box> */}
                      {/* <Box height={500} overflow={'scroll'} bg={'pink'} p={5}>
                      reportCompilerOutput:{JSON.stringify(reportCompilerOutput)}
                    </Box>
                    <Box height={500} overflow={'scroll'} bg={'lightblue'} p={5}>
                      <Text>sotssCLS: {localStorage.getItem('sotssCLS')}</Text>
                      <Text>sotssRLI: {localStorage.getItem('sotssRLI')}</Text>
                      <Text>sotssELI: {localStorage.getItem('sotssELI')}</Text>
                      <Text>sotssLCI: {localStorage.getItem('sotssLCI')}</Text>
                      <Text>sotssLMI: {localStorage.getItem('sotssLMI')}</Text>
                      <Text>sotssLSI: {localStorage.getItem('sotssLSI')}</Text>
                    </Box>
                    <Box height={500} overflow={'scroll'} bg={'orange'} p={5}>
                      reportCompiler:{JSON.stringify(reportCompiler)}
                    </Box> */}

                      {/* <HStack>
                      <Button
                        variant={'defaultButton'}
                        _hover={{ backgroundColor: 'brand.200', color: 'white' }}
                        onClick={() => {
                          setActiveStep(activeStep - 1);
                        }}
                        my={10}
                        mr={5}
                      >
                        Back
                      </Button>
                    </HStack> */}
                    </Box>
                  )}
                </Box>
              </VStack>
            </HStack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReportingContent;
