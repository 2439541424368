import React, { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  VStack,
  HStack,
  Text,
  Image,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { privateForm } from './../../interfaces/privateForm';
import ninoxTableGetRow from './../../utils/ninoxTableGetRow';
import directDebitLogo from './../../logo/directdebitlogo.jpg';
import postDataToEndPoint from './../../utils/postDataToEndPoint';
import ninoxTableUpdateRow from './../../utils/ninoxTableUpdateRow';
import { Link } from 'react-router-dom';
// import { gocardless } from 'gocardless-nodejs';
// const constants = require('gocardless-nodejs/constants');
// const gocardless = require('gocardless-nodejs');
// const client = gocardless('sandbox_tmW4W_voXRf93RFCCfytoXoda3Xe8_76jWl2UIIh', 'SANDBOX');

// import * as gocardless from 'gocardless-nodejs';
const queryParameters = new URLSearchParams(window.location.search);
const ul_cl_id = queryParameters.get('ul_cl');
const allClients = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;

interface FormProps {
  title?: string;
  form: privateForm;
  setForm?: Function;
  signContract: number;
  setSignContract: Function;
  defaultIndex: number;
  setDefaultIndex: Function;
  nextStep: Function;
  stepStatus: any;
  setStepStatus: Function;
}
const StepFour = ({
  title,
  form,
  signContract,
  setSignContract,
  defaultIndex,
  setDefaultIndex,
  nextStep,
  stepStatus,
  setStepStatus,
}: FormProps) => {
  // const client = gocardless('sandbox_tmW4W_voXRf93RFCCfytoXoda3Xe8_76jWl2UIIh', 'SANDBOX');
  // console.log(client);
  // customer: {
  //   email: "charlie@example.com",
  //   given_name: "Charlie",
  //   family_name: "Smith",
  // },
  // customer_billing_detail: {
  //   address_line1: "1 Buckhingham Palace road",
  //   city: "London",
  //   postal_code: "W1 8EE",
  //   country_code: "GB",
  // }
  const defaultCustomer = {
    customer: {
      email: 'charlie@example.com',
      given_name: 'Charlie',
      family_name: 'Smith',
    },
  };
  const defaultCustomerBillingDetails = {
    customer_billing_detail: {
      address_line1: '1 Buckhingham Palace road',
      city: 'London',
      postal_code: 'W1 8EE',
      country_code: 'GB',
    },
  };
  const defaultBankAccountDetails = {
    bank_account_detaisl: {
      account_number: '55779911',
      branch_code: '200000',
      account_holder_name: 'Charlie Smith',
      country_code: 'GB',
      metadata: {
        user_id: '1357',
      },
    },
  };
  const defaultGoCardlessData = {
    first_name: '',
    last_name: '',
    contact_number: '',
    email: '',
    account_number: '',
    sort_code: '',
    account_holder_name: '',
    address: '',
    city: '',
    country: '',
    post_code: '',
  };

  // https://pay.gocardless.com/AL0000VHXNBQJX

  // enum Environments {
  //     Live = 'LIVE',
  //     Sandbox = 'SANDBOX',
  //   }
  const customer_id = 1357;
  const customer_details = {
    email: 'datatest@unlockinglanguage.co.uk',
    given_name: 'Mark',
    family_name: 'Smith',
  };
  const customer_billing_detail = {
    address_line1: '1 Buckhingham Palace road',
    city: 'London',
    postal_code: 'W1 8EE',
    country_code: 'GB',
  };
  const customer_bank_account = {
    account_number: '55779911',
    branch_code: '200000',
    account_holder_name: 'Charlie Smith',
    country_code: 'GB',
    metadata: {
      user_id: '1357',
    },
  };
  const [customer, setCustomer] = useState<any>(defaultCustomer);
  const [customerBillingDetails, setCustomerBillingDetails] = useState<any>(
    defaultCustomerBillingDetails
  );
  const [bankDetails, setBankDetails] = useState<any>(defaultBankAccountDetails);
  const [goCardlessData, setGoCardlessData] = useState<any>(defaultGoCardlessData);
  const [gcDualSignature, setGcDualSignature] = useState<any>(0);
  const [gcDdPreCheck, setGcDdPreCheck] = useState<any>(0);
  const [ddLoading, setDdLoading] = useState<any>(0);
  const [ddErrorMsg, setDdErrorMsg] = useState<any>('');
  const toast = useToast();

  // Function to move to the next accordion item
  const handleNextAccordion = () => {
    console.log("We're going to step 5");
    setDefaultIndex(5);
  };
  const [directDebit, setDirectDebit] = useState<any>(0);
  const goCardlessEP = 'https://client-portal-serverless-functions.vercel.app/api/goCardless';
  function saveDirectDebit(data: any) {
    postDataToEndPoint(goCardlessEP, goCardlessData).then((gcData: any) => {
      console.log(gcData);
      // console.log('Response', gcData.response);
      // console.log('Status', gcData.status);

      if (gcData.statusCode !== 200) {
        // setDdErrorMsg('There was a problem in processing your Direct Debit. Please try again');
        toast({
          title: 'Direct Debit Error',
          description: 'There was a problem in processing your Direct Debit. Please try again',
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
        setGcDdPreCheck(0);
        setDdLoading(0);
      } else {
        setDirectDebit(1);
        setGcDdPreCheck(2);
        setDdLoading(0);
        toast({
          title: 'Thank you! ',
          description: (
            <Box>
              Your Direct Debit mandate has been set up successfully.<br></br>To complete the
              onboarding process, please make the <strong>First payment</strong> in the final step
            </Box>
          ),
          status: 'success',
          duration: null,
          isClosable: true,
        });
      }
    });
  }

  useEffect(() => {
    ninoxTableGetRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(ul_cl_id),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      // setGoCardlessData(data);
      console.log('THE SINGLE ROW: ', ul_cl_id, ': ', data);
      setDirectDebit(data?.fields['Gocardless active'] === true ? 1 : 0);
      setGoCardlessData({
        ...goCardlessData,
        first_name: data?.fields['First Name'],
        last_name: data?.fields['Last Name'],
        contact_number: data?.fields['Phone'],
        email: data?.fields['Primary Email Address'],
        account_number: '',
        sort_code: '',
        account_holder_name: '',
        address: '',
        city: '',
        country: '',
        post_code: '',
        user_id: ul_cl_id,
      });
    });
  }, []);
  const openThirdItem = () => {
    // setIndex([2]) // Open the third item (index 2)
    setDefaultIndex([3]);
  };

  return (
    <Box w="100%" height={'auto'} p={4}>
      <Box display={'flex'} flexDirection={'row'} bg={'gray.100'} borderRadius={10} p={5} mb={5}>
        <Box width="90%">
          <HStack>
            <Box width="75%">
              <Text>
                Your Direct Debit will be set up now, but we'll confirm the amount and let you know
                before future payments are taken.
              </Text>
            </Box>
            {/* <Box
                  width="15%"
                  display={'flex'}
                  flexDirection={'column'}
                  alignContent={'end'}
                  alignItems={'end'}
                >
                  <Image
                    width="150px"
                    objectFit="cover"
                    src={directDebitLogo}
                    alt="Direct Debit"
                    borderRadius={10}
                  />
                </Box> */}
          </HStack>
        </Box>
        <Box width="auto">
          {directDebit === 1 ? (
            ''
          ) : (
            <FormControl mt={1}>
              {Number(gcDualSignature) === 1 ? (
                <Box style={{ color: 'red' }}> We do not support dual signature accounts</Box>
              ) : Number(gcDdPreCheck) === 1 ? (
                <HStack>
                  <Button variant={'defaultButton'} onClick={() => setGcDdPreCheck(0)}>
                    Go Back
                  </Button>
                  {ddLoading === 0 ? (
                    <Button
                      variant={'defaultButton'}
                      onClick={() => {
                        setDdLoading(1);
                        saveDirectDebit(goCardlessData);
                      }}
                    >
                      Confirm Direct Debit
                    </Button>
                  ) : (
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  )}
                </HStack>
              ) : Number(gcDdPreCheck) === 2 ? (
                ''
              ) : (
                // <Button variant={'defaultButton'} onClick={() => saveDirectDebit(goCardlessData)}>
                //   Go to next step
                // </Button>'
                Number(gcDdPreCheck) === 0 && (
                  <Button variant={'defaultButton'} onClick={() => setGcDdPreCheck(1)}>
                    Proceed with Direct Debit
                  </Button>
                )
              )}
            </FormControl>
          )}
        </Box>
      </Box>
      {Number(gcDdPreCheck) === 1 ? (
        <>
          <Box width="100%">
            <Text fontWeight={'bold'}>Check your details are correct</Text>
            <HStack
              display={'flex'}
              alignContent={'start'}
              justifyContent={'start'}
              alignItems={'start'}
            >
              <VStack width={'30%'} p={'1.5%'}>
                <FormControl mb="5%">
                  <FormLabel htmlFor="first_name" fontWeight={'bold'}>
                    First name
                  </FormLabel>
                  <Text>{goCardlessData?.first_name}</Text>
                </FormControl>

                <FormControl mb="5%">
                  <FormLabel htmlFor="first_name" fontWeight={'bold'}>
                    Last name
                  </FormLabel>
                  <Text>{goCardlessData?.last_name}</Text>
                </FormControl>
                <FormControl mb="5%">
                  <FormLabel htmlFor="contact_number" fontWeight={'bold'}>
                    Contact number
                  </FormLabel>
                  <Text>{goCardlessData?.contact_number}</Text>
                </FormControl>
                <FormControl mb="5%">
                  <FormLabel htmlFor="email" fontWeight={'bold'}>
                    Email
                  </FormLabel>
                  <Text>{goCardlessData?.email}</Text>
                </FormControl>
              </VStack>
              <VStack width={'30%'} p={'1.5%'}>
                <FormControl mb="5%">
                  <FormLabel htmlFor="address" fontWeight={'bold'}>
                    Address
                  </FormLabel>
                  <Text>{goCardlessData?.address}</Text>
                </FormControl>
                <FormControl mb="5%">
                  <FormLabel htmlFor="city" fontWeight={'bold'}>
                    City
                  </FormLabel>
                  <Text>{goCardlessData?.city}</Text>
                </FormControl>
                <FormControl mb="5%">
                  <FormLabel htmlFor="postcode" fontWeight={'bold'}>
                    Post code
                  </FormLabel>
                  <Text>{goCardlessData?.post_code}</Text>
                </FormControl>
                <FormControl mb="5%">
                  <FormLabel htmlFor="country" fontWeight={'bold'}>
                    Country
                  </FormLabel>
                  <Text>{goCardlessData?.country}</Text>
                </FormControl>
              </VStack>
              <VStack width={'30%'} p={'1.5%'}>
                <FormControl mb="5%">
                  <FormLabel htmlFor="account_number" fontWeight={'bold'}>
                    Account number
                  </FormLabel>
                  <Text>{goCardlessData?.account_number}</Text>
                </FormControl>
                <FormControl mb="5%">
                  <FormLabel htmlFor="sort_code" fontWeight={'bold'}>
                    Sort code
                  </FormLabel>
                  <Text>{goCardlessData?.sort_code}</Text>
                </FormControl>

                <FormControl mb="5%">
                  <FormLabel htmlFor="email" fontWeight={'bold'}>
                    Account holder name
                  </FormLabel>
                  <Text>{goCardlessData?.account_holder_name}</Text>
                </FormControl>

                {/* <FormControl mb="5%">
                  <FormLabel htmlFor="email" fontWeight={'bold'}>
                    Direct debit approval
                  </FormLabel>

                  <Checkbox
                    onChange={(e: any) =>
                      gcDualSignature === 0 ? setGcDualSignature(1) : setGcDualSignature(0)
                    }
                  >
                    Is more than one person is required to authorise Direct Debits?
                  </Checkbox>
                </FormControl> */}
              </VStack>
            </HStack>

            <Box fontWeight={'bold'}>
              You may cancel this Direct Debit at any time by contacting Unlocking Language or your
              bank.
            </Box>
            <Box mt={5}>
              <Text fontWeight={'bold'}>Business Information</Text>
              <UnorderedList>
                <ListItem>Unlocking Language</ListItem>
                <ListItem>020 7536 9299</ListItem>
                <ListItem>info@@unlockinglanguage.co.uk</ListItem>
                <ListItem>unlocking language ltd</ListItem>
              </UnorderedList>
            </Box>
          </Box>
        </>
      ) : Number(gcDdPreCheck) === 2 ? (
        <Box>
          <Text fontWeight={'bold'}>
            Thank you! Your Direct Debit mandate has been set up successfully.
          </Text>
          <Text>
            You will receive a confirmation notification via email within the next 3 business days
            from the date of setup, detailing the specifics of your Direct Debit
          </Text>
          <Text fontWeight={'bold'} mt={5} borderRadius={10} color={'black'}>
            Please complete the last step for the first payment.
          </Text>
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-end'}
            mt={5}
          >
            <Button
              variant={'defaultButton'}
              mt={5}
              onClick={() => {
                nextStep(4);
                ninoxTableUpdateRow(
                  allClients!,
                  Number(ul_cl_id),
                  {
                    fields: {
                      'Meta Upload': true,
                    },
                  },
                  process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                ).then(() => {
                  setStepStatus({ ...stepStatus, stepFour: true });
                });
              }}
              // isDisabled={directDebit === 1 ? false : true}
              _hover={{ backgroundColor: 'brand.200' }}
            >
              Next
            </Button>
          </Box>
          {/* <Button variant={'defaultButton'} mt={5} onClick={() => setDefaultIndex(5)}>
            Go to next step
          </Button> */}
        </Box>
      ) : (
        <Box width={'100%'} height={'auto'}>
          {directDebit === 1 ? (
            <Box color={'blue.500'} fontWeight={'bold'}>
              We have already received your direct debit details, you can now proceed to the next
              step
            </Box>
          ) : (
            <>
              <HStack
                display={'flex'}
                alignContent={'start'}
                justifyContent={'start'}
                alignItems={'start'}
              >
                <VStack width={'30%'} p={'1.5%'}>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="first_name" fontWeight={'bold'}>
                      First name
                    </FormLabel>
                    <Input
                      id="first_name"
                      placeholder="First name"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, first_name: e.target.value })
                      }
                      value={goCardlessData?.first_name}
                      // value={goCardlessData?.fields['First Name']}
                    />
                  </FormControl>

                  <FormControl mb="5%">
                    <FormLabel htmlFor="last_name" fontWeight={'bold'}>
                      Last name
                    </FormLabel>
                    <Input
                      id="last_name"
                      placeholder="Last name"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, last_name: e.target.value })
                      }
                      value={goCardlessData?.last_name}
                    />
                  </FormControl>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="contact_number" fontWeight={'bold'}>
                      Contact number
                    </FormLabel>
                    <Input
                      id="contact_number"
                      placeholder="Contact number"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, contact_number: e.target.value })
                      }
                      value={goCardlessData?.contact_number}
                    />
                  </FormControl>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="email" fontWeight={'bold'}>
                      Email
                    </FormLabel>
                    <Input
                      id="email"
                      placeholder="Email"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, email: e.target.value })
                      }
                      value={goCardlessData?.email}
                    />
                  </FormControl>
                </VStack>
                <VStack width={'30%'} p={'1.5%'}>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="address" fontWeight={'bold'}>
                      Address
                    </FormLabel>
                    <Input
                      id="address"
                      placeholder="Address"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, address: e.target.value })
                      }
                      value={goCardlessData?.address}
                    />
                  </FormControl>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="city" fontWeight={'bold'}>
                      City
                    </FormLabel>
                    <Input
                      id="city"
                      placeholder="City"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, city: e.target.value })
                      }
                      value={goCardlessData?.city}
                    />
                  </FormControl>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="postcode" fontWeight={'bold'}>
                      Post code
                    </FormLabel>
                    <Input
                      id="post_code"
                      placeholder="Post code"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, post_code: e.target.value })
                      }
                      value={goCardlessData?.post_code}
                    />
                  </FormControl>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="country" fontWeight={'bold'}>
                      Country
                    </FormLabel>
                    <Input
                      id="country"
                      placeholder="Country"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, country: e.target.value })
                      }
                      value={goCardlessData?.country}
                    />
                  </FormControl>
                </VStack>
                <VStack width={'30%'} p={'1.5%'}>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="account_number" fontWeight={'bold'}>
                      Account number
                    </FormLabel>
                    <Input
                      id="account_number"
                      placeholder="Account number"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, account_number: e.target.value })
                      }
                      value={goCardlessData?.account_number}
                      type="number"
                    />
                  </FormControl>
                  <FormControl mb="5%">
                    <FormLabel htmlFor="sort_code" fontWeight={'bold'}>
                      Sort code
                    </FormLabel>
                    <Input
                      id="sort_code"
                      placeholder="Sort code"
                      onChange={(e) =>
                        setGoCardlessData({ ...goCardlessData, sort_code: e.target.value })
                      }
                      value={goCardlessData?.sort_code}
                      type="number"
                      maxLength={6}
                    />
                  </FormControl>

                  <FormControl mb="5%">
                    <FormLabel htmlFor="email" fontWeight={'bold'}>
                      Account holder name
                    </FormLabel>
                    <Input
                      id="email"
                      placeholder="Account holder name"
                      onChange={(e) =>
                        setGoCardlessData({
                          ...goCardlessData,
                          account_holder_name: e.target.value,
                        })
                      }
                      value={goCardlessData?.account_holder_name}
                      type="text"
                    />
                  </FormControl>

                  <FormControl mb="5%">
                    <FormLabel htmlFor="email" fontWeight={'bold'}>
                      Direct debit approval
                    </FormLabel>

                    <Checkbox
                      onChange={(e: any) =>
                        gcDualSignature === 0 ? setGcDualSignature(1) : setGcDualSignature(0)
                      }
                    >
                      Is more than one person is required to authorise Direct Debits?
                    </Checkbox>
                  </FormControl>
                </VStack>
              </HStack>
              <Box fontStyle={'italic'}>
                Payments securely processed by GoCardless. GoCardless Ltd (company registration
                number 07495895) is authorised by the Financial Conduct Authority under the Payment
                Services Regulations 2017, registration number 597190, for the provision of payment
                services. GoCardless uses personal data as described in their{' '}
                <Text fontWeight={'bold'}>
                  <Link to="https://gocardless.com/legal/privacy/" target="_blank">
                    Privacy Notice.
                  </Link>
                </Text>
                <Box mt={10}>
                  <HStack>
                    <Box width="75%">
                      <Text fontWeight={'bold'}>Direct Debit Guarantee</Text>
                    </Box>
                    <Box
                      width="15%"
                      display={'flex'}
                      flexDirection={'column'}
                      alignContent={'end'}
                      alignItems={'end'}
                    >
                      <Image
                        width="150px"
                        objectFit="cover"
                        src={directDebitLogo}
                        alt="Direct Debit"
                        borderRadius={10}
                        mb={10}
                      />
                    </Box>
                  </HStack>
                  <UnorderedList>
                    <ListItem>
                      This Guarantee is offered by all banks and building societies that accept
                      instructions to pay Direct Debits.
                    </ListItem>
                    <ListItem>
                      If there are any changes to the amount, date or interval of your Direct Debit
                      GoCardless will notify you 3 working days in advance of your account being
                      debited or as otherwise agreed. If you request GoCardless Ltd to collect a
                      payment, confirmation of the amount and date will be given to you at the time
                      of the request.
                    </ListItem>
                    <ListItem>
                      If an error is made in the payment of your Direct Debit, by GoCardless Ltd or
                      your bank or building society, you are entitled to a full and immediate refund
                      of the amount paid from your bank or building society - If you receive a
                      refund you are not entitled to, you must pay it back when GoCardless Ltd asks
                      you to.
                    </ListItem>
                    <ListItem>
                      You can cancel a Direct Debit at any time by simply contacting your bank or
                      building society. Written confirmation may be required. Please also notify us.
                    </ListItem>
                  </UnorderedList>
                </Box>
              </Box>
            </>
          )}

          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-end'}
            mt={5}
          >
            <HStack>
              <Button
                variant={'defaultButton'}
                mt={5}
                onClick={() => nextStep(2)}
                isDisabled={directDebit === 1 ? false : true}
                _hover={{ backgroundColor: 'brand.200' }}
              >
                Back
              </Button>
              <Button
                variant={'defaultButton'}
                mt={5}
                onClick={() => {
                  nextStep(4);
                  ninoxTableUpdateRow(
                    allClients!,
                    Number(ul_cl_id),
                    {
                      fields: {
                        'Gocardless active': true,
                      },
                    },
                    process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                  ).then(() => {
                    setStepStatus({ ...stepStatus, stepFour: true });
                  });
                }}
                isDisabled={directDebit === 1 ? false : true}
                _hover={{ backgroundColor: 'brand.200' }}
              >
                Next
              </Button>
            </HStack>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default StepFour;
