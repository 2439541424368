import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    Link,
    Textarea,
    
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { BackButton } from './BackButton';

const txFirstName =
localStorage.getItem('firstName') !== undefined ? localStorage.getItem('firstName') : '';
const txLastName =
localStorage.getItem('lastName') !== undefined ? localStorage.getItem('lastName') : '';

const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;


const today = new Date();
const currentMonth = today.getMonth() + 1; // JS months are 0-based


// ✅ Determine the current term based on the month
const currentTerm =
currentMonth >= 9 ? "Autumn" : currentMonth >= 1 && currentMonth <= 4 ? "Spring" : "Summer";



// API function to fetch data
const fetchStudentTargets = async (sessionId: number, schoolType: string) => {
    try {
        // ✅ Step 1: Get list of IDs (API endpoint depends on schoolType)
        const baseApiUrl = schoolType === "RegularSchool"
        ? "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/f38343wjq310"
        : "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/wgwsf2vlwfr9";
        
        const query = encodeURIComponent(
            `concat((select 'Progress Tracker')[Students = ${sessionId}].Id order by Year)`
        );
        
        const apiUrl = `${baseApiUrl}/query?query=${query}`;
        
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearerToken || ""
                , // Injects token dynamically
            },
        });
        
        if (!response.ok) {
            throw new Error(`Failed to fetch IDs: ${response.statusText}`);
        }
        
        const idString = await response.text(); // Get raw string (e.g., "12, 13, 14")
        console.log("Fetched IDs:", idString);
        
        if (!idString.trim()) {
            return []; // Return empty array if no IDs found
        }
        
        // ✅ Step 2: Fetch details for each ID
        const ids = idString.includes(",")
        ? idString.split(",").map(id => id.replace(/\D/g, "").trim()) // ✅ Remove non-numeric characters and split
        : [idString.replace(/\D/g, "").trim()];  // ✅ Single ID: Ensure clean number format
        
        const targetDetails = await Promise.all(ids.map(id => fetchTargetDetails(id, schoolType)));
        
        return targetDetails.filter(Boolean); // Remove null results
    } catch (error) {
        console.error('Error fetching student targets:', error);
        return [];
    }
};

// ✅ Fetch details of a single target (with dynamic schoolType)
const fetchTargetDetails = async (id: string, schoolType: string) => {
    try {
        const baseApiUrl = schoolType === "RegularSchool"
        ? "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/f38343wjq310/tables/LB"
        : "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/wgwsf2vlwfr9/tables/GB";
        
        const detailUrl = `${baseApiUrl}/records/${id}`;
        
        const response = await fetch(detailUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearerToken || "",
            },
        });
        
        if (!response.ok) {
            console.error(`Failed to fetch details for ID ${id}: ${response.statusText}`);
            return { id, fields: {} }; // ✅ Return a safe object with an empty fields object
        }
        
        const data = await response.json();
        return data && data.fields ? data : { id, fields: {} }; // ✅ Ensure a valid structure
    } catch (error) {
        console.error(`Error fetching details for ID ${id}:`, error);
        return { id, fields: {} }; // ✅ Return a safe object even in case of an error
    }
};






const TargetsschoolsinglePage = ({
    signedInUserId,
    signedInUserEmail,
    userMeta,
}: SignedInUserProps) => {
    // ✅ Navigation and URL Parameters
    const navigate = useNavigate();
    // ✅ Ensure sessionId is always a string using fallback value ""
    const { sessionId } = useParams<{ sessionId?: string }>(); // Allow optional sessionId
    const safeSessionId = sessionId ?? ""; // If undefined, default to an empty string
    
    const [searchParams] = useSearchParams();
    const schoolType = searchParams.get('schoolType');
    
    // ✅ State Hooks
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [studentTargets, setStudentTargets] = useState<any[]>([]);
    // ✅ Determine the correct academic year based on today’s term
    const determineDefaultAcademicYear = () => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Months are 0-based
        
        // ✅ Autumn remains in the same year, but Spring and Summer belong to the previous year
        const currentYear = today.getFullYear();
        return currentMonth >= 9
        ? `${currentYear}/${currentYear + 1}` // Autumn Term (New Academic Year)
        : `${currentYear - 1}/${currentYear}`; // Spring & Summer (Previous Academic Year)
    };
    
    // ✅ Set the default selected year dynamically
    const [selectedYear, setSelectedYear] = useState(determineDefaultAcademicYear());
    
    const [filteredTargets, setFilteredTargets] = useState<any[]>([]);
    
    
    
    
    const [groupMatchingData, setGroupMatchingData] = useState<Record<string, string>>({});
    // ✅ Fetch "Group Matching" details after the main target details are loaded
    const fetchGroupMatchingDetails = async (targets: any[], schoolType: string, sessionId: string) => {
        if (!sessionId) {
            console.error("Error: sessionId is required but was not provided.");
            return;
        }
        
        const updatedData: Record<string, string> = {};
        
        // ✅ Determine API base URL dynamically (remove trailing slash to avoid "//")
        const baseApiUrl = schoolType === "RegularSchool"
        ? "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/f38343wjq310"
        : "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/wgwsf2vlwfr9";
        
        await Promise.all(targets.map(async (target) => {
            
            if (!target.id) return;
            
            // ✅ Use different queries based on school type
            const query = schoolType === "RegularSchool"
            ? encodeURIComponent(`text(let final := "";
let individualtartarget := number(${target.id});
let todayterm := if month(today()) >= 9 and month(today()) <= 12 then
		1
	else
		if month(today()) >= 1 and month(today()) <= 4 then
			2
		else
			3
		end
	end;
let todayyear := year(today());
let allTargets := (select 'Progress Tracker')[number(Term) = todayterm and Year = todayyear];
concat(allTargets);
let matchedStudents := [""];
let result := [""];
for t1 in allTargets[Id = individualtartarget] do
	let targetText1 := lower(text(t1.Target));
	let student1 := t1.Students.'Student/Patient Name';
	let count := 0;
	for t2 in allTargets do
		let targetText2 := lower(text(t2.Target));
		if t1 != t2 then
			let words1 := split(targetText1, " ");
			let words2 := split(targetText2, " ");
			let matchCount := 0;
			for w1 in words1 do
				if contains(targetText2, w1) then
					matchCount := matchCount + 1
				end
			end;
			let totalWords := length(words1) + length(words2);
			let similarity := (matchCount * 2) / totalWords;
			if similarity >= 0.9 then
				count := count + 1;
				matchedStudents := array(matchedStudents, [t2.Students.'Student/Patient Name'])
			end
		end
	end;
	if count > 1 then
		let entry := "Target: " + targetText1 + " | Students with similar targets: " +
			concat(matchedStudents, ", ");
		if not contains(result, entry) then
			result := array(result, [entry])
		end
	end
end;
let Formula1 := concat(result);
let firstfind := index(Formula1, first((select 'Progress Tracker')[Id = individualtartarget].lower(text(Target))));
let slicedtext := slice(Formula1, firstfind, length(Formula1));
let endfind := index(slicedtext, "Target:");
if length(slice(Formula1, firstfind, endfind)) = 0 then
	"No group matching"
else
	let formula2 := slice(Formula1, firstfind, endfind);
	let begin := index(formula2, "|");
	final := text(slice(formula2, begin + 2, length(formula2))); final
end;
)`)
                : encodeURIComponent(
                    `text(let final := "";
let individualtartarget := number(${target.id});
let todayterm := if month(today()) >= 9 and month(today()) <= 12 then
		1
	else
		if month(today()) >= 1 and month(today()) <= 4 then
			2
		else
			3
		end
	end;
let todayyear := year(today());
let allTargets := (select 'Progress Tracker')[number(Term) = todayterm and Year = todayyear];
concat(allTargets);
let matchedStudents := [""];
let result := [""];
for t1 in allTargets[Id = individualtartarget] do
	let targetText1 := lower(text(t1.Target));
	let student1 := t1.Students.'Student/Patient Name';
	let count := 0;
	for t2 in allTargets do
		let targetText2 := lower(text(t2.Target));
		if t1 != t2 then
			let words1 := split(targetText1, " ");
			let words2 := split(targetText2, " ");
			let matchCount := 0;
			for w1 in words1 do
				if contains(targetText2, w1) then
					matchCount := matchCount + 1
				end
			end;
			let totalWords := length(words1) + length(words2);
			let similarity := (matchCount * 2) / totalWords;
			if similarity >= 0.9 then
				count := count + 1;
				matchedStudents := array(matchedStudents, [t2.Students.'Student/Patient Name'])
			end
		end
	end;
	if count > 1 then
		let entry := "Target: " + targetText1 + " | Students with similar targets: " +
			concat(matchedStudents, ", ");
		if not contains(result, entry) then
			result := array(result, [entry])
		end
	end
end;
let Formula1 := concat(result);
let firstfind := index(Formula1, first((select 'Progress Tracker')[Id = individualtartarget].lower(text(Target))));
let slicedtext := slice(Formula1, firstfind, length(Formula1));
let endfind := index(slicedtext, "Target:");
if length(slice(Formula1, firstfind, endfind)) = 0 then
	"No group matching"
else
	let formula2 := slice(Formula1, firstfind, endfind);
	let begin := index(formula2, "|");
	final := text(slice(formula2, begin + 2, length(formula2))); final
end;
)` // ✅ Now sessionId is always safe
                );
                
                try {
                    const detailUrl = `${baseApiUrl}/query?query=${query}`;
                    
                    const response = await fetch(detailUrl, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": bearerToken || "",
                        },
                    });
                    
                    if (response.ok) {
                        let data = await response.text(); // ✅ Read response as text
                        data = data.trim(); // ✅ Remove unnecessary spaces
                        
                        // ✅ Remove surrounding quotes if they exist
                        if (data.startsWith('"') && data.endsWith('"')) {
                            data = data.slice(1, -1);
                        }
                        // ✅ Remove extra commas at the end
                        data = data.replace(/,\s*$/, ""); // ✅ Removes trailing commas (", ," or ",,")
                        // ✅ Ensure empty results are replaced with "No group matching"
                        updatedData[target.id] = data || "No group matching";
                    } else {
                        console.error(`Failed to fetch Group Matching for ID ${target.id}: ${response.statusText}`);
                    }
                } catch (error) {
                    console.error(`Error fetching Group Matching for ID ${target.id}:`, error);
                }
            }));
            
            setGroupMatchingData(updatedData);
        };
        
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [selectedTarget, setSelectedTarget] = useState<any | null>(null);
        
        
        
        const [isEditing, setIsEditing] = useState(false);
        const [updatedTarget, setUpdatedTarget] = useState<any | null>(null);
        
        // ✅ Open modal in view mode
        const openModal = (target: any) => {
            setSelectedTarget(target);
            setUpdatedTarget({ ...target.fields }); // ✅ Store a copy for editing
            setIsEditing(false);
            setIsModalOpen(true);
        };
        
        // ✅ Enable edit mode
        const enableEditMode = () => {
            setIsEditing(true);
        };
        
        // ✅ Handle input changes in edit mode
        const handleInputChange = (field: string, value: string | boolean) => {
            setUpdatedTarget((prev: any) => ({
                ...prev,
                [field]: value,
            }));
        };
        
        
        // ✅ Close modal & reset state
        const closeModal = () => {
            setIsModalOpen(false);
            setIsEditing(false);
            setSelectedTarget(null);
            setUpdatedTarget(null);
        };
        
        
        
        // ✅ Fetch student targets when the page loads
        useEffect(() => {
            const loadStudentData = async () => {
                if (!sessionId || !schoolType) {
                    setError("No session ID or school type provided.");
                    return;
                }
                
                setLoading(true);
                setError(null);
                
                try {
                    const data = await fetchStudentTargets(Number(sessionId), schoolType);
                    console.log("Final API Data:", data);
                    
                    if (Array.isArray(data)) {
                        setStudentTargets(data);
                        
                        // ✅ Extract available academic years from API data
                        const availableYears = new Set(
                            data.flatMap(target => {
                                const year = Number(target.fields["Year"]) || 0;
                                const term = target.fields["Term"] || "";
                                
                                if (term === "Autumn") {
                                    return `${year}/${year + 1}`;
                                } else if (term === "Spring" || term === "Summer") {
                                    return `${year - 1}/${year}`;
                                }
                                return [];
                            })
                        );
                        
                        // ✅ Get today's academic year
                        const today = new Date();
                        const currentMonth = today.getMonth() + 1;
                        const currentYear = today.getFullYear();
                        const todayAcademicYear = currentMonth >= 9
                        ? `${currentYear}/${currentYear + 1}`  // Autumn → Current Year
                        : `${currentYear - 1}/${currentYear}`; // Spring/Summer → Previous Year
                        
                        // ✅ If today's academic year is available, set it; otherwise, pick the first available
                        if (availableYears.has(todayAcademicYear)) {
                            setSelectedYear(todayAcademicYear);
                        } else if (availableYears.size > 0) {
                            setSelectedYear([...availableYears][0]); // Pick the first available year
                        }
                    } else {
                        setError("Unexpected API response format.");
                    }
                } catch (error) {
                    console.error("Error in loadStudentData:", error);
                    setError("Failed to load student targets.");
                }
                
                setLoading(false);
            };
            
            loadStudentData();
        }, [sessionId, schoolType]); // ✅ Ensure it runs when data is updated
        
        
        
        
        useEffect(() => {
            if (studentTargets.length > 0) {
                const filteredData = studentTargets.filter(target => {
                    const targetYear = Number(target.fields["Year"]) || 0;
                    const term = target.fields["Term"] || "";
                    
                    let academicYear;
                    if (term === "Autumn") {
                        academicYear = `${targetYear}/${targetYear + 1}`;
                    } else if (term === "Spring" || term === "Summer") {
                        academicYear = `${targetYear - 1}/${targetYear}`;
                    } else {
                        return false;
                    }
                    
                    return selectedYear === "" || academicYear === selectedYear;
                });
                
                console.log("Filtered Targets Before Setting:", filteredData.map(t => ({
                    ID: t.id,
                    Term: t.fields["Term"],
                    Year: t.fields["Year"],
                })));
                
                setFilteredTargets(filteredData);
            }
        }, [selectedYear, studentTargets]);
        
        
        
        
        
        
        
        const termOrder: Record<string, number> = { "Summer": 1, "Spring": 2, "Autumn": 3 };
        
        const sortedTargets = [...filteredTargets].sort((a, b) => {
            const yearA = Number(a.fields["Year"]) || 0;
            const yearB = Number(b.fields["Year"]) || 0;
            const termA = a.fields["Term"] || "Unknown";
            const termB = b.fields["Term"] || "Unknown";
            
            // ✅ Ensure that Summer is not being treated as a new row but belongs to Spring
            const displayYearA = termA === "Autumn" ? yearA : yearA + 1;
            const displayYearB = termB === "Autumn" ? yearB : yearB + 1;
            
            // ✅ First, sort by academic year (newest first)
            if (displayYearA !== displayYearB) {
                return displayYearB - displayYearA;
            }
            
            // ✅ Then, reverse order of terms within each year (Summer → Spring → Autumn)
            return (termOrder[termA] ?? 99) - (termOrder[termB] ?? 99);
        });
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        // ✅ Fetch "Group Matching" details **after** fetching target details
        useEffect(() => {
            if (studentTargets.length > 0 && schoolType && safeSessionId) {
                fetchGroupMatchingDetails(studentTargets, schoolType, safeSessionId);
            }
        }, [studentTargets, schoolType, safeSessionId]); // ✅ Now safeSessionId is always a string
        
        const [isSaving, setIsSaving] = useState(false);
        
        const saveUpdatedTarget = async () => {
            if (!selectedTarget) return;
            
            setIsSaving(true); // ✅ Show loading state
            
            // ✅ Determine API base URL dynamically
            const baseApiUrl = schoolType === "RegularSchool"
            ? "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/f38343wjq310/tables/LB"
            : "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/wgwsf2vlwfr9/tables/GB";
            
            const updateUrl = `${baseApiUrl}/records/${selectedTarget.id}`;
            
            try {
                const response = await fetch(updateUrl, {
                    method: "PUT", // ✅ PATCH method to update existing data
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": bearerToken || ""
                        ,
                    },
                    body: JSON.stringify({
                        fields: updatedTarget, // ✅ Correct structure: { "fields": { key-value pairs } }
                        
                    }), // ✅ Send updated target data
                });
                console.log(updatedTarget)
                
                if (response.ok) {
                    console.log("Target updated successfully");
                    toast({
                        title: "Success",
                        description: "Target updated successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    
                    setIsEditing(false); // ✅ Exit edit mode
                    closeModal(); // ✅ Close modal
                    refreshData(); // ✅ Fetch new data after update
                } else {
                    throw new Error(`Failed to update: ${response.statusText}`);
                }
            } catch (error) {
                console.error("Error updating target:", error);
                toast({
                    title: "Error",
                    description: `Failed to update target`,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSaving(false);
            }
        };
        
        const toast = useToast(); // ✅ Initialize toast inside the component
        
        
        
        const refreshData = async () => {
            if (!sessionId || !schoolType) return;
            
            setLoading(true);
            setError(null);
            
            try {
                // ✅ Delay before the API call
                await new Promise(resolve => setTimeout(resolve, 2000));
                
                const data = await fetchStudentTargets(Number(sessionId), schoolType);
                console.log("Refreshed API Data:", data);
                
                if (Array.isArray(data)) {
                    setStudentTargets(data);
                } else {
                    setError("Unexpected API response format.");
                }
            } catch (error) {
                console.error("Error refreshing data:", error);
                setError("Failed to refresh student targets.");
            } finally {
                setLoading(false); // ✅ Ensure loading is disabled even if an error occurs
            }
        };
        
        
        
        const [newTarget, setNewTarget] = useState({
            Term: "",
            Year: "",
            Direct: "",
            "TA Support": false,
            Type: "",
            "End of Term Progress": "",
            Target: "",
            Comments: "",
            "Resource URLs": "",
        });
        
        const [isSubmitting, setIsSubmitting] = useState(false);
        
        // ✅ Handle input changes for new target form
        const handleNewTargetChange = (field: string, value: string | boolean | number) => {
            setNewTarget((prev) => ({
                ...prev,
                [field]: value,
            }));
        };
        
        
        // ✅ API Call to Add New Target
        const addNewTarget = async () => {
            if (!sessionId || !schoolType) return;
            
            setIsSubmitting(true);
            
            // ✅ Determine API base URL dynamically
            const baseApiUrl = schoolType === "RegularSchool"
            ? "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/f38343wjq310/tables/LB"
            : "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/wgwsf2vlwfr9/tables/GB";
            
            const addUrl = `${baseApiUrl}/records`;
            
            try {
                const response = await fetch(addUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": bearerToken || "",
                    },
                    
                    body: JSON.stringify({
                        fields: {
                            ...newTarget,
                            Year: newTarget.Year ? Number(newTarget.Year) : null, // ✅ Ensure Year is a number or null
                            Direct: newTarget.Direct ? Number(newTarget.Direct) : null, // ✅ Ensure Direct is a number or null
                            Students: sessionId, // ✅ Add Student ID
                            
                        },
                    }),
                });
                console.log(JSON.stringify({
                    fields: {
                        ...newTarget,
                        Year: Number(newTarget.Year), // Ensure Year is a number
                        Students: sessionId, // ✅ Add Student ID
                    }
                }))
                if (response.ok) {
                    toast({
                        title: "Success",
                        description: "New target added successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    
                    const createdRecord = await response.json();
                    const recordId = createdRecord.id; // ✅ Capture the new record's ID
                    
                    // ✅ Step 2: Immediately Update the Year
                    const updateUrl = `${baseApiUrl}/records/${recordId}`;
                    const updateResponse = await fetch(updateUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": bearerToken || ""
                            ,
                        },
                        body: JSON.stringify({
                            fields: {
                                Year: Number(newTarget.Year), // ✅ Send the correct year
                            },
                        }),
                    });
                    
                    setNewTarget({ // ✅ Reset form after successful submission
                        Term: "",
                        Year: "",
                        Direct: "",
                        "TA Support": false,
                        Type: "",
                        "End of Term Progress": "",
                        Target: "",
                        Comments: "",
                        "Resource URLs": "",
                    });
                    
                    refreshData(); // ✅ Fetch updated data
                } else {
                    throw new Error(`Failed to add target: ${response.statusText}`);
                }
            } catch (error) {
                console.error("Error adding new target:", error);
                toast({
                    title: "Error",
                    description: "Failed to add new target",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }
        };
        
        
        const isFormValid = newTarget.Term && newTarget.Year && newTarget.Type && newTarget.Target.trim() !== "";
        
        const currentYear = new Date().getFullYear();
        const yearOptions = Array.from({ length: 16 }, (_, i) => currentYear - 2 + i); // Generates from 5 years ago to 10 years ahead
        
        const fetchStudentName = async (sessionId: number, schoolType: string) => {
            try {
                // ✅ Determine API base URL based on school type
                const baseApiUrl = schoolType === "RegularSchool"
                ? "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/f38343wjq310/tables/CB"
                : "https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/wgwsf2vlwfr9/tables/BB";
                
                // ✅ Construct API URL
                const studentUrl = `${baseApiUrl}/records/${sessionId}`;
                
                const response = await fetch(studentUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": bearerToken || "" || ""
                        ,
                    },
                });
                
                if (!response.ok) {
                    throw new Error(`Failed to fetch student: ${response.statusText}`);
                }
                
                const data = await response.json();
                return data.fields["Student/Patient Name"] || "Unknown Student"; // ✅ Extract student name
            } catch (error) {
                console.error("Error fetching student name:", error);
                return "Unknown Student"; // ✅ Fallback in case of an error
            }
        };
        const [studentName, setStudentName] = useState<string | null>(null);
        
        useEffect(() => {
            const loadStudentName = async () => {
                if (!sessionId || !schoolType) return;
                
                const name = await fetchStudentName(Number(sessionId), schoolType);
                setStudentName(name);
            };
            
            loadStudentName();
        }, [sessionId, schoolType]); // ✅ Runs when sessionId or schoolType changes
        
        
// ✅ Compute Summary Statistics before rendering JSX
const computeSummaryStatistics = (targets: any[]) => {
    if (!targets.length) return { totalTargets: 0, progressCounts: {}, typesByTerm: {}, progressByTerm: {} };

    const totalTargets = targets.length;

    // ✅ Initialize progress tracking per term
    const progressByTerm: Record<string, Record<string, number>> = { Autumn: {}, Spring: {}, Summer: {} };
    const typesByTerm: Record<string, string[]> = { Autumn: [], Spring: [], Summer: [] };
    const progressCounts: Record<string, number> = { Autumn: 0, Spring: 0, Summer: 0 };

    targets.forEach(target => {
        const term = target.fields["Term"] || "Unknown";
        const progress = target.fields["End of term Progress"] || "Unknown";
        const type = target.fields["Type"] || "Unknown";

        // ✅ Count total targets per term
        if (term) {
            progressCounts[term] = (progressCounts[term] || 0) + 1;

            // ✅ Track progress by term
            progressByTerm[term][progress] = (progressByTerm[term][progress] || 0) + 1;

            // ✅ Track unique types per term
            if (!typesByTerm[term].includes(type)) {
                typesByTerm[term].push(type);
            }
        }
    });

    return { totalTargets, progressCounts, typesByTerm, progressByTerm };
};

// ✅ Compute statistics from filtered targets
const { totalTargets, progressCounts, typesByTerm, progressByTerm } = computeSummaryStatistics(filteredTargets);

        
const typeIcons: Record<string, string> = {
    "Attention and Listening": "👂",
    "Receptive Language": "🗣️",
    "Expressive Language": "📝",
    "Speech": "🗨️",
    "Social Skills": "🤝"
  };
  

    
        
        
        
        return (
            <>
            <Box ml={{ base: 0, md: 20 }} p="6">
            <Box p={6} bg="white">
            {/* ✅ Header Section */}
            <Box p={2} mb={5}>
            <HStack spacing={5} justifyContent="space-between">
            <BackButton navigate={navigate} />
            <Heading pb={6} mt={6} width={'100%'} textAlign="left">
            Student Targets: {loading ? 'Loading...' : studentName || sessionId}
            </Heading>
            
            <Link href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQX4lPhg70RIl5HZJi5fJeb3YElsdpGHRrQrQ4S_qaURCI7MW0k_o0dOQtscONxSNxyPhOTqFwh-MqA/pubhtml?gid=558722167&single=true" target="_blank">
            <Button bg={'brand.300'} color={'white'} _hover={{ background: 'brand.200' }}>
            Target Examples
            </Button>
            </Link>
            </HStack>
            </Box>
            
            {/* ✅ Show Summary Only If Data Exists */}
            {filteredTargets.length > 0 && (
                <Box mb={5} p={5} borderRadius="md" boxShadow="lg" bg="gray.50">
                <Heading size="md" mb={4} textAlign="center" color="brand.600">
    📊 Summary Statistics for academic year {selectedYear || "N/A"}
</Heading>

                
                {/* ✅ Total Targets Box */}
                <Flex justify="center" mb={4}>
                <Box p={4} bg="blue.100" color="blue.800" fontWeight="bold" borderRadius="md" border={"2px solid blue" } boxShadow="md">
                🎯 <Text as="span">Total Targets:</Text> {computeSummaryStatistics(filteredTargets)?.totalTargets || 0}
                </Box>
                </Flex>
                
                {/* ✅ Horizontal Layout for Terms */}
                <Flex justify="center" gap={6} mt={6}>
  {["Autumn", "Spring", "Summer"].map((term) => (
    <Box
      key={term}
      p={5}
      w="30%"
      bg={term === "Autumn" ? "orange.100" : term === "Spring" ? "green.100" : "yellow.100"}
      border={`2px solid ${term === "Autumn" ? "orange" : term === "Spring" ? "green" : "yellow"}`}
      borderRadius="lg"
      boxShadow="lg"
    >
      {/* Term Title */}
      <Heading size="md" color="gray.800" mb={2} textAlign="center">
        {term === "Autumn" ? "🍂 Autumn Term" : term === "Spring" ? "🌱 Spring Term" : "☀️ Summer Term"}
      </Heading>

      {/* Targets Count */}
      <Text fontWeight="bold" color="red.600" mb={2}>
        📌 Targets: <strong>{progressCounts[term] || 0}</strong>
      </Text>

      {/* Types with Icons */}
      <Text fontWeight="bold" color="gray.700">📂 Types:</Text>
      <Flex wrap="wrap" gap={2} mt={1}>
        {typesByTerm[term]?.length > 0 ? (
          typesByTerm[term].map((type) => (
            <Text key={type} bg="blue.200" px={2} py={1} borderRadius="md" fontSize="sm" fontWeight="bold">
            {typeIcons[type] || "🔹"} {type}
          </Text>
          
          ))
        ) : (
          <Text color="gray.500">No types recorded</Text>
        )}
      </Flex>

      {/* Progress Breakdown */}
      <Text fontWeight="bold" color="blue.700" mt={2}>📊 Progress:</Text>
      <Flex wrap="wrap" gap={2} mt={1}>
        {Object.entries(progressByTerm[term] || {}).length > 0 ? (
          Object.entries(progressByTerm[term]).map(([status, count]) => (
            <Text
              key={status}
              bg={
                status === "Achieved"
                  ? "green.200"
                  : status === "Working towards"
                  ? "orange.200"
                  : status === "Not met"
                  ? "red.200"
                  : status === "Not targeted"
                  ? "yellow.200"
                  : "gray.300"
              }
              px={2}
              py={1}
              borderRadius="md"
              fontSize="sm"
              fontWeight="bold"
            >
              {status === "Achieved" ? "✅" : status === "Working towards" ? "🔄" : status === "Not met" ? "❌" : status === "Not targeted" ? "⚠️" : "🚫"}{" "}
              {status}: {count}
            </Text>
          ))
        ) : (
          <Text color="gray.500">No progress recorded</Text>
        )}
      </Flex>
    </Box>
  ))}
</Flex>



                </Box>
            )}
            
            
            
            
            
            
            {/* ✅ Display Loading or Error Messages */}
            {loading && <Text>Loading student targets...</Text>}
            {error && <Text color="red.500">{error}</Text>}
            
            {!loading && !error && (
                <Flex>
                {/* ✅ Left-Side Form */}
                <Box w={"20%"} p={"5"} mt={10} borderRadius="lg" bg="gray.50" boxShadow="md"  overflow="hidden">
{/* ✅ Target Examples Button at the Top */}
<Flex justify={{ base: "center", md: "flex-start" }} width="100%">
  <Link
    href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQX4lPhg70RIl5HZJi5fJeb3YElsdpGHRrQrQ4S_qaURCI7MW0k_o0dOQtscONxSNxyPhOTqFwh-MqA/pubhtml?gid=558722167&single=true"
    target="_blank"
    style={{ display: "flex", justifyContent: "center", width: "100%" }}
  >
   <Button
      bg={'brand.300'}
      color={'white'}
      _hover={{ background: 'brand.200' }}
      width="100%" // ✅ Ensure it takes full width inside container
      maxWidth="250px" // ✅ Prevents button from getting too wide
      px={4}
      py={3} // ✅ Add vertical padding to prevent cutting
      minHeight="auto" // ✅ Ensures button adjusts height dynamically
      fontSize={{ base: "sm", md: "md" }}
      textAlign="center"
      whiteSpace="normal" // ✅ Allows text to wrap
      wordBreak="break-word" // ✅ Prevents text from overflowing
      lineHeight="1.4" // ✅ Ensures better text readability
    >
      📂 View Target Examples
    </Button>
  </Link>
</Flex>


                <Heading size="md" mb={4} textAlign="center" p={4} // ✅ Adds padding on all sides

  borderRadius="md" // ✅ (Optional) Adds rounded corners
  >Add New Target Form</Heading>
                
                {/* ✅ Term Dropdown */}
                <FormControl mb={3}>
                <FormLabel><strong>Term:</strong></FormLabel>
                <Select value={newTarget.Term} onChange={(e) => handleNewTargetChange("Term", e.target.value)}   bg="white"
      borderColor="gray.300"
      _hover={{ borderColor: "gray.400" }}>
                <option value="">Select Term</option>
                <option value="Autumn">🍂 Autumn</option>
      <option value="Spring">🌱 Spring</option>
      <option value="Summer">☀️ Summer</option>
                </Select>
                </FormControl>
                
                {/* ✅ Year Input (Number) */}
                <FormControl mb={3}>
                <FormLabel><strong>Year:</strong></FormLabel>
                <Select
                value={newTarget.Year ? String(newTarget.Year) : ""} // ✅ Display as a string for the dropdown
                onChange={(e) => handleNewTargetChange("Year", Number(e.target.value))} // ✅ Convert back to number
                bg="white"
      borderColor="gray.300"
      _hover={{ borderColor: "gray.400" }}>
                <option value="">Select Year</option> {/* ✅ Default empty option */}
                {yearOptions.map((year) => (
                    <option key={year} value={year}>{year}</option>
                ))}
                </Select>
                </FormControl>
                
                
                
                
                {/* ✅ Direct Input (Number) */}
                <FormControl mb={3}>
                <FormLabel><strong>Direct:</strong></FormLabel>
                <Input type="number" value={newTarget.Direct} onChange={(e) => handleNewTargetChange("Direct", e.target.value)} bg="white"
      borderColor="gray.300"
      _hover={{ borderColor: "gray.400" }}/>
                </FormControl>
                
                {/* ✅ TA Support Dropdown (Boolean) */}
                <FormControl mb={3}>
                <FormLabel><strong>TA Support:</strong></FormLabel>
                <Select value={newTarget["TA Support"] ? "true" : "false"} onChange={(e) => handleNewTargetChange("TA Support", e.target.value === "true")} bg="white"
      borderColor="gray.300"
      _hover={{ borderColor: "gray.400" }}>
                <option value="true">Yes</option>
                <option value="false">No</option>
                </Select>
                </FormControl>
                
                {/* ✅ Type Dropdown (Options should match modal) */}
                <FormControl mb={3}>
                <FormLabel><strong>Type:</strong></FormLabel>
                <Select value={newTarget.Type} onChange={(e) => handleNewTargetChange("Type", e.target.value)} bg="white"
      borderColor="gray.300"
      _hover={{ borderColor: "gray.400" }}>
                <option value="">Select Type</option>
                <option value="Attention and Listening">👂 Attention & Listening</option>
  <option value="Receptive Language">🗣️ Receptive Language</option>
  <option value="Expressive Language">📝 Expressive Language</option>
  <option value="Speech">💬 Speech</option>
  <option value="Social Skills">🤝 Social Skills</option>
                </Select>
                </FormControl>
                
                
                {/* ✅ End of Term Progress Dropdown (Options should match modal) */}
                {/* <FormControl mb={3}>
                    <FormLabel><strong>End of Term Progress:</strong></FormLabel>
                    <Select value={newTarget["End of Term Progress"]} onChange={(e) => handleNewTargetChange("End of Term Progress", e.target.value)}>
                    <option value="">Select Progress</option>
                    <option value="Achieved">Achieved</option>
                    <option value="Working towards">Working towards</option>
                    <option value="Not met">Not met</option>
                    <option value="Not targeted">Not targeted</option>
                    <option value="Discontinued">Discontinued</option>
                    </Select>
                    </FormControl> */}
                    
                    {/* ✅ Target Textarea */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Target:</strong></FormLabel>
                    <Textarea value={newTarget.Target} onChange={(e) => handleNewTargetChange("Target", e.target.value)} size="md" bg="white"
      borderColor="gray.300"
      _hover={{ borderColor: "gray.400" }}/>
                    </FormControl>
                    
                    {/* ✅ Comments Textarea */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Comments:</strong></FormLabel>
                    <Textarea value={newTarget.Comments} onChange={(e) => handleNewTargetChange("Comments", e.target.value)} size="md" bg="white"
      borderColor="gray.300"
      _hover={{ borderColor: "gray.400" }}/>
                    </FormControl>
                    {/* ✅ Resource URLs Input */}
<FormControl mb={3}>
  <FormLabel><strong>Resource URLs:</strong></FormLabel>
  <Textarea
    value={newTarget["Resource URLs"]}
    onChange={(e) => handleNewTargetChange("Resource URLs", e.target.value)}
    placeholder="Enter comma-separated URLs"
    size="md"
    bg="white"
    borderColor="gray.300"
    _hover={{ borderColor: "gray.400" }}
  />
</FormControl>

                    
                    {/* ✅ Submit Button */}
                    <Button
                    colorScheme="blue"
                    onClick={addNewTarget}
                    isLoading={isSubmitting}
                    isDisabled={!isFormValid} // ✅ Disable button if required fields are empty
                    width="100%"
                    >
                    Add Target
                    </Button>
                    
                    
                    </Box>
                    
                    
                    
                    {/* ✅ Right-Side Table with Filters */}
                    
                    <Box w={'80%'} h={'85vh'} overflowY={'scroll'} p={'5'} borderRadius={'5'}>
                    
                    
                    <Flex justifyContent={'flex-end'} alignItems={'right'}>
                    <Box mt={8}> <HStack><Button
                    bg={'brand.300'}
                    color={'white'}
                    _hover={{
                        background: 'brand.200',
                    }}
                    onClick={() => {
                        navigate('/therapist-session/' + sessionId + '?schoolType=' + schoolType);
                    }}
                    mr={5}
                    >
                    Case Notes
                    </Button>
                    <Button
                    bg={'brand.300'}
                    color={'white'}
                    _hover={{
                        background: 'brand.200',
                    }}
                    onClick={() => {
                        navigate('/edit-student/' + sessionId + '?schoolType=' + schoolType);
                    }}
                    mr={5}
                    >
                    Manage
                    </Button>
                    <Button
                    bg={'brand.300'}
                    color={'white'}
                    _hover={{
                        background: 'brand.200',
                    }}
                    onClick={() => {
                        navigate('/toms-rating/' + sessionId + '?schoolType=' + schoolType);
                    }}
                    mr={5}
                    >
                    TOMS
                    </Button>
                    
                    
                    {/* ✅ Filter Section */}
                    
                    {/* ✅ Academic Year Filter with Default Selection */}
                    <FormControl width="200px" mb={4}>
                    <FormLabel fontWeight="bold">Select Academic Year</FormLabel>
                    <Select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    width="200px"
                    >
                    {Array.from(
                        new Set(
                            studentTargets
                            .flatMap(target => {
                                const year = Number(target.fields["Year"]) || 0;
                                const term = target.fields["Term"] || "";
                                
                                // ✅ Ensure Spring and Summer contribute to academic year calculations
                                if (term === "Autumn") {
                                    return [`${year}/${year + 1}`];
                                } else if (term === "Spring" || term === "Summer") {
                                    return [`${year - 1}/${year}`]; // ✅ Ensure previous year is included
                                }
                                return [];
                            })
                        )
                    )
                    .sort((a, b) => b.localeCompare(a))
                    .map((academicYear, index) => (
                        <option key={index} value={academicYear}>{academicYear}</option>
                    ))}
                    </Select>
                    
                    </FormControl>
                    </HStack>
                    
                    
                    
                    
                    
                    
                    </Box>     </Flex>
                    
                    {/* ✅ Filtered Table */}
                    <TableContainer className="studentTargetTableContainer" height="80vh" overflowY="scroll">
                    <Table variant="striped" colorScheme="green">
                    <Thead position="sticky" top={0} left={0} zIndex={10} bg="white">
                    <Tr>
                    <Th minWidth="120px" maxWidth="150px">Term</Th>
                    <Th minWidth="100px" maxWidth="120px">Year</Th>
                    <Th minWidth="80px" maxWidth="100px">Direct</Th>
                    <Th minWidth="100px" maxWidth="130px">TA Support</Th>
                    <Th minWidth="150px" maxWidth="180px">Type</Th>
                    <Th minWidth="200px" maxWidth="220px">End of Term Progress</Th>
                    <Th minWidth="250px" maxWidth="280px">Target</Th>
                    <Th minWidth="250px" maxWidth="300px">Comments</Th>
                    <Th minWidth="250px" maxWidth="300px">Resource URLs</Th>
                    <Th minWidth="120px" maxWidth="150px"> {`Group Matching - ${currentTerm} ${currentYear}`} </Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {sortedTargets.length > 0 ? (
                        sortedTargets.reduce((acc, target, index) => {
                            const currentTerm = target.fields["Term"] || "N/A";
                            const actualYear = target.fields["Year"] || "N/A";
                            
                            // ✅ Sorting uses `displayYear`, but UI shows `actualYear`
                            const displayYear = currentTerm === "Autumn" ? Number(actualYear) : Number(actualYear) + 1;
                            
                            const previousTerm = index > 0 ? sortedTargets[index - 1].fields["Term"] : null;
                            const previousYear = index > 0 ? sortedTargets[index - 1].fields["Year"] : null;
                            const previousDisplayYear = previousTerm === "Autumn" ? Number(previousYear) : Number(previousYear) + 1;
                            
                            // ✅ Only insert separator if term OR academic year changes
                            if (previousDisplayYear !== displayYear || previousTerm !== currentTerm) {
                                acc.push(
                                    <Tr key={`separator-${currentTerm}-${displayYear}`} bg="gray.400" pointerEvents="none">
                                    <Td colSpan={10} textAlign="left" fontWeight="bold" fontSize="lg" p={3}>
                                    {`${currentTerm} Term ${actualYear}`} {/* ✅ Show actual stored year */}
                                    </Td>
                                    </Tr>
                                );
                            }
                            
                            // ✅ Push the actual row data
                            acc.push(
                                <Tr key={index} cursor="pointer" _hover={{ bg: "gray.100" }} onClick={() => openModal(target)}>
                                <Td>{target.fields["Term"] || "N/A"}</Td>
                                <Td>{target.fields["Year"] || "N/A"}</Td> {/* ✅ Show the true year */}
                                <Td>{target.fields["Direct"] || "N/A"}</Td>
                                <Td>{target.fields["TA Support"] ? "Yes" : "No"}</Td>
                                <Td>{target.fields["Type"] || "N/A"}</Td>
                                <Td>{target.fields["End of term Progress"] || "N/A"}</Td>
                                <Td whiteSpace="pre-line">{target.fields["Target"] || "N/A"}</Td>
                                <Td whiteSpace="pre-line">{target.fields["Comments"] || "N/A"}</Td>
                                <Td whiteSpace="pre-line">
  {target.fields["Resource URLs"]
    ? target.fields["Resource URLs"]
        .split(",") // ✅ Split multiple URLs (if needed)
        .map((url: string, i: number)=> (
            <Box key={i}> {/* ✅ Wrap in a Box to handle spacing */}
            <Link href={url.trim()} color="blue.500" isExternal>
              {url.trim()}
            </Link>
            <br /> {/* ✅ Ensures each link appears on a new line */}
          </Box>
        ))
    : "N/A"}
</Td>

                                <Td>{groupMatchingData[target.id] || "No group matching"}</Td>
                                </Tr>
                            );
                            
                            return acc;
                        }, [])
                    ) : (
                        <TableCaption>No Student Targets found!</TableCaption>
                    )}
                    </Tbody>
            
                    </Table>
                    </TableContainer>
                    </Box>
                    </Flex>
                )}
                </Box>
                </Box>
                
                <Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>{isEditing ? "Edit Target Details" : "Target Details"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                {selectedTarget ? (
                    <Box>
                    {/* ✅ Show red warning text if OLD target is true */}
                    {selectedTarget.fields["OLD target"] === true && (
                        <Text color="red.500" fontWeight="bold" mb={3}>
                        ⚠ This is an old target as the end of term progress has been set.
                        </Text>
                    )}
                    
                    {/* ✅ Note ID - Read-only Field */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Target ID:</strong></FormLabel>
                    <Text>{selectedTarget?.id || "N/A"}</Text> {/* ✅ Display Note ID */}
                    </FormControl>
                    
                    {/* ✅ Term Dropdown */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Term:</strong></FormLabel>
                    {isEditing ? (
                        <Select value={updatedTarget?.["Term"] || ""} onChange={(e) => handleInputChange("Term", e.target.value)}>
                        <option value="Autumn">Autumn</option>
                        <option value="Spring">Spring</option>
                        <option value="Summer">Summer</option>
                        </Select>
                    ) : (
                        <Text>{selectedTarget.fields["Term"] || "N/A"}</Text>
                    )}
                    </FormControl>
                    
                    {/* ✅ Year Number Input */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Year:</strong></FormLabel>
                    {isEditing ? (
                        <Select value={updatedTarget?.["Year"] || ""} onChange={(e) => handleInputChange("Year", e.target.value)}>
                        <option value="">Select Year</option> {/* ✅ Default empty option */}
                        {yearOptions.map((year) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                        </Select>
                    ) : (
                        <Text>{selectedTarget.fields["Year"] || "N/A"}</Text>
                    )}
                    </FormControl>
                    
                    
                    {/* ✅ Direct Number Input */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Direct:</strong></FormLabel>
                    {isEditing ? (
                        <Input
                        type="number"
                        value={updatedTarget?.["Direct"] || ""}
                        onChange={(e) => handleInputChange("Direct", e.target.value)}
                        />
                    ) : (
                        <Text>{selectedTarget.fields["Direct"] || "N/A"}</Text>
                    )}
                    </FormControl>
                    
                    
                    {/* ✅ TA Support Dropdown - Sends Boolean Value */}
                    <FormControl mb={3}>
                    <FormLabel><strong>TA Support:</strong></FormLabel>
                    {isEditing ? (
                        <Select
                        value={updatedTarget?.["TA Support"] === true ? "true" : "false"} // ✅ Convert boolean to string for UI
                        onChange={(e) => handleInputChange("TA Support", e.target.value === "true")} // ✅ Send boolean
                        >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                        </Select>
                    ) : (
                        <Text>{selectedTarget.fields["TA Support"] ? "Yes" : "No"}</Text> // ✅ Convert boolean to readable text
                    )}
                    </FormControl>
                    
                    
                    
                    {/* ✅ Type Dropdown */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Type:</strong></FormLabel>
                    {isEditing ? (
                        <Select value={updatedTarget?.["Type"] || ""} onChange={(e) => handleInputChange("Type", e.target.value)}>
                        <option value="">Select Type</option>
                        <option value="Attention and Listening">Attention and Listening</option>
                        <option value="Receptive Language">Receptive Language</option>
                        <option value="Expressive Language">Expressive Language</option>
                        <option value="Speech">Speech</option>
                        <option value="Social Skills">Social Skills</option>
                        </Select>
                    ) : (
                        <Text>{selectedTarget.fields["Type"] || "N/A"}</Text>
                    )}
                    </FormControl>
                    
                    
                    <FormControl mb={3}>
                    <FormLabel><strong>End of Term Progress:</strong></FormLabel>
                    {isEditing ? (
                        <Select
                        value={updatedTarget?.["End of term Progress"] || ""}
                        onChange={(e) => handleInputChange("End of term Progress", e.target.value)}
                        >
                        <option value="">Select Progress</option> {/* ✅ Default empty option */}
                        <option value="Achieved">Achieved</option>
                        <option value="Working towards">Working towards</option>
                        <option value="Not met">Not met</option>
                        <option value="Not targeted">Not targeted</option>
                        <option value="Discontinued">Discontinued</option>
                        </Select>
                    ) : (
                        <Text>{selectedTarget.fields["End of term Progress"] || "N/A"}</Text>
                    )}
                    </FormControl>
                    
                    
                    {/* ✅ Target Field - Preserve Line Breaks */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Target:</strong></FormLabel>
                    {isEditing ? (
                        <Textarea
                        value={updatedTarget?.["Target"] || ""}
                        onChange={(e) => handleInputChange("Target", e.target.value)}
                        size="md"
                        />
                    ) : (
                        <Text whiteSpace="pre-line">{selectedTarget.fields["Target"] || "N/A"}</Text>
                    )}
                    </FormControl>
                    
                    {/* ✅ Comments Field - Preserve Line Breaks */}
                    <FormControl mb={3}>
                    <FormLabel><strong>Comments:</strong></FormLabel>
                    {isEditing ? (
                        <Textarea
                        value={updatedTarget?.["Comments"] || ""}
                        onChange={(e) => handleInputChange("Comments", e.target.value)}
                        size="md"
                        />
                    ) : (
                        <Text whiteSpace="pre-line">{selectedTarget.fields["Comments"] || "N/A"}</Text>
                    )}
                    </FormControl>
                    
                    {/* ✅ Resource URLs Field */}
<FormControl mb={3}>
  <FormLabel><strong>Resource URLs:</strong> <Text>Enter comma-separated URLs</Text></FormLabel>
  {isEditing ? (
    <Textarea
      value={updatedTarget?.["Resource URLs"] || ""}
      onChange={(e) => handleInputChange("Resource URLs", e.target.value)}
      size="md"
      placeholder="Enter comma-separated URLs"
    />
  ) : (
    selectedTarget.fields["Resource URLs"] ? (
      selectedTarget.fields["Resource URLs"].split(",").map((url: string, i: number)  => (
        <Box key={i}> {/* ✅ Wrap in a Box to handle spacing */}
        <Link href={url.trim()} color="blue.500" isExternal>
          {url.trim()}
        </Link>
        <br /> {/* ✅ Ensures each link appears on a new line */}
      </Box>
      ))
    ) : (
      <Text>N/A</Text>
    )
  )}
</FormControl>

                    
                    </Box>
                ) : (
                    <Text>Loading...</Text>
                )}
                </ModalBody>
                <ModalFooter>
                {/* ✅ Show red warning in place of "Edit" button if OLD target is true */}
                {selectedTarget?.fields["OLD target"] === true ? (
                    <Text color="red.500" fontWeight="bold" mr={3}>
                    ⚠ This is an old target as the end of term progress has been set.
                    </Text>
                ) : (
                    !isEditing && (
                        <Button colorScheme="blue" mr={3} onClick={enableEditMode}>
                        Edit
                        </Button>
                    )
                )}
                
                {/* ✅ Show "Save" button when in edit mode */}
                {isEditing && (
                    <Button colorScheme="green" mr={3} onClick={saveUpdatedTarget} isLoading={isSaving}>
                    Save
                    </Button>
                )}
                
                <Button variant="ghost" onClick={closeModal}>Close</Button>
                </ModalFooter>
                </ModalContent>
                </Modal>
                
                
                
                </>
                
                
            );
            
        };
        
        export default TargetsschoolsinglePage;
        