import {
    Box,
    Button,
    Flex,
    HStack,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    Link,
    Tag,
    FormControl,
    FormLabel,
    Select,


} from '@chakra-ui/react';


import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { BackButton } from './BackButton';

const SchoolSummarysinglePage = ({
    signedInUserId,
    signedInUserEmail,
    userMeta,
}: SignedInUserProps) => {
    // ✅ Navigation & URL Parameters
    const navigate = useNavigate();
    const { sessionId } = useParams<{ sessionId?: string }>();
    const [searchParams] = useSearchParams();
    const schoolType = searchParams.get('schoolType');

    // ✅ State Hooks (Dummy Data)
    // Dummy data structure
    const students: Student[] = [
        {
            id: 1,
            name: "John Doe",
            academicYear: "2024/2025",
            totalTargets: 10,
            terms: {
                autumn: {
                    targets: 3,
                    types: ["Receptive Language", "Speech", "Social Skills"],
                    progress: { "Achieved": 2, "Working towards": 1, "Not met": 0, "Unknown": 0, "Not targeted": 0 }
                },
                spring: {
                    targets: 4,
                    types: ["Expressive Language", "Social Skills"],
                    progress: { "Achieved": 1, "Working towards": 2, "Not met": 1, "Unknown": 0, "Not targeted": 0 }
                },
                summer: {
                    targets: 3,
                    types: ["Receptive Language"],
                    progress: { "Achieved": 0, "Working towards": 1, "Not met": 0, "Unknown": 2, "Not targeted": 0 }
                }
            },
            caseNotes: {
                total: 12,
                breakdown: {
                    "General Updates": 3,
                    "Therapy Sessions": 6,
                    "Parent Communications": 2,
                    "Concerns/Incidents": 1
                },
                mostRecentDate: "12/03/2025",
                mostCommonType: "Therapy Session",
                sessions: [
                    {
                        sessionDate: "10/03/2025",
                        sessionTime: "09:00 AM",
                        typeOfContact: "In-Person",
                        sessionNotes: "Practiced expressive language skills. Showed moderate improvement.",
                        directMinutes: 40,
                        indirectMinutes: 15,
                        therapistName: "Dr. Emily Carter",
                        role: "Speech & Language Therapist"
                    },
                    {
                        sessionDate: "02/03/2025",
                        sessionTime: "11:30 AM",
                        typeOfContact: "Virtual",
                        sessionNotes: "Parent reported challenges with following multi-step instructions.",
                        directMinutes: 30,
                        indirectMinutes: 20,
                        therapistName: "Mr. John Smith",
                        role: "Assistant Therapist"
                    }
                ]
            },
            lastNote: {
                date: "10/03/2025",
                type: "Therapy Session",
                therapist: "Dr. Emily Carter"
            },
            details: {
                dob: "08/05/2015",
                class: "Year 3",
                className: "Willow Class",
                gender: "Male",
                ethnicity: "Black British",
                referralDate: "05/09/2023",
                language1: "English",
                language2: "Spanish",
                language3: "",
                otherLanguages: "",
                dateOfAssessment: "10/09/2023",
                dateOfReview: "10/03/2024",
                reportDate: "15/09/2023",
                reportSent: "Yes",
                reportLink: "https://example.com/report-john-doe",
                primaryMedicalDiagnosis: "Autism Spectrum Disorder",
                additionalMedicalDiagnosis: "",
                otherMedicalDiagnosis: "",
                primaryCommSwallowingDisorder: "Expressive Language Delay",
                additionalCommSwallowingDisorder: "Receptive Language Disorder",
                otherCommSwallowingDisorder: "",
                ehcp: "Yes",
                plan: "SEN Support Plan",
                directHours: 20,
                indirectHours: 10,
                directHoursCompleted: 10,
                indirectHoursCompleted: 5,
                directHoursRemaining: 10,
                indirectHoursRemaining: 5
            },
            targets: [
                {
                    id: 1,
                    term: "Autumn",
                    year: "2024",
                    type: "Speech",
                    progress: "Achieved",
                    target: "Improve articulation",
                    resourceUrls: ["https://example.com/articulation-exercise"],
                    comments: "Student showed significant improvement in articulation drills.",
                    direct: 3,
                    taSupport: true
                },
                {
                    id: 2,
                    term: "Spring",
                    year: "2024",
                    type: "Receptive Language",
                    progress: "Working towards",
                    target: "Follow 3-step instructions",
                    resourceUrls: ["https://example.com/receptive-language"],
                    comments: "Can follow 2-step instructions well, but struggles with longer sequences.",
                    direct: 2,
                    taSupport: false
                },
                {
                    id: 3,
                    term: "Summer",
                    year: "2024",
                    type: "Social Skills",
                    progress: "Not met",
                    target: "Initiate conversations with peers",
                    resourceUrls: ["https://example.com/social-skills"],
                    comments: "Needs more structured opportunities for peer interaction.",
                    direct: 4,
                    taSupport: true
                },
                {
                    id: 4,
                    term: "Spring",
                    year: "2024",
                    type: "Expressive Language",
                    progress: "Not targeted",
                    target: "Use 5+ word sentences in conversations",
                    resourceUrls: ["https://example.com/expressive-language"],
                    comments: "Will be assessed next term.",
                    direct: 1,
                    taSupport: false
                }
            ]
        },
        {
            id: 2,
            name: "Jane Smith",
            academicYear: "2024/2025",
            totalTargets: 12,
            terms: {
                autumn: {
                    targets: 2,
                    types: ["Speech"],
                    progress: { "Achieved": 2, "Working towards": 0, "Not met": 0, "Unknown": 0, "Not targeted": 0 }
                },
                spring: {
                    targets: 6,
                    types: ["Social Skills", "Receptive Language"],
                    progress: { "Achieved": 0, "Working towards": 2, "Not met": 4, "Unknown": 0, "Not targeted": 0 }
                },
                summer: {
                    targets: 4,
                    types: ["Expressive Language"],
                    progress: { "Achieved": 0, "Working towards": 0, "Not met": 0, "Unknown": 0, "Not targeted": 4 }
                }
            },
            caseNotes: {
                total: 8,
                breakdown: {
                    "General Updates": 2,
                    "Therapy Sessions": 4,
                    "Parent Communications": 1,
                    "Concerns/Incidents": 1
                },
                mostRecentDate: "20/02/2025",
                mostCommonType: "Therapy Session",
                sessions: [
                    {
                        sessionDate: "18/02/2025",
                        sessionTime: "11:00 AM",
                        typeOfContact: "In-Person",
                        sessionNotes: "Student practiced turn-taking and social interactions. Showed mild improvement but requires further reinforcement.",
                        directMinutes: 40,
                        indirectMinutes: 20,
                        therapistName: "Dr. Emily Carter",
                        role: "Speech & Language Therapist"
                    },
                    {
                        sessionDate: "10/02/2025",
                        sessionTime: "02:30 PM",
                        typeOfContact: "Virtual",
                        sessionNotes: "Followed up on previous session. Parent reported slight progress in expressive language skills.",
                        directMinutes: 30,
                        indirectMinutes: 15,
                        therapistName: "Ms. Hannah Lee",
                        role: "Assistant Therapist"
                    }
                ]
            },
            lastNote: {
                date: "20/02/2025",
                type: "Therapy Session",
                therapist: "Dr. Emily Carter"
            },
            details: {
                dob: "15/03/2016",
                class: "Year 2",
                className: "Maple Class",
                gender: "Female",
                ethnicity: "Asian",
                referralDate: "10/09/2023",
                language1: "English",
                language2: "French",
                language3: "",
                otherLanguages: "German",
                dateOfAssessment: "18/09/2023",
                dateOfReview: "20/03/2024",
                reportDate: "22/09/2023",
                reportSent: "Yes",
                reportLink: "https://example.com/report-jane-smith",
                primaryMedicalDiagnosis: "Dyslexia",
                additionalMedicalDiagnosis: "",
                otherMedicalDiagnosis: "",
                primaryCommSwallowingDisorder: "Receptive Language Difficulty",
                additionalCommSwallowingDisorder: "Expressive Language Delay",
                otherCommSwallowingDisorder: "",
                ehcp: "No",
                plan: "Individual Learning Plan",
                directHours: 15,
                indirectHours: 8,
                directHoursCompleted: 10,
                indirectHoursCompleted: 5,
                directHoursRemaining: 5,
                indirectHoursRemaining: 3
            },
            targets: [
                {
                    id: 1,
                    term: "Autumn",
                    year: "2024",
                    type: "Speech",
                    progress: "Achieved",
                    target: "Improve articulation",
                    resourceUrls: ["https://example.com/articulation-exercise"],
                    comments: "Clearer pronunciation noted in class participation.",
                    direct: 3,
                    taSupport: true
                },
                {
                    id: 2,
                    term: "Spring",
                    year: "2024",
                    type: "Receptive Language",
                    progress: "Working towards",
                    target: "Follow 3-step instructions",
                    resourceUrls: ["https://example.com/receptive-language"],
                    comments: "Can follow 2-step instructions well, but struggles with longer sequences.",
                    direct: 2,
                    taSupport: false
                },
                {
                    id: 3,
                    term: "Summer",
                    year: "2024",
                    type: "Social Skills",
                    progress: "Not met",
                    target: "Initiate conversations with peers",
                    resourceUrls: ["https://example.com/social-skills"],
                    comments: "Needs more structured opportunities for peer interaction.",
                    direct: 4,
                    taSupport: true
                }
            ]
        },
        {
            id: 3,
            name: "Alice Johnson",
            academicYear: "2024/2025",
            totalTargets: 8,
            terms: {
                autumn: {
                    targets: 2,
                    types: ["Expressive Language", "Social Skills"],
                    progress: { "Achieved": 1, "Working towards": 1, "Not met": 0, "Unknown": 0, "Not targeted": 0 }
                },
                spring: {
                    targets: 3,
                    types: ["Speech", "Receptive Language"],
                    progress: { "Achieved": 0, "Working towards": 1, "Not met": 2, "Unknown": 0, "Not targeted": 0 }
                },
                summer: {
                    targets: 3,
                    types: ["Social Skills", "Expressive Language"],
                    progress: { "Achieved": 0, "Working towards": 0, "Not met": 1, "Unknown": 2, "Not targeted": 0 }
                }
            },
            caseNotes: {
                total: 14,
                breakdown: {
                    "General Updates": 4,
                    "Therapy Sessions": 5,
                    "Parent Communications": 3,
                    "Concerns/Incidents": 2
                },
                mostRecentDate: "08/03/2025",
                mostCommonType: "Therapy Session",
                sessions: [
                    {
                        sessionDate: "05/03/2025",
                        sessionTime: "10:30 AM",
                        typeOfContact: "In-Person",
                        sessionNotes: "Worked on expressive language skills. Some difficulty with verb use.",
                        directMinutes: 45,
                        indirectMinutes: 10,
                        therapistName: "Dr. Rachel Green",
                        role: "Speech & Language Therapist"
                    },
                    {
                        sessionDate: "25/02/2025",
                        sessionTime: "02:00 PM",
                        typeOfContact: "Virtual",
                        sessionNotes: "Parent noted progress in turn-taking at home. Further practice advised.",
                        directMinutes: 30,
                        indirectMinutes: 20,
                        therapistName: "Mr. Michael Scott",
                        role: "Assistant Therapist"
                    }
                ]
            },
            lastNote: {
                date: "05/03/2025",
                type: "Therapy Session",
                therapist: "Dr. Rachel Green"
            },
            details: {
                dob: "15/07/2014",
                class: "Year 4",
                className: "Oak Class",
                gender: "Female",
                ethnicity: "Asian British",
                referralDate: "10/09/2023",
                language1: "English",
                language2: "Hindi",
                language3: "French",
                otherLanguages: "Punjabi",
                dateOfAssessment: "15/09/2023",
                dateOfReview: "15/03/2024",
                reportDate: "20/09/2023",
                reportSent: "Yes",
                reportLink: "https://example.com/report-alice-johnson",
                primaryMedicalDiagnosis: "Speech Sound Disorder",
                additionalMedicalDiagnosis: "",
                otherMedicalDiagnosis: "",
                primaryCommSwallowingDisorder: "Expressive Language Delay",
                additionalCommSwallowingDisorder: "Articulation Difficulty",
                otherCommSwallowingDisorder: "",
                ehcp: "Yes",
                plan: "Individual Learning Plan",
                directHours: 18,
                indirectHours: 12,
                directHoursCompleted: 9,
                indirectHoursCompleted: 6,
                directHoursRemaining: 9,
                indirectHoursRemaining: 6
            },
            targets: [
                {
                    id: 1,
                    term: "Autumn",
                    year: "2024",
                    type: "Expressive Language",
                    progress: "Achieved",
                    target: "Use pronouns correctly in sentences",
                    resourceUrls: ["https://example.com/expressive-language-pronouns"],
                    comments: "Has started using correct pronouns in structured tasks.",
                    direct: 4,
                    taSupport: true
                },
                {
                    id: 2,
                    term: "Spring",
                    year: "2024",
                    type: "Receptive Language",
                    progress: "Working towards",
                    target: "Understand and respond to WH-questions",
                    resourceUrls: ["https://example.com/receptive-language-wh-questions"],
                    comments: "Improvement noted but needs more practice with ‘why’ questions.",
                    direct: 3,
                    taSupport: false
                },
                {
                    id: 3,
                    term: "Summer",
                    year: "2024",
                    type: "Social Skills",
                    progress: "Not met",
                    target: "Maintain a conversation for at least 3 turns",
                    resourceUrls: ["https://example.com/social-skills"],
                    comments: "Struggles with spontaneous responses, requires prompting.",
                    direct: 5,
                    taSupport: true
                },
                {
                    id: 4,
                    term: "Spring",
                    year: "2024",
                    type: "Speech",
                    progress: "Not targeted",
                    target: "Produce /s/ sound correctly in words",
                    resourceUrls: ["https://example.com/speech-therapy"],
                    comments: "To be introduced next term.",
                    direct: 2,
                    taSupport: false
                }
            ]
        }

    ];



    const getCurrentAcademicYear = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // JS months are 0-based

        // If it's September or later, the academic year is the current year to next year
        if (currentMonth >= 9) {
            return `${currentYear}/${currentYear + 1}`;
        }
        // If it's before September, the academic year is the previous year to current year
        return `${currentYear - 1}/${currentYear}`;
    };

    const [selectedYear, setSelectedYear] = useState(getCurrentAcademicYear());


    const availableYears = Array.from({ length: 3 }, (_, i) => {
        const currentYear = new Date().getFullYear();
        return `${currentYear - i}/${currentYear - i + 1}`;
    });

    const filteredStudents = students.filter((student) => student.academicYear === selectedYear);

    interface Student {
        id: number;
        name: string;
        academicYear: string;
        totalTargets: number;
        terms: {
            autumn: { targets: number; types: string[]; progress: Record<string, number> };
            spring: { targets: number; types: string[]; progress: Record<string, number> };
            summer: { targets: number; types: string[]; progress: Record<string, number> };
        };
        caseNotes: {
            total: number;
            breakdown: Record<string, number>;
            mostRecentDate: string;
            mostCommonType: string;
            sessions: {
                sessionDate: string;
                sessionTime: string;
                typeOfContact: string;
                sessionNotes: string;
                directMinutes: number;
                indirectMinutes: number;
                therapistName: string;
                role: string;
            }[];
        };
        lastNote: {
            date: string;
            type: string;
            therapist: string;
        };
        details: {
            dob: string;
            class: string;
            className: string;
            gender: string;
            ethnicity: string;
            referralDate: string;
            language1: string;
            language2: string;
            language3: string;
            otherLanguages: string;
            dateOfAssessment: string;
            dateOfReview: string;
            reportDate: string;
            reportSent: string;
            reportLink: string;
            primaryMedicalDiagnosis: string;
            additionalMedicalDiagnosis: string;
            otherMedicalDiagnosis: string;
            primaryCommSwallowingDisorder: string;
            additionalCommSwallowingDisorder: string;
            otherCommSwallowingDisorder: string;
            ehcp: string;
            plan: string;
            directHours: number;
            indirectHours: number;
            directHoursCompleted: number;
            indirectHoursCompleted: number;
            directHoursRemaining: number;
            indirectHoursRemaining: number;
        };
        targets: {
            id: number;
            term: string;
            year: string;
            type: string;
            progress: string;
            target: string;
            resourceUrls: string[];
            comments: string;
            direct: number;
            taSupport: boolean;
        }[];
    }





    const handlePrint = (student: Student) => {
        const printContent = `
            <html>
            <head>
                <title>Student Report</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        margin: 20px;
                    }
                    .container {
                        width: 80%;
                        margin: auto;
                    }
                    h1, h2 {
                        text-align: center;
                        color: #333;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-top: 20px;
                    }
                    th, td {
                        border: 1px solid #ccc;
                        padding: 10px;
                        text-align: left;
                    }
                    th {
                        background-color: #f4f4f4;
                    }
                </style>
            </head>
            <body>
                <div class="container">
                    <h1>Summary Speech & Language Observation Report ADD LOGO?COLOURS</h1>
    
                    <table>
                        <tr>
                            <th>Name:</th>
                            <td>${student.name}</td>
                            <th>D.O.B:</th>
                            <td>${student.details?.dob || "N/A"}</td>
                        </tr>
                        <tr>
                            <th>Year Group:</th>
                            <td>${student.details?.class || "N/A"} - ${student.details?.className || "N/A"}</td>
                            <th>Assessment Date:</th>
                            <td>${student.details?.dateOfAssessment || "N/A"}</td>
                        </tr>
                        <tr>
                            <th>Report Date:</th>
                            <td>${student.details?.reportDate || "N/A"}</td>
                            <th>Report Sent:</th>
                            <td>${student.details?.reportSent || "N/A"}</td>
                        </tr>
                    </table>
    
                    <h2>Student Details</h2>
                    <table>
                        <tr>
                            <th>Gender</th>
                            <td>${student.details?.gender || "N/A"}</td>
                            <th>Ethnicity</th>
                            <td>${student.details?.ethnicity || "N/A"}</td>
                        </tr>
                        <tr>
                            <th>Referral Date</th>
                            <td>${student.details?.referralDate || "N/A"}</td>
                            <th>Languages</th>
                            <td>${student.details?.language1 || "N/A"}, ${student.details?.language2 || "N/A"}, ${student.details?.language3 || "N/A"}, ${student.details?.otherLanguages || "N/A"}</td>
                        </tr>
                    </table>
    
                    <h2>Medical & Communication Needs</h2>
                    <table>
                        <tr>
                            <th>Primary Medical Diagnosis</th>
                            <td>${student.details?.primaryMedicalDiagnosis || "N/A"}</td>
                            <th>Additional Medical Diagnosis</th>
                            <td>${student.details?.additionalMedicalDiagnosis || "N/A"}</td>
                        </tr>
                        <tr>
                            <th>Primary Communication/Swallowing Disorder</th>
                            <td>${student.details?.primaryCommSwallowingDisorder || "N/A"}</td>
                            <th>Additional Communication/Swallowing Disorder</th>
                            <td>${student.details?.additionalCommSwallowingDisorder || "N/A"}</td>
                        </tr>
                    </table>
    
                    <h2>EHCP & Therapy Hours</h2>
                    <table>
                        <tr>
                            <th>EHCP</th>
                            <td>${student.details?.ehcp || "N/A"}</td>
                            <th>Plan</th>
                            <td>${student.details?.plan || "N/A"}</td>
                        </tr>
                        <tr>
                            <th>Direct Hours</th>
                            <td>${student.details?.directHours || "N/A"}</td>
                            <th>Indirect Hours</th>
                            <td>${student.details?.indirectHours || "N/A"}</td>
                        </tr>
                        <tr>
                            <th>Direct Hours Completed</th>
                            <td>${student.details?.directHoursCompleted || "N/A"}</td>
                            <th>Indirect Hours Completed</th>
                            <td>${student.details?.indirectHoursCompleted || "N/A"}</td>
                        </tr>
                        <tr>
                            <th>Direct Hours Remaining</th>
                            <td>${student.details?.directHoursRemaining || "N/A"}</td>
                            <th>Indirect Hours Remaining</th>
                            <td>${student.details?.indirectHoursRemaining || "N/A"}</td>
                        </tr>
                    </table>
    
                    <h2>Observation Results</h2>
                    <table>
                        <tr>
                            <th>Strengths</th>
                            <th>Speech, Language and Communication Needs</th>
                        </tr>
                        <tr>
                            <td>Comment on attention, listening, receptive and expressive language, social communication, speech (if relevant).</td>
                            <td></td>
                        </tr>
                    </table>
    
                    <h2>Strategies to Use in the Classroom and Home</h2>
                    <ul>
                        <li>List as many as apply</li>
                    </ul>
    
                    <h2>Recommendations</h2>
                    <ul>
                        <li>${student.name} will benefit from X, Y, Z</li>
                        <li>The above strategies should be adopted into the classroom setting</li>
                        <li>Speech and language targets:</li>
                    </ul>
    
                    <h2>Speech & Language Targets</h2>
                    <table>
                        <tr>
                            <th>Term</th>
                            <th>Year</th>
                            <th>Type</th>
                            <th>Progress</th>
                            <th>Direct</th>
                            <th>TA Support</th>
                            <th>Target</th>
                            <th>Comments</th>
                            <th>Resources</th>
                        </tr>
                        ${student.targets.map(target => `
                            <tr>
                                <td>${target.term}</td>
                                <td>${target.year}</td>
                                <td>${target.type}</td>
                                <td>${target.progress}</td>
                                <td>${target.direct}</td>
                                <td>${target.taSupport ? "Yes" : "No"}</td>
                                <td>${target.target}</td>
                                <td>${target.comments}</td>
                                <td>${target.resourceUrls.join("<br>")}</td>
                            </tr>
                        `).join("")}
                    </table>
    
                    <h2>Case Notes</h2>
                    <table>
                        <tr>
                            <th>Total Case Notes</th>
                            <td>${student.caseNotes.total}</td>
                            <th>Most Recent Note</th>
                            <td>${student.caseNotes.mostRecentDate}</td>
                        </tr>
                        <tr>
                            <th>Most Common Type</th>
                            <td>${student.caseNotes.mostCommonType}</td>
                        </tr>
                    </table>
    
                    <h2>Signature</h2>
                    <p>Name: _______________</p>
                    <p>Title: Speech & Language Therapist</p>
                    <p>Tel: 0207 536 9299</p>
                    <p>Email: name@unlockinglanguage.co.uk</p>
    
                    <h2>Unlocking Language</h2>
                    <p>Independent Speech & Language Therapy</p>
                    <p>Unit 3, 12 Pepper Street, London, E14 9RP</p>
                    <p>Tel: 0207 536 9299</p>
                    <p>Website: <a href="http://www.unlockinglanguage.co.uk">www.unlockinglanguage.co.uk</a></p>
                </div>
            </body>
            </html>
        `;

        const printWindow = window.open("", "_blank");
        if (printWindow) {
            printWindow.document.open();
            printWindow.document.write(printContent);
            printWindow.document.close();
            printWindow.print();
        }
    };


    const [selectedStudentId, setSelectedStudentId] = useState("");




    return (
        <Box ml={{ base: 0, md: 20 }} p="6">
            <Box p={6} bg="white" borderRadius="md" boxShadow="lg">
                {/* ✅ Header Section */}
                <Box p={2} mb={5}>
                    <HStack spacing={5} justifyContent="space-between">
                        <BackButton navigate={navigate} />


                        <Heading pb={6} mt={6} width="100%" textAlign="left">
                            School Name Summary {sessionId} DUMMY DATA
                        </Heading>

                    </HStack>
                </Box>
                {/* ✅ Academic Year & Student Selector */}
                <Flex justify="flex-end" mb={5} gap={4}>
                    {/* ✅ Student Selector (Scrolls to Selected Student) */}
                    <Box width="250px">
                        <FormControl>
                            <FormLabel fontWeight="bold">Select Student</FormLabel>
                            <Select
                                value={selectedStudentId}
                                onChange={(e) => {
                                    setSelectedStudentId(e.target.value);
                                    document.getElementById(`student-${e.target.value}`)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                }}
                                bg="white"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                            >
                                {filteredStudents.map((student) => (
                                    <option key={student.id} value={student.id}>{student.name}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* ✅ Academic Year Selector */}
                    <Box width="250px">
                        <FormControl>
                            <FormLabel fontWeight="bold">Select Academic Year</FormLabel>
                            <Select
                                value={selectedYear}
                                onChange={(e) => {
                                    setSelectedYear(e.target.value);
                                    setSelectedStudentId(""); // ✅ Reset Student Selector
                                }}
                                bg="white"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                            >
                                {availableYears.map((year) => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>


                </Flex>





                {/* ✅ Student Summary Sections */}
                {filteredStudents.length > 0 ? (
                    filteredStudents.map((student) => (
                        <Box key={student.id} id={`student-${student.id}`} mb={6} p={5} borderRadius="md" boxShadow="md" bg="gray.50">

                            <Box position="relative" width="100%" mt={4}>
                                {/* Centered Heading */}
                                <Heading
                                    size="md"
                                    color="brand.600"
                                    position="absolute"
                                    left="50%"
                                    transform="translateX(-50%)"
                                >
                                    📊 {student.name} - Academic Year {student.academicYear}
                                </Heading>

                                {/* Right-Aligned Button */}
                                <Flex justify="flex-end">
                                    <Button
                                        bg="brand.300"
                                        color="white"
                                        _hover={{ background: "brand.200" }}
                                        onClick={() => handlePrint(student)}
                                    >
                                        🖨️ Print Formal Summary
                                    </Button>
                                </Flex>
                            </Box>




                            <Text fontWeight="bold" textAlign="center" fontSize="lg" mb={4}>
                                🎯 Total Targets: {student.totalTargets}
                            </Text>

                            {/* ✅ Term Sections */}
                            <Flex justify="center" gap={6}>
                                {Object.entries(student.terms).map(([term, data]) => (
                                    <Box
                                        key={term}
                                        p={5}
                                        w="30%"
                                        bg={term === "autumn" ? "orange.100" : term === "spring" ? "green.100" : "yellow.100"}
                                        border={`2px solid ${term === "autumn" ? "orange" : term === "spring" ? "green" : "yellow"}`}
                                        borderRadius="lg"
                                        boxShadow="lg"
                                    >
                                        <Heading size="md" color="gray.800" mb={2} textAlign="center">
                                            {term === "autumn" ? "🍂 Autumn Term" : term === "spring" ? "🌱 Spring Term" : "☀️ Summer Term"}
                                        </Heading>
                                        <Text fontWeight="bold" color="red.600" mb={2}>
                                            📌 Targets: {data.targets}
                                        </Text>
                                        <Text fontWeight="bold" color="gray.700">📂 Types:</Text>
                                        <Flex wrap="wrap" gap={2} mt={1}>
                                            {data.types.map((type) => (
                                                <Tag key={type} bg="blue.200">{type}</Tag>
                                            ))}
                                        </Flex>
                                        <Text fontWeight="bold" color="blue.700" mt={2}>📊 Progress:</Text>
                                        <Flex wrap="wrap" gap={2} mt={1}>
                                            {Object.entries(data.progress)
                                                .filter(([_, count]) => count > 0) // ✅ Hide zero values
                                                .map(([status, count]) => (
                                                    <Tag
                                                        key={status}
                                                        bg={
                                                            status === "Achieved" ? "green.200"
                                                                : status === "Working towards" ? "orange.200"
                                                                    : status === "Not met" ? "red.200"
                                                                        : status === "Not targeted" ? "yellow.200"
                                                                            : "gray.300"
                                                        }
                                                    >
                                                        {status}: {count}
                                                    </Tag>
                                                ))
                                            }
                                        </Flex>

                                    </Box>
                                ))}
                            </Flex>

                            {/* ✅ Targets Table Section */}
                            <Box mt={6} p={5} borderRadius="md" boxShadow="md" bg="white">
                                <Heading size="md" mb={4} textAlign="center" color="brand.600">
                                    🎯 Student Targets
                                </Heading>

                                {student.targets.length > 0 ? (
                                    <TableContainer border="1px solid gray.200" borderRadius="md" boxShadow="md">
                                        <Table variant="striped" colorScheme="green">
                                            <Thead>
                                                <Tr>
                                                    <Th>Term</Th>
                                                    <Th>Year</Th>
                                                    <Th>Type</Th>
                                                    <Th>Progress</Th>
                                                    <Th>Direct</Th>
                                                    <Th>TA Support</Th>
                                                    <Th>Target</Th>
                                                    <Th>Comments</Th>
                                                    <Th>Resource URLs</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {student.targets.map((target) => (
                                                    <Tr key={target.id}>
                                                        <Td>{target.term}</Td>
                                                        <Td>{target.year}</Td>
                                                        <Td>{target.type}</Td>

                                                        {/* ✅ Progress Column with Color Coding */}
                                                        <Td>
                                                            <Tag
                                                                bg={
                                                                    target.progress === "Achieved"
                                                                        ? "green.200"
                                                                        : target.progress === "Working towards"
                                                                            ? "orange.200"
                                                                            : target.progress === "Not met"
                                                                                ? "red.200"
                                                                                : target.progress === "Not targeted"
                                                                                    ? "yellow.200"
                                                                                    : "gray.300"
                                                                }
                                                                px={2}
                                                                py={1}
                                                                borderRadius="md"
                                                                fontSize="sm"
                                                                fontWeight="bold"
                                                            >

                                                                {target.progress}
                                                            </Tag>
                                                        </Td>

                                                        <Td>{target.direct}</Td>
                                                        <Td>{target.taSupport ? "Yes" : "No"}</Td>
                                                        <Td whiteSpace="pre-line">{target.target}</Td>
                                                        <Td whiteSpace="pre-line">{target.comments || "N/A"}</Td>

                                                        {/* ✅ Resource URLs - Clickable Links */}
                                                        <Td whiteSpace="pre-line">
                                                            {target.resourceUrls.length > 0 ? (
                                                                target.resourceUrls.map((url, i) => (
                                                                    <Box key={i}>
                                                                        <Link href={url.trim()} color="blue.500" isExternal>
                                                                            {url.trim()}
                                                                        </Link>
                                                                        <br />
                                                                    </Box>
                                                                ))
                                                            ) : (
                                                                "N/A"
                                                            )}
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Text textAlign="center" fontWeight="bold" color="gray.500">
                                        No targets available for this student.
                                    </Text>
                                )}
                            </Box>



                            {/* ✅ Case Notes */}
                            <Box p={5} mt={5} borderRadius="md" bg="gray.200"  boxShadow="md">
                                <Heading size="md" mb={3} color="green.500">📖 Case Notes Summary</Heading>
                                <Text fontWeight="bold">📝 Total Case Notes: {student.caseNotes.total}</Text>
                                {Object.entries(student.caseNotes.breakdown).map(([category, count]) => (
                                    <Text key={category}>▫️ {category}: {count}</Text>
                                ))}
                                <Text><strong>📅 Most Recent Note:</strong> {student.caseNotes.mostRecentDate}</Text>
                                <Text><strong>📊 Most Common Type:</strong> {student.caseNotes.mostCommonType}</Text>
                            </Box>

                            


                            {/* ✅ Case Notes Table */}
                            <Box p={5} mt={5} borderRadius="md" bg="white">
                                <Heading size="md" mb={4} textAlign="center" color="brand.600">
                                    📝 Detailed Case Notes
                                </Heading>

                                {student.caseNotes.sessions && student.caseNotes.sessions.length > 0 ? (
                                    <TableContainer border="1px solid gray.200" borderRadius="md" boxShadow="md">
                                        <Table variant="striped" colorScheme="blue">
                                            <Thead>
                                                <Tr>
                                                    <Th>Session Date</Th>
                                                    <Th>Session Time</Th>
                                                    <Th>Type of Contact</Th>

                                                    <Th>Direct Minutes</Th>
                                                    <Th>Indirect Minutes</Th>
                                                    <Th>Therapist Name</Th>
                                                    <Th>Role</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {student.caseNotes.sessions.map((session, index) => (
                                                    <Tr key={index}>
                                                        <Td>{session.sessionDate}</Td>
                                                        <Td>{session.sessionTime}</Td>
                                                        <Td>{session.typeOfContact}</Td>

                                                        <Td>{session.directMinutes || "0"}</Td>
                                                        <Td>{session.indirectMinutes || "0"}</Td>
                                                        <Td>{session.therapistName || "N/A"}</Td>
                                                        <Td>{session.role || "N/A"}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Text textAlign="center" fontWeight="bold" color="gray.500">
                                        No case notes available for this student.
                                    </Text>
                                )}
                            </Box>

                            {/* ✅ Student Details Section */}
                            <Box p={5} mt={5} borderRadius="md" bg="gray.100"  boxShadow="md">
                                <Heading size="md" mb={3} color="green.500">📋 Student Details</Heading>

                                {/* ✅ Personal Information */}
                                <Flex wrap="wrap" gap={4}>
                                    <Box width={{ base: "100%", md: "48%" }}>
                                        <Text><strong>📅 DOB:</strong> {student.details.dob || "N/A"}</Text>
                                        <Text><strong>🏷️ Class:</strong> {student.details.class || "N/A"}</Text>
                                        <Text><strong>🏫 Class Name:</strong> {student.details.className || "N/A"}</Text>
                                        <Text><strong>🚻 Gender:</strong> {student.details.gender || "N/A"}</Text>
                                        <Text><strong>🌍 Ethnicity:</strong> {student.details.ethnicity || "N/A"}</Text>
                                        <Text><strong>📆 Referral Date:</strong> {student.details.referralDate || "N/A"}</Text>
                                    </Box>

                                    <Box width={{ base: "100%", md: "48%" }}>
                                        <Text><strong>🗣️ Language 1:</strong> {student.details.language1 || "N/A"}</Text>
                                        <Text><strong>🗣️ Language 2:</strong> {student.details.language2 || "N/A"}</Text>
                                        <Text><strong>🗣️ Language 3:</strong> {student.details.language3 || "N/A"}</Text>
                                        <Text><strong>🌐 Other Languages:</strong> {student.details.otherLanguages || "N/A"}</Text>
                                    </Box>
                                </Flex>
                            </Box>

                            {/* ✅ Assessment & Reports */}
                            <Box p={5} mt={5} borderRadius="md" bg="yellow.50"  boxShadow="md">
                                <Heading size="md" mb={3} color="green.500">📄 Assessment & Reports</Heading>
                                <Flex wrap="wrap" gap={4}>
                                    <Box width={{ base: "100%", md: "48%" }}>
                                        <Text><strong>📝 Date of Assessment:</strong> {student.details.dateOfAssessment || "N/A"}</Text>
                                        <Text><strong>📆 Date of Review:</strong> {student.details.dateOfReview || "N/A"}</Text>
                                    </Box>

                                    <Box width={{ base: "100%", md: "48%" }}>
                                        <Text><strong>📑 Report Date:</strong> {student.details.reportDate || "N/A"}</Text>
                                        <Text><strong>📩 Report Sent:</strong> {student.details.reportSent || "N/A"}</Text>
                                        <Text><strong>🔗 Report Link:</strong> <Link href={student.details.reportLink} color="blue.500" isExternal>{student.details.reportLink ? "View Report" : "N/A"}</Link></Text>
                                    </Box>
                                </Flex>
                            </Box>

                            {/* ✅ Medical & Communication Needs */}
                            <Box p={5} mt={5} borderRadius="md" bg="blue.50" boxShadow="md"  > 
                                <Heading size="md" mb={3} color="green.500">🏥 Medical & Communication Needs</Heading>
                                <Flex wrap="wrap" gap={4}>
                                    <Box width={{ base: "100%", md: "48%" }}>
                                        <Text><strong>🏥 Primary Medical Diagnosis:</strong> {student.details.primaryMedicalDiagnosis || "N/A"}</Text>
                                        <Text><strong>➕ Additional Medical Diagnosis:</strong> {student.details.additionalMedicalDiagnosis || "N/A"}</Text>
                                        <Text><strong>🔍 Other Medical Diagnosis:</strong> {student.details.otherMedicalDiagnosis || "N/A"}</Text>
                                    </Box>

                                    <Box width={{ base: "100%", md: "48%" }}>
                                        <Text><strong>🗣️ Primary Communication/Swallowing Disorder:</strong> {student.details.primaryCommSwallowingDisorder || "N/A"}</Text>
                                        <Text><strong>➕ Additional Communication/Swallowing Disorder:</strong> {student.details.additionalCommSwallowingDisorder || "N/A"}</Text>
                                        <Text><strong>🔍 Other Communication/Swallowing Disorder:</strong> {student.details.otherCommSwallowingDisorder || "N/A"}</Text>
                                    </Box>
                                </Flex>
                            </Box>

                            {/* ✅ EHCP & Therapy Hours */}
                            <Box p={5} mt={5} borderRadius="md" bg="green.50"  boxShadow="md">
                                <Heading size="md" mb={3} color="green.500">📜 EHCP & Therapy Hours</Heading>

                                {/* ✅ EHCP & Plan in First Row */}
                                <Flex wrap="wrap" gap={4} mb={3}>
                                    <Box>
                                        <Text><strong>📜 EHCP:</strong> {student.details.ehcp || "N/A"}</Text>
                                        <Text><strong>📋 Plan:</strong> {student.details.plan || "N/A"}</Text>
                                    </Box>
                                </Flex>

                                {/* ✅ Therapy Hours in the Same Row */}
                                <Flex wrap="wrap" gap={4}>
                                    <Box width={{ base: "100%", md: "48%" }}>
                                        <Text><strong>⏳ Direct Hours:</strong> {student.details.directHours || "N/A"}</Text>
                                        <Text color="green.700"><strong>✅ Direct Hours Completed:</strong> {student.details.directHoursCompleted || "N/A"}</Text>
                                        <Text><strong>⏳ Direct Hours Remaining:</strong> {student.details.directHoursRemaining || "N/A"}</Text>
                                    </Box>

                                    <Box width={{ base: "100%", md: "48%" }}>
                                        <Text><strong>⏳ Indirect Hours:</strong> {student.details.indirectHours || "N/A"}</Text>
                                        <Text color="green.700"><strong>✅ Indirect Hours Completed:</strong> {student.details.indirectHoursCompleted || "N/A"}</Text>
                                        <Text><strong>⏳ Indirect Hours Remaining:</strong> {student.details.indirectHoursRemaining || "N/A"}</Text>
                                    </Box>
                                </Flex>
                            </Box>



                        </Box>
                    ))
                ) : (
                    <Box textAlign="center" p={5} borderRadius="md" boxShadow="md" bg="gray.50">
                        <Text fontWeight="bold" color="gray.500" fontSize="lg">📌 No Data Available for {selectedYear}</Text>
                    </Box>
                )}

            </Box>
        </Box>

    );
};

export default SchoolSummarysinglePage;
