import React, { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  Text,
  List,
  ListItem,
  ListIcon,
  HStack,
  VStack,
  Spinner,
  background,
  useToast,
} from '@chakra-ui/react';
import getDataFromEndPoint from './../../utils/getDataFromEndPoint';
import getAllDriveFoldersFromClientUploadFolder from '../../utils/getAllDriveFoldersFromClientUploadFolder';
import getAllDriveFilesFromClientUploadFolder from '../../utils/getAllDriveFilesFromClientUploadFolder';
import { createClientUploadDriveFolder } from '../../utils/createClientUploadDriveFolder';
import createFolder from '../../utils/createFolder';
import { useDropzone } from 'react-dropzone';
import { MdCheckCircle } from 'react-icons/md';
import uploadFileToClientUploadFolder from '../../utils/uploadFileToClientUploadFolder';
import ninoxTableUpdateRow from '../../utils/ninoxTableUpdateRow';
import ninoxTableGetRow from '../../utils/ninoxTableGetRow';
import { useLocation } from 'react-router-dom';

const GoogleTokenEPAndListFiles =
  'https://client-portal-serverless-functions.vercel.app/api/googleDrive';

const googleDriveAuthToken =
  'https://client-portal-serverless-functions.vercel.app/api/googleDriveAuthToken';

const allClients = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
interface stepTwoProps {
  form?: any;
  setForm?: Function;
  nextStep: Function;
  stepStatus: any;
  setStepStatus: Function;
}
const StepTwo = ({ nextStep, stepStatus, setStepStatus, form, setForm }: stepTwoProps) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const ul_cl_id = queryParameters.get('ul_cl');
  // const currentStep = queryParameters.get('step');
  const [theDriveFiles, setTheDriveFiles] = useState<any>([]);
  const [theDriveFolders, setTheDriveFolders] = useState<any>();
  const [theUploadedFile, setTheUploadedFile] = useState<any>();
  const [folderCreated, setFolderCreated] = useState<number>(0);
  const [clientFolderId, setClientFolderId] = useState<any>(undefined);
  const [loading, setLoading] = useState<number>(0);
  const location = useLocation();
  const toast = useToast();

  const [dataToSend, setDataToSend] = useState<any>();

  const [folderToCreate, setFolderToCreate] = useState<string>('');
  const parentFolder = '1bQYeQl_1gs3FOVGc6E-kT9gdnXkX6qIh';
  const [savedAccessToken, setSavedAccessToken] = useState<string>('');
  const [fileData, setFileData] = useState<any>();
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // const clientFolder = theDriveFolders?.find((i: any) => i.name === ul_cl_id);
  let test = clientFolderId;
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        // var file = files[0]
        // const reader = new FileReader();
        // reader.onload = (event) => {
        //   console.log(event.target.result);
        // };
        // reader.readAsDataURL(file);
        // var reader = new FileReader();
        // reader.onload = function(e) {
        //   var contents = e.target.result;
        //   displayContents(contents);
        // };
        // reader.readAsText(file);
        const base64 = convertBase64(file);

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
          // // Do whatever you want with the file contents
          // console.log(theFile);
          const binaryStr = reader.result;

          setFileData(binaryStr);
          // console.log('binaryStr: ', binaryStr);
          console.log('e: ', e);
        };
        reader.readAsDataURL(file);
        // const filePath = reader.readAsDataURL(file);
        getDataFromEndPoint(GoogleTokenEPAndListFiles).then((data: any) => {
          const theReaderData = reader.result;
          // const base64result = theReaderData!.split(',')[1];
          // let base64String = reader.result.split(',').pop();
          // var base64 = theReaderData!.replace(/^data:image\/\w+;base64,/, '');
          const base64 = theReaderData!.toString().replace(/^data:image\/\w+;base64,/, '');
          let base64Image = theReaderData!.toString().split(';base64,').pop();
          // console.log('clientFolderId:', clientFolderId);
          setLoading(1);

          uploadFileToClientUploadFolder(
            base64Image,
            file.name,
            file.name,
            clientFolderId,
            // ul_cl_id!,
            // savedAccessToken,
            // data.config.headers.Authorization,
            file.type,
            file.size
          ).then((uploadData) => {
            // console.log('result: ', uploadData);
            setClientFolderId(clientFolderId);
            // console.log('clientFolderId after upload:', clientFolderId);
            setLoading(0);

            getAllDriveFilesFromClientUploadFolder(clientFolderId, the_url, savedAccessToken).then(
              (data: any) => {
                // console.log('we got all files again' + data);
                // setTheDriveFiles(data);
                console.log(
                  'data with PARENTS',
                  data?.filter((i: any) => i.parents)
                );
                console.log('clientFolderId', clientFolderId);

                setTheDriveFiles(data?.filter((i: any) => i.parents.includes(clientFolderId)));
                // data?.filter((i: any) => i.parents.includes(clientFolderId))
                // setClientFolderId(clientFolderId);
              }
            );
          });

          // console.log('TOKENNN', googleDriveAuthToken);
          const the_url = `https://www.googleapis.com/drive/v3/files?uploadType=media`;
          // console.log('test', clientFolderId);
        });
        // const theFile = reader.readAsArrayBuffer(file);
        const theMainFile = reader.readAsDataURL(file);

        // console.log('theMainFile: ', theMainFile);
        setTheUploadedFile(file);
        // console.log('THE FILE', file);
      });
    },
    [clientFolderId, theDriveFiles, savedAccessToken, theDriveFolders]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [file, setFile] = useState<any | null>(null);
  const [theFileToUpload, setTheFileToUpload] = useState<any | null>(null);

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      // console.log('the files', e.target.files);

      // handleUpload(e.target.files);
      setFile(e.target.files);
      setTheFileToUpload(e.target.files[0]);
    }

    console.log('e.target.files: ', e.target.files);

    console.log('e.target.files![0]: ', e.target.files![0]);

    const file = {
      // preview: URL.createObjectURL(e.target.files![0]),
      data: e.target.files,
      name: e.target.files![0].name,
      type: e.target.files![0].type,
      size: e.target.files![0].size,
    };
    setFile(file);
  };

  const handleUpload = async (e: any) => {
    if (file) {
      // console.log('Uploading file...');
      // console.log('savedAccessToken', savedAccessToken);
      // console.log('e.target.files[0]: ', e.target.files[0]);

      // let formData = new FormData();
      // formData.append('file', file.webkitRelativePath);
      // formData.append('mimeType', file.type);
      // formData.append('description', 'a file uploaded');
      // formData.append('parents', '1WSgy2LJyUiX6rP6l5SJR9LOH7qT-e32a');

      // let formData = new FormData();
      // formData.append('file', file);
      // var options = { content: formData };

      // console.log('requestBody', requestBody);

      // let formData = new FormData(); //formdata object
      // formData.append('name', 'ABC'); //append the values with key, value pair
      // formData.append('age', 20);

      // let formData = new FormData();
      // formData.append('file', file.name);
      // formData.append('mimeType', file.type);
      // formData.append('description', 'a file uploaded');
      // formData.append('parents', '1WSgy2LJyUiX6rP6l5SJR9LOH7qT-e32a');

      // const reader = new FileReader();
      // const theFile = reader.readAsDataURL(file.preview);

      // var reader = new FileReader();
      // const parents = ['1WSgy2LJyUiX6rP6l5SJR9LOH7qT-e32a'];
      // var blob_url = window.URL.createObjectURL(e.target.files);
      // const formDatas = new FormData();
      // formDatas.append('file', theFileToUpload);
      // formDatas.append('parents', String(parents));
      // formDatas.append('mimeType', file.type);
      // formDatas.append('description', 'a description of the uploaded file');

      // var fileContent = 'sample text'; // As a sample, upload a text file.
      // var thefile = new Blob([theFileToUpload], { type: file.type });
      var metadata = {
        file: file.data,
        name: file.name, // Filename at Google Drive
        mimeType: file.type, // mimeType at Google Drive
        parents: ['1WSgy2LJyUiX6rP6l5SJR9LOH7qT-e32a'], // Folder ID at Google Drive
      };

      var form = new FormData();
      // form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
      form.append('file', file);
      form.append('parents', '1WSgy2LJyUiX6rP6l5SJR9LOH7qT-e32a');
      form.append('name', file.name);
      form.append('mimeType', file.type);
      form.append('description', 'the description of the file');

      // const form = new FormData();
      // form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
      // form.append('file', thefile);
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = (e) => {
        // // Do whatever you want with the file contents
        // console.log(theFile);
        const binaryStr = reader.result;
        setDataToSend(reader.result);
      };
      // const newBlobFile = new Blob(theFileToUpload, { type: file.type });
      // const newBlobFile = new File(theFileToUpload, file.name, { type: 'text/json;charset=utf-8' });
      let dataBlob = new Blob([theFileToUpload], { type: file.type });
      const requestBody = {
        // var blob = new Blob(final_transformation, {type: "text/plain;charset=utf-8"});

        file: reader.readAsDataURL(dataBlob),
        // file: reader.readAsArrayBuffer(file.data),
        // file: new Blob([file.preview], { type: 'application/json' }),
        // file: new Blob([file.data]),
        mimeType: metadata.mimeType,
        description: 'a description of the uploaded file',
        parents: ['1WSgy2LJyUiX6rP6l5SJR9LOH7qT-e32a'],
        name: metadata.name,
        // size: file.size,
      };

      try {
        // const the_url = `https://www.googleapis.com/upload/drive/v3/files?uploadType=media`;
        const the_url = `https://www.googleapis.com/drive/v3/files?uploadType=media`;
        // const the_url = `https://www.googleapis.com/upload/drive/v3/files?uploadType=media`;
        // const the_url = `https://client-portal-serverless-functions.vercel.app/api/googleUploadClientFile`;
        // console.log('requestBody', requestBody);
        // console.log('fileData', file.data);
        // console.log('formData', formData);
        // console.log('savedAccessToken', savedAccessToken);
        // You can write the URL of your server or any other endpoint used for file upload
        // 'Content-Type': 'application/json',
        const the_serverless_url = `https://client-portal-serverless-functions.vercel.app/api/googleUploadClientFile`;

        const result = await fetch(the_serverless_url, {
          method: 'POST',
          // headers: {
          //   'content-type': 'multipart/form-data',
          // },
          // headers: {
          // Authorization: savedAccessToken,
          // 'Content-Type': 'application/json',
          // 'Content-Type': file.type,
          // 'Content-Length': file.size,
          // 'Content-Type': 'multipart/related;boundary=9700883396bond',
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'application/json',
          // 'Content-Length': 20,
          // },
          body: JSON.stringify(requestBody),
        });

        // console.log('REQUEST BODY SENT', result);
        // console.log('form: ', JSON.stringify(form));
        const data = await result.json();

        // const requestBody = {
        //   file: file,
        //   mimeType: mimeType,
        //   description: fileDescription,
        //   parents: [parentFolder],
        // };
        // const requestOptions = {
        //   method: 'POST',
        //   headers: {
        //     Authorization: myToken,
        //     'Content-Type': mimeType,
        //     // 'Content-Length': 20,
        //   },
        //   body: JSON.stringify(requestBody),
        // };
        // const response = await fetch(
        //   'https://www.googleapis.com/upload/drive/v3/files?uploadType=media',
        //   requestOptions
        // );
        // const data = await response.json();

        console.log(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const the_url = `https://www.googleapis.com/drive/v3/files?`;
    theDriveFolders?.filter((i: any) => i.name === ul_cl_id).length > 0 &&
      getAllDriveFilesFromClientUploadFolder(
        theDriveFolders?.find((i: any) => i.name === ul_cl_id).id,
        the_url,
        savedAccessToken
      ).then((data: any) => {
        // console.log('We got the files from the client folder', data);s
        setClientFolderId(
          theDriveFolders?.find((i: any) => String(i.name) === String(ul_cl_id)).id
        );
        setTheDriveFiles(data?.filter((i: any) => i.parents.includes(clientFolderId)));
        // setTheDriveFiles(data);
      });
  }, [theDriveFolders, folderCreated]);

  useEffect(() => {
    const the_url = `https://www.googleapis.com/drive/v3/files?`;

    // theDriveFolders?.filter((i: any) => i.name === ul_cl_id).length > 0 &&

    theDriveFolders?.filter((i: any) => String(i.name) === String(ul_cl_id)).length > 0 &&
      getAllDriveFilesFromClientUploadFolder(clientFolderId, the_url, savedAccessToken).then(
        (data: any) => {
          // console.log('We detected a change so we got the files from the client folder', data);
          // setTheDriveFiles(data);
          // setClientFolderId(
          //   theDriveFolders?.find((i: any) => String(i.name) === String(ul_cl_id)).id
          // );
          // console.log('clid', clientFolderId);
          // setTheDriveFiles(data?.filter((i: any) => i.parents.includes(clientFolderId)).id);
          // setTheDriveFiles(data);

          const currentClient = theDriveFolders?.filter(
            (i: any) => String(i.name) === String(ul_cl_id)
          ).id;
          // console.log('data after detection', data);

          setTheDriveFiles(data?.filter((i: any) => i.parents?.includes(currentClient)).id);
        }
      );

    getAllDriveFoldersFromClientUploadFolder(parentFolder, the_url, savedAccessToken).then(
      (data: any) => {
        setTheDriveFolders(data);
      }
    );
  }, [clientFolderId]);

  useEffect(() => {
    const parentFolder = '1bQYeQl_1gs3FOVGc6E-kT9gdnXkX6qIh';
    const the_url = `https://www.googleapis.com/drive/v3/files?`;
    getDataFromEndPoint(GoogleTokenEPAndListFiles).then((data: any) => {
      console.log(data);
      setSavedAccessToken(data.config.headers.Authorization);

      getAllDriveFoldersFromClientUploadFolder(
        parentFolder,
        the_url,
        data.config.headers.Authorization
      ).then((data: any) => {
        setTheDriveFolders(data);

        const currentClient = theDriveFolders?.filter(
          (i: any) => String(i.name) === String(ul_cl_id)
        ).id;
        // console.log('clid', clientFolderId);
        // console.log('data on first load', data);

        setTheDriveFiles(data?.filter((i: any) => i.parents?.includes(currentClient)));

        setClientFolderId(data?.find((i: any) => String(i.name) == String(ul_cl_id))?.id);
        // console.log('Client folder id', data?.find((i: any) => i.name === ul_cl_id)?.id);
        setFolderCreated(1);

        // console.log(
        //   'foldercheck:',
        //   data?.filter((i: any) => String(i.name) == String(ul_cl_id)).length
        // );
        // data?.filter((i: any) => String(i.name) == String(ul_cl_id)).length > 0
        //   ? setFolderCreated(1)
        //   : setFolderCreated(0);
      });
    });
  }, []);

  return (
    <Box w="100%" height={'60vh'} p={4}>
      {/* <Heading as="h3" size="lg" mb={'10'}>
        {title}
      </Heading> */}
      <Text mb={'5'}>Upload any files that may help the therapist in therapy such as an EHCP.</Text>
      <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        <Box>
          <Box
            // border={'dashed 2px #cccccc'}
            textAlign={'center'}
            alignContent={'center'}
            justifyContent={'center'}
            p={'30'}
            // width={'100%'}
            height={'40vh'}
          >
            <div>
              {/* <Box bg={clientFolderId && clientFolderId.length > 0 ? 'lightblue' : 'pink'}>
                {clientFolderId && clientFolderId} - and length:{' '}
                {clientFolderId && clientFolderId.length}
              </Box> */}

              {/* <Heading>activeStep: {activeStep}</Heading> */}
              {/* <Box bg={'lightblue'}>savedAccessToken: {savedAccessToken}</Box> */}
              {/* <Box bg={'orange'}>
                {JSON.stringify(theDriveFolders?.find((i: any) => i.name === ul_cl_id))}
              </Box>
              <Box bg={'yellow'}>
                Folders count: {theDriveFolders?.filter((i: any) => i.name === ul_cl_id).length}
              </Box> */}
              {/* <Box bg={'orange'}>
                {theDriveFolders?.filter((i: any) => String(i.name) == String(ul_cl_id)).length}
              </Box> */}
              {/* {theDriveFolders?.filter((i: any) => i.name === ul_cl_id).length <= 0 ? ( */}
              {clientFolderId === undefined ? (
                <Button
                  onClick={() =>
                    // createClientUploadDriveFolder(ul_cl_id!, parentFolder, savedAccessToken)
                    createFolder(ul_cl_id!, parentFolder, savedAccessToken).then((data: any) => {
                      console.log('folder created data', data);
                      setFolderCreated(1);
                      setClientFolderId(data.id);
                      ninoxTableUpdateRow(
                        process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
                        Number(ul_cl_id),
                        {
                          fields: {
                            'Uploads folder': 'https://drive.google.com/drive/folders/' + data.id,
                          },
                        },
                        String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
                      );
                      // console.log(`folder created for ${ul_cl_id!}`);
                    })
                  }
                  fontWeight={'medium'}
                >
                  Start uploading files to your account
                  {/* Get started | Create client folder for {ul_cl_id} == {folderCreated} ==
                  clientFolderId: {clientFolderId?.length} */}
                </Button>
              ) : (
                <HStack>
                  {/* <Box>
                    <input id="file" type="file" onChange={(e) => handleFileChange(e)} />
                    {file && (
                      <section>
                        File details:
                        <ul>
                          <li>{JSON.stringify(file.data)}</li>
                          <li>Name: {file.name}</li>
                          <li>Type: {file.type}</li>
                          <li>Size: {file.size} bytes</li>
                        </ul>
                      </section>
                    )}
                    {file && <button onClick={(e) => handleUpload(e)}>Upload a file</button>}
                  </Box> */}
                  <Box
                    {...getRootProps()}
                    bg={'gray.100'}
                    border={'dashed 2px #cccccc'}
                    textAlign={'center'}
                    // alignContent={'center'}
                    // justifyContent={'center'}
                    // p={'30'}
                    width={'50%'}
                    h={'40vh'}
                    p={5}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the file here ...</p>
                    ) : (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        textAlign={'center'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        pt={'20%'}
                      >
                        {/* <p> clientFolderId: {clientFolderId?.length}</p> */}
                        {loading === 1 ? (
                          <Box
                            width={'100%'}
                            display={'flex'}
                            flexDirection={'row'}
                            textAlign={'center'}
                            justifyContent={'center'}
                          >
                            <Box
                              width={'130px'}
                              height={'130px'}
                              alignContent={'center'}
                              display={'flex'}
                              flexDirection={'column'}
                              alignItems={'center'}
                            >
                              <Spinner
                                thickness="8px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="brand.200"
                                size="xl"
                              />
                              <Box>Uploading file ...</Box>
                            </Box>
                          </Box>
                        ) : (
                          <>
                            <Text p={5} fontWeight={'bold'}>
                              Drag 'n' drop file or click 'Upload file'
                            </Text>
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              textAlign={'center'}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <Button
                                bg={'brand.200'}
                                color={'white'}
                                _hover={{ background: 'brand.300' }}
                              >
                                Upload file
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                    {/* <Box bg={'green'}>
                  <Text>theDriveFolders:</Text>
                  <Text>{JSON.stringify(theDriveFolders)}</Text>
                </Box>
                <Box bg={'purple'}>
                  <Text>theFolderFiles:</Text>
                  <Text>{JSON.stringify(theDriveFiles)}</Text>
                </Box> */}
                    {/* Folder count: {theDriveFolders?.filter((i: any) => i.name === ul_cl_id).length}
                    <hr></hr>
                    Folders: <hr></hr>
                    <Text>
                      theUploadedFile:
                      {JSON.stringify(theUploadedFile)}
                    </Text>
                    <hr></hr> */}
                  </Box>
                  <List
                    spacing={3}
                    bg={'gray.100'}
                    textAlign={'left'}
                    width={'50%'}
                    h={'40vh'}
                    p={5}
                    // justifyContent={'flex-start'}
                    // alignContent={'start'}
                  >
                    <Box fontWeight={'bold'}>
                      {theDriveFiles && theDriveFiles?.length > 0
                        ? theDriveFiles?.length < 2
                          ? theDriveFiles?.length + ' file uploaded'
                          : theDriveFiles?.length > 2
                          ? theDriveFiles?.length + ' files uploaded'
                          : ''
                        : 'No files uploaded'}
                    </Box>

                    <Box overflow={'scroll'} h={'25vh'}>
                      {theDriveFiles?.length >= 1 &&
                        theDriveFiles?.sort().map((i: any, k: any) => (
                          <ListItem key={k}>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            {k + 1 + ') ' + i.name}
                          </ListItem>
                        ))}
                    </Box>
                  </List>
                </HStack>
              )}
            </div>
          </Box>
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          mt={5}
        >
          {location.pathname === '/private-client-clinical-express-onboarding' ? (
            <HStack>
              <Button
                variant={'defaultButton'}
                mt={5}
                onClick={() => nextStep(1)}
                isDisabled={clientFolderId === undefined ? true : false}
                _hover={{ backgroundColor: 'brand.200' }}
              >
                Back
              </Button>
              <Button
                variant={'defaultButton'}
                mt={5}
                onClick={() => {
                  nextStep(3);
                  ninoxTableUpdateRow(
                    allClients!,
                    Number(ul_cl_id),
                    {
                      fields: {
                        'Meta Upload': true,
                      },
                    },
                    process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                  ).then(() => {
                    setStepStatus({ ...stepStatus, stepThree: true });
                  });
                }}
                isDisabled={clientFolderId === undefined ? true : false}
                _hover={{ backgroundColor: 'brand.200' }}
              >
                Next
              </Button>
            </HStack>
          ) : (
            <HStack>
              <Button
                variant={'defaultButton'}
                mt={5}
                onClick={() => nextStep(1)}
                isDisabled={clientFolderId === undefined ? true : false}
                _hover={{ backgroundColor: 'brand.200' }}
              >
                Back
              </Button>
              <Button
                variant={'defaultButton'}
                mt={5}
                onClick={() => {
                  nextStep(3);
                  ninoxTableUpdateRow(
                    allClients!,
                    Number(ul_cl_id),
                    {
                      fields: {
                        'Meta Upload': true,
                      },
                    },
                    process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                  ).then(() => {
                    setStepStatus({ ...stepStatus, stepThree: true });
                  });
                }}
                isDisabled={clientFolderId === undefined ? true : false}
                _hover={{ backgroundColor: 'brand.200' }}
              >
                Next
              </Button>
            </HStack>
          )}
        </Box>
      </Grid>
    </Box>
  );
};
export default StepTwo;
